
import { useEffect, useState, useContext } from 'react';

import {
    Box, IconButton, Stepper, Step, Tooltip, MenuItem,
    makeStyles, StepLabel, Select, FormControl, FormControlLabel, Radio,
    RadioGroup, Tabs, Tab, Chip
} from '@material-ui/core';
//Style
import clsx from 'clsx';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
//Helpers.
import { blueBar } from '../../../helpers/common'
import { getCatalogo } from '../helpers';
//Componentes.
import ListNodeSelect from '../../Dashboards/SummaryLocation/Filter'
//Estado local nodos
import NodesListContext from '../../../context/NodesListContext';

const useStyles = makeStyles(theme => ({
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3.1),
        width: theme.spacing(3.1),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    addBtn: {
        margin: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    filterChip: {
        margin: theme.spacing(0.5),
        '@media (max-width: 700px)': {
            width: '250px',
        },
    },
    btn: {
        margin: theme.spacing(3, 1),
        '@media (max-width: 700px)': {
            fontSize: '13px',
            padding: '1%',
        },
    },
    tabsTitle: {
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        textAlign: 'center',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    radio: {
        height: '150px',
        marginLeft: theme.spacing(1),
        '& .MuiTypography-body1': {
            fontSize: '0.75rem',
        },
        '& > *': {
            flexBasis: '33%',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },
        '@media (max-width: 700px)': {
            height: 'auto',
        },
    },
}));

const VariableFilterOptions = ({ varState, setVarState, setDisabledButtonSave }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    const { nodesList } = useContext(NodesListContext);
    const [activeStep, setActiveStep] = useState(0);
    const [nodeSelect, setNodeSelect] = useState({});
    const [openModalDevice, setIsOpenModalDevice] = useState(false);
    //Periods
    const [granularity, setGranularity] = useState([]);
    const [indexSelectPeriods, setIndexSelectPeriods] = useState(0);
    //Periods.values
    const [Rango, setRango] = useState('');
    //Lista de opciones q pertenecen a tipo de operacion unico.
    const [uniqueValueList, setUniqueValueList] = useState([]);
    //block_op_choices
    const [opChoice, setOpChoice] = useState('');
    //Deshabilita la opcion de el selector tipo de operacion cuando es unico.
    const [disableOperatorSelect, setDisableOperatorSelect] = useState(false);

    //Estado de el filtro.
    const [stateForm, setStateForm] = useState({
        node: '',
        variable: '',
        values: ''
    });
    //Steeps options.
    const steps = [
        'Elija un dispositivo',
        'Elija una variable.',
        'Seleccione un tipo de operación.',
        'Haga click en Guardar'
    ]

    useEffect(() => {
        let res = getCatalogo('ALERTS_VARIABLES', 'generic_data')
        let uniqueValueListAux = res?.unique_value
        setUniqueValueList(uniqueValueListAux)
        //Cargo el nodo al filtro
        if (varState?.nodes?.length > 0) {
            let idNode = varState?.nodes[0]
            let nodeFind = nodesList.find(e => e.id === idNode)
            setNodeSelect(nodeFind)
        }

        //Cargo variable.
        if (varState?.values?.length > 0) {
            let prevValue = varState?.values
            let variable = prevValue.split('-')[1]
            let CATALOGO = getCatalogo('ALERTS_VARIABLES', 'detail_data')
            let filterVar = CATALOGO.find(e => e.key === variable)
            let periods = filterVar?.periods
            let granularityKey = varState?.values?.split('-')[2]
            //Cargo granularidad si tiene periodos.
            if (periods?.length > 0) {
                for (let i = 0; i < periods.length; i++) {
                    if (periods[i].key === granularityKey) {
                        setIndexSelectPeriods(i)
                    }
                }
            }
            setStateForm({
                variable,
            })
            setGranularity(periods)
            let rango = varState?.values?.split('-')[3]
            setRango(rango)
            //Cargo tipo de operacion.
            let variable_operation = varState?.variable_operation
            setOpChoice(variable_operation)
            let findOption = uniqueValueListAux.find(e => e?.granularity === granularityKey)
            let res = findOption?.range.includes(rango)
            setDisableOperatorSelect(res)
            setActiveStep(3)
        }
        // eslint-disable-next-line
    }, [])

    //Handle Node seleccionado.
    useEffect(() => {
        if (nodeSelect?.id) {
            setVarState({
                ...varState,
                nodes: [nodeSelect?.id]
            })
        }
        else {
            setVarState({
                ...varState,
                nodes: []
            })
        }
        setDisabledButtonSave(false)
        // eslint-disable-next-line
    }, [nodeSelect])

    // `${SECCION}-${VARIABLE}-${GRANULARIDAD}-${RANGO}`
    const handleChangeVariable = (e) => {
        let name = e.target.name
        let value = e.target.value
        setDisabledButtonSave(false)
        let stateFormAux = {
            ...stateForm,
            [name]: value
        }
        //Coloco el local del componente VariableFilterOptions
        setStateForm(stateFormAux)
        if (name === 'variable') {
            let CATALOGO = getCatalogo('ALERTS_VARIABLES', 'detail_data')
            let filterVar = CATALOGO.find(e => e.key === value)
            let periods = filterVar?.periods
            let rangoAux = periods[0]?.values[0]?.key
            setGranularity(periods)
            setIndexSelectPeriods(0)
            setRango(rangoAux)
            let SECCION = 'ALERTS_VARIABLES'
            let VARIABLE = stateFormAux.variable
            let valuesAux = `${SECCION}-${VARIABLE}-${periods[0]?.key}-${periods[0]?.values[0]?.key}`
            let varStateObjAux = {
                ...varState,
                values: valuesAux,
            }
            let findOption = uniqueValueList.find(e => e?.granularity === periods[0]?.key)
            let res = findOption?.range.includes(rangoAux)
            if (res) {
                setOpChoice("UNIQUE")
                varStateObjAux = {
                    ...varStateObjAux,
                    variable_operation: "UNIQUE"
                }
            }
            setDisableOperatorSelect(res)
            //Coloco el estado geenral de la variable
            setVarState(varStateObjAux)
            setActiveStep(2)
        }
    }

    //Handle granularidad.
    const handleGranularity = (event, tabNumber) => {
        setIndexSelectPeriods(tabNumber)
        let rangoAux = granularity[tabNumber].values[0].key
        setRango(rangoAux)

        let valuesAux = varState.values
        let spliter = valuesAux
        if (typeof spliter === 'string') {
            spliter = spliter?.split('-')
        }
        if (spliter[2]) {
            spliter[2] = granularity[tabNumber]?.key
            // spliter[3] = granularity[tabNumber]?.values[0]?.key
            spliter.join('-')
            valuesAux = spliter
        }
        else {
            valuesAux += `-${granularity[tabNumber]?.key}`
        }
        if (typeof valuesAux !== 'string') {
            valuesAux = valuesAux?.join('-')
        }
        let varStateObjAux = {
            ...varState,
            values: valuesAux,
        }
        let findOption = uniqueValueList.find(e => e?.granularity === granularity[tabNumber].key)
        let res = findOption?.range.includes(rangoAux)
        if (res) {
            setOpChoice("UNIQUE")
            varStateObjAux = {
                ...varStateObjAux,
                variable_operation: "UNIQUE"
            }
        }
        setDisableOperatorSelect(res)
        //Coloco el estado geenral de la variable
        setVarState(varStateObjAux)
    }

    //Handle periodo.
    const handlePeriod = (e) => {
        let value = e.target.value
        setRango(value)
        let valuesAux = varState.values
        let spliter = valuesAux
        if (typeof spliter === 'string') {
            spliter = spliter?.split('-')
        }
        if (spliter[3]) {
            spliter[3] = value
            spliter.join('-')
            valuesAux = spliter
        }
        else {
            valuesAux += `-${value}`
        }
        if (typeof valuesAux !== 'string') {
            valuesAux = valuesAux?.join('-')
        }

        let varStateObjAux = {
            ...varState,
            values: valuesAux,
        }
        let findOption = uniqueValueList.find(e => e?.granularity === granularity[indexSelectPeriods]?.key)
        let res = findOption?.range.includes(value)
        if (res) {
            setOpChoice("UNIQUE")
            varStateObjAux = {
                ...varStateObjAux,
                variable_operation: "UNIQUE"
            }
        }
        setDisableOperatorSelect(res)
        //Coloco el estado geenral de la variable
        setVarState(varStateObjAux)
    }

    const handleOpChoice = (e) => {
        let value = e.target.value
        setOpChoice(value)
        setActiveStep(3)
        setVarState({
            ...varState,
            variable_operation: value
        })
    }

    const handleOpenCloseModalDevices = () => {
        setIsOpenModalDevice(!openModalDevice)
    }
    return (
        <Box>

            {/* LISTA DE NODOS */}
            <ListNodeSelect
                diagramDynamic={true}
                isOpen={openModalDevice}
                setIsOpen={setIsOpenModalDevice}
                setLocation={setNodeSelect}
                location={nodeSelect}
            />

            {/* TITULO */}
            <Box m={1} >
                <h3 style={{ fontSize: '18px' }}>  Configuración de Variable.</h3>
            </Box >

            {/* STEPPERS */}
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box >
                {/* STEP 1 */}
                <Box display="flex" alignItems="center" flexWrap="wrap" minHeight={60} >
                    <Tooltip
                        title="Elija el dispositivo que desea añadir, haciendo click en el botón +"
                        placement="right"
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 1 })}  >
                            {activeStep >= 1 ? <DoneIcon style={{ padding: '2px' }} /> : 1}
                        </Box>
                    </Tooltip>

                    <Box className={classes.devicesLabel}>
                        Agregar dispositivo
                    </Box>

                    <IconButton
                        aria-label="agregar dispositivos"
                        className={classes.addBtn}
                        onClick={handleOpenCloseModalDevices}
                    >
                        <AddIcon />
                    </IconButton>

                    {nodeSelect?.nombre &&
                        <Chip
                            className={classes.filterChip}
                            icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                            label={nodeSelect.nombre}
                            clickable
                            color="primary"
                            onDelete={() => setNodeSelect({})}
                            onClick={handleOpenCloseModalDevices}
                        />
                    }

                </Box>

                {blueLine}

                {/* STEP 2 */}
                <Box display={'flex'}>
                    <Tooltip
                        title='Elija una variable'
                        arrow
                        placement='right'
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 2 })}  >
                            {activeStep >= 2 ? <DoneIcon style={{ padding: '2px' }} /> : 2}
                        </Box>
                    </Tooltip>

                    <Box width={'90%'} >
                        <Box className={classes.devicesLabel}>
                            Seleccione una variable
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                name='variable'
                                value={stateForm.variable || ''}
                                onChange={handleChangeVariable}
                            >
                                {getCatalogo('ALERTS_VARIABLES', 'detail_data').map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem.key}
                                    >
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {blueLine}

                <Box display={'flex'}>
                    {/* Opcion de granularidad */}
                    {granularity?.length > 0 &&
                        <Box ml={5} mt={2} width={'90%'} >
                            <Box display='flex' alignItems='center' flexWrap='wrap'>
                                <Box className={classes.devicesLabel}>
                                    Granularidad y periodo
                                </Box>
                            </Box>

                            <Box display='flex' justifyContent='center'>
                                <span className={classes.tabsTitle}>Granularidad</span>
                            </Box>

                            <Tabs
                                value={granularity.length > 0 ? indexSelectPeriods : 0}
                                onChange={handleGranularity}
                                indicatorColor='primary'
                                textColor='primary'
                                centered
                            >
                                {
                                    granularity?.map(period => <Tab key={period?.key} label={period?.label} />)
                                }
                            </Tabs>
                            <Box>
                                <FormControl component='fieldset' fullWidth>
                                    <RadioGroup
                                        aria-label='chosen_range'
                                        name='chosen_range'
                                        value={Rango ? Rango : ''}
                                        onChange={handlePeriod}
                                        className={classes.radio}
                                    >
                                        {
                                            granularity[indexSelectPeriods]?.values?.map(option => (
                                                <FormControlLabel
                                                    key={option.key}
                                                    value={option.key}
                                                    control={<Radio color='primary' />}
                                                    label={option.name}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    }
                </Box>

                {/* STEP 3 */}
                <Box display={'flex'} width={'100%'}>
                    <Tooltip
                        title='Nuevo tooltip'
                        arrow
                        placement='right'
                    >
                        <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 3 })}  >
                            {activeStep >= 3 ? <DoneIcon style={{ padding: '2px' }} /> : 3}
                        </Box>
                    </Tooltip>

                    <Box width={'90%'} >
                        <Box className={classes.devicesLabel}>
                            Tipo de operación
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                name='choice'
                                value={opChoice ? opChoice : ''}
                                onChange={handleOpChoice}
                                disabled={disableOperatorSelect}
                            >
                                {getCatalogo('ALERTS_VARIABLES', 'generic_data')?.block_op_choices?.map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem.key}
                                    >
                                        {elem.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

            </Box>
        </Box >
    )
}
export default VariableFilterOptions