import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

//DATEPICKER
import es from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

//STYLES
import { makeStyles } from '@material-ui/core';

// SERVICIOS
import { getNodesTreeContractValidate } from '../../services/hierarchy';

import {
    getBestRate, getRateConfig, getProviders, getContractByIdLoc,
    postNewContract, postContractPower, putContract,
    putContractPower, deleteContract
} from '../../services/billing';
// COMPONENTES
import TopNav from '../common/TopNav';
import FormRatesBilling from '../Rates&Contracts/FormRatesBilling';
import AlertComponent from '../Elements/AlertComponent'
import InputsBox from '../Rates&Contracts/InputsBox'
//DATE

import { formatDateStrDashToSlashReverse } from '../../helpers/common';
import Container from '../Elements/Container';
import ContractNode from './ContractNode';

// HOOKS
import useDeviceDetect from "../../hooks/useDeviceDetect";

let scrollsSettings = {
    overflow: "scroll",
    height: "90vh",
    overflowX: "hidden",
}
let webkitScrollbar = {
    background: "white",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#beb1b18f",
    color: "red",
    width: "10px"
}
let webkitscrollbarTrack = {
    background: "#dfdfe957",
}
const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        display: "flex",
    },
    containerinputs: {
        "@media (max-width: 700px)": {
            marginRight: "5px"
        }
    },
    leftContainer: {
        padding: theme.spacing(2),
        //son los estilos de las barras scroll en una variable.
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        "@media (max-width: 700px)": {
            padding: theme.spacing(0, 0, 0, 1),
        }
    },
    rightContainer: {
        padding: theme.spacing(2),
        //son los estilos de las barras scroll en una variable.
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        "@media (max-width: 700px)": {
            padding: theme.spacing(0, 1, 0, 1),
        }
    },
    form: {
        // marginLeft: theme.spacing(2),
        '& .MuiInputBase-root': {
            color: '#666666',
        },
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: theme.spacing(2),
    },
    labelDate: {
        margin: "5px 15px 0 0",
        fontSize: 16,
    },
    title: {
        "@media (max-width: 700px)": {
            textAlign: "center"
        }

    },
    btn: {
        margin: theme.spacing(3, 1),
    },
    addBtn: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: theme.spacing(2),
        width: theme.spacing(2),
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tableContainer: {
        justifyContent: "center"
    },
    tableTitle: {
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary.light,
        color: 'white',
        height: 48,
    },
    tableItem: {
        padding: theme.spacing(1),
        textAlign: 'center',

    },
    dateContainer: {
        margin: "20px 0 20px 0",
        width: "300px",
        '& .MuiFormControl-marginNormal': {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
        },
    },
    scheduleTitle: {
        fontSize: '13px',
        color: theme.palette.grey.main,
        backgroundColor: theme.palette.grey.dark,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
        borderRadius: theme.spacing(1),
        textAlign: 'center',
    },
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
    },
    timeSelector: {
        width: '100%',
        "@media (max-width: 400px)": {
            width: " 155px"

        }
    },
    alerts: {
        height: "60px",
        paddingLeft: "4%",
        paddingRight: "2%",
        fontSize: "large",
        position: "absolute",
        top: "119px",
        width: 'calc(100% - 55px)',
        zIndex: "2",
        "@media (max-width: 920px)": {
            width: '100%',
        },
    },
    wordCut: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    buttonBack: {
        position: "relative",
        bottom: "16px",
        fontSize: "20px",
        backgroundColor: "#f3f3f3",
        width: "30px",
        borderRadius: "1em",
        textAlign: "center",
        fontWeight: "bold",
        marginTop: "25px"
    },
}));

function ConfigContract() {

    const classes = useStyles();

    const { isMobile } = useDeviceDetect(959);

    const [selectedNode, setSelectedNode] = useState({});

    const initialNewContract = {
        id: "",
        newContractName: "",
        from_date: new Date(),
        to_date: new Date(),
        location: selectedNode.id,
        provider: "",
        providerId: "",
        type: "",
        typeName: "",
        hired_demand: ""
    }

    const initialConfig = {
        provider: '',
        providerName: '',
        rateName: '',
        rateItems: {
            name: '',
            date_to: new Date(),
            date_from: new Date(),
            fixed_charge: 0,
            provider: '',
        },
        contractItems: {
            id: '',
            location: '',
            name: '',
            from_date: new Date(),
            to_date: new Date(),
            rate: '',
            powercontract: [],
            provider: [],
        },
        dividerBar: {
            height: "1px",
            backgroundColor: "grey",
            marginTop: "10px",
            width: "100%"
        },
    };
    const [nodes, setNodes] = useState([]);

    const [config, setConfig] = useState(initialConfig);
    const [newContract, setNewContract] = useState(initialNewContract);
    const [providerItems, setProviderItems] = useState([]);
    const [rateItems, setRateItems] = useState([]);

    //Variable para saber si tengo que crear un nuevo contrato o modificar uno existente.
    const [createContract, setCreateContract] = useState(false);

    //Guardo todos los contratos segun el id de localizacion
    const [contracts, setContracts] = useState([]);

    //Show selects and data
    const [showPowerrateList, setShowPowerrateList] = useState(false)
    const [showRate, setShowRate] = useState(false)

    //Estado para blokear modificar proveedor si contrato existe
    const [blockProvider, setBlockProvider] = useState(false)

    //blokear boton siguiente
    const [blockButton, setBlockButton] = useState(false)

    const [showRightBlock, setShowRightBlock] = useState(true)
    const [showLeftBlock, setShowLeftBlock] = useState(true)
    const [loading, setLoading] = useState(true)


    const [powerContract, setPowerContrac] = useState({
        name: "",
        hired_demand: 0,
        contract: "",
        time_slot: ""
    })

    const [bestRate, setBestRate] = useState({
        id: "",
        provider: false
    })

    //Error message
    const errorMessage = {
        delete: "Se elimino el contrato correctamente",
        needProvider: "Necesita seleccionar un proveedor",
        needTypeRate: "Necesita seleccionar un tipo de tarifa",
        sameName: "Ya existe un contrato con ese nombre"
    }

    //Estados alert
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: ""
    })
    useEffect(() => {

        if (isMobile) {
            // console.log("isMobile")
            setShowRightBlock(false)
        }

        else {
            // console.log("desktop");
            setShowRightBlock(true)
            setShowLeftBlock(true)
        }

    }, [isMobile])

    useEffect(() => {
        const fetchNodesTreeData = async () => {
            try {
                const res = await getNodesTreeContractValidate();
                setNodes(res.data);
                setLoading(false)
            }

            catch (error) {
                console.error(error);
            }
        };

        fetchNodesTreeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchContractById = async () => {
            try {

                const resContract = await getContractByIdLoc(selectedNode.id);
                setContracts(resContract.data);
            }

            catch (error) {
                console.error(error);
            }
        };
        const fetchEnergyProvider = async () => {
            try {

                const res = await getProviders();
                setProviderItems([...res.data, { name: "Otra" }]);
            }

            catch (error) {
                console.error(error);
            }
        }

        // Si tengo un nodo seleccionado quiero obtener su configuración
        if (selectedNode.id) {
            fetchEnergyProvider();
            fetchContractById(selectedNode.id);
            setNewContract({ ...newContract, location: selectedNode.id });
            setCreateContract(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode]);

    const nodeList = () => {

        return nodes.map(node => <ContractNode node={node} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} key={node.id} />);
    };

    const getErrors = () => {
        let errors = false;
        //TODO Chequear que todos los campos sean numéricos
        return errors;
    };

    const handleSelectedNode = async (node) => {

        setShowRate(false)

        setShowPowerrateList(false)

        setProviderItems([])

        setSelectedNode(node)

        setCreateContract(false);

        setBestRate({
            provider: "",
            tarifa: ""
        })

        if (isMobile) {
            setShowLeftBlock(false)
            setShowRightBlock(true)
        }
    };

    const validateName = (name) => {

        let res = false

        contracts.forEach((elem) => {

            if (elem.name === name) {
                res = true
                return
            }
        })

        return res
    }

    const valuePower = (powerName, powerContract) => {

        let res = powerContract.filter(e => e.name === powerName)

        return res[0]?.hired_demand
    }

    const BackPageMobile = () => {
        setShowLeftBlock(true)
        setShowRightBlock(false)
    }

    // HANDLES
    const handleChange = async e => {

        if (e.target.name === "contractName") {

            if (e.target.value === 0) {
                //Si es "NUEVO CONTRATO"

                setNewContract({ ...newContract, newContractName: "" });
                setBlockProvider(false)
                setShowPowerrateList(false)
                setRateItems([])

                setBestRate({
                    provider: "",
                    tarifa: ""
                })

                setConfig(initialConfig);
                setCreateContract(true);
                setShowRate(true)
            }

            else {
                //Si es "CONTRATO EXISTENTE"

                //limpio estado
                setBestRate({
                    id: "",
                    provider: false
                })

                setBlockProvider(true)
                setPowerContrac(e.target.value.powercontract)

                let aux = await getRateConfig(e.target.value.rate)
                let from = formatDateStrDashToSlashReverse(e.target.value.date_from)
                let to = formatDateStrDashToSlashReverse(e.target.value.date_to)

                setNewContract({
                    ...newContract,
                    id: e.target.value.id,
                    from_date: new Date(from),
                    to_date: new Date(to),
                    newContractName: e.target.value.name,
                    provider: aux.data.provider,
                    providerId: aux.data.provider_id,
                    type: aux.data.t_type,
                    typeName: aux.data.type_name,
                })

                setBestRate(aux.data)
                setShowPowerrateList(true)

                let providerSelect = providerItems.filter(elem => elem.id === aux.data.provider_id)

                setRateItems(providerSelect[0].types);
            }
        }

        if (e.target.name === "newContractName") {

            setNewContract({ ...newContract, newContractName: e.target.value });

            if (validateName(e.target.value)) {

                setClose(true)
                setMsg({
                    msg: errorMessage.sameName,
                    type: "error"
                })

                setBlockButton(true)
            }

            else {
                setBlockButton(false)
            }

        }
        if (e.target.name === "providerNewContract") {

            setShowPowerrateList(false)

            const fetchRatesProvider = async () => {

                try {
                    setBestRate({
                        ...bestRate,
                        provider: e.target.value,

                    })
                    //Si seleccione alguna empresa, traigo sus tipos de tarifas
                    let providerSelect = providerItems.filter(elem => elem.name === e.target.value)
                    setNewContract({ ...newContract, provider: e.target.value, providerId: providerSelect[0].id });
                    setRateItems(providerSelect[0].types);
                    setShowRate(true)
                }

                catch (error) {
                    // console.error(error);
                }
            };
            fetchRatesProvider();
        }

        else if (e.target.name === "type" || e.target.name === "rateName") {
            // console.log("entro a type");
            setNewContract({ ...newContract, type: e.target.value.id, typeName: e.target.value.name });
        }
    };

    const handleDateTo = (e, time) => {
        setShowPowerrateList(false)

        if (time === "from") {
            setNewContract({ ...newContract, from_date: e });
        }

        else {
            setNewContract({ ...newContract, to_date: e });
        }
    }

    const handlePower = (e, idx, powerName) => {

        setPowerContrac(powerContract.map((elem, i) => {

            if (elem.name === powerName) {
                elem.hired_demand = e.target.value
                return elem
            }

            else {
                return elem
            }
        }))
    }

    const handleCancel = () => {

        setShowRate(false)
        setProviderItems([])
        setShowPowerrateList(false)
        setSelectedNode({});
        setContracts([])
        setConfig(initialConfig);
        setNewContract(initialNewContract)

        if (createContract) {
            setCreateContract(false);
        }

        if (isMobile) {
            setShowRightBlock(false)
            setShowLeftBlock(true)
        }
    };

    const handleDelete = async () => {
        try {

            await deleteContract(newContract.id);

            setClose(true)
            setMsg({
                msg: errorMessage.delete,
                type: "error"
            })

            handleCancel()
        }

        catch (error) {
            console.error(error);
        }
    }
    //Funcion evento boton Siguiente
    const handlePowerrate = async () => {

        let { providerId, from_date, type, to_date } = newContract

        if (typeof newContract.providerId === "number" && typeof newContract.type === "number") {

            try {
                let from = format(from_date, 'dd/MM/yyyy')
                let to = format(to_date, 'dd/MM/yyyy')

                let aux = await getBestRate(providerId.toString(), type.toString(), from, to, selectedNode.id)

                setBestRate(aux.data)

                let arryPowerrate = []

                aux.data.powerrate.forEach((e) => {
                    let objet = {
                        name: e.name,
                        hired_demand: 0,
                        contract: "",
                        time_slot: e.id
                    }
                    arryPowerrate.push(objet)
                })

                setPowerContrac(
                    arryPowerrate
                )

                setShowPowerrateList(true)
            }

            catch (error) {

                setClose(true)
                setMsg({
                    msg: error.response.data.msg,
                    type: "error"
                })
            }
        }

        if (newContract.providerId === "") {

            setClose(true)
            setMsg({
                msg: "Debe seleccionar una distribuidora",
                type: "error"
            })

            return
        }

        if (newContract.type === "") {

            setClose(true)
            setMsg({
                msg: "Debe seleccionar un tipo de tarifa",
                type: "error"
            })

            return
        }
    }

    const handleSubmit = async e => {

        e.preventDefault();

        let from = format(newContract.from_date, 'yyyy-MM-dd')
        let to = format(newContract.to_date, 'yyyy-MM-dd')

        let newContractRes = {
            name: newContract.newContractName,
            date_from: from,
            date_to: to,
            location: newContract.location,
            rate: bestRate.id,
        }

        if (createContract) {
            // console.log("Se creo Nuevo Contrato")

            if (newContract.newContractName.length > 4) {

                try {

                    let aux = await postNewContract(newContractRes)

                    // SI EL CONTRATO ES NUEVO SE COLOCA ACA SE AGREGA LA PRIPIEDAD time_slot:
                    powerContract.map(async (elem) => {
                        let objPow = {
                            contract: aux.data.id,
                            hired_demand: elem.hired_demand,
                            name: elem.name,
                            time_slot: elem.time_slot
                        }

                        await postContractPower(objPow)

                    })

                    setClose(true)
                    setMsg({
                        msg: "Se creo contrato correctamente",
                        type: "success"
                    })
                }
                catch (error) {

                    setClose(true)
                    setMsg({
                        msg: error.response.data.msg,
                        type: "error"
                    })
                }
            }
            else {

                setClose(true)
                setMsg({
                    msg: "El nombre del contrato debe contener al menos 4 caracteres",
                    type: "error"
                })
            }
        }
        else {
            try {
                // console.log("Se Modifico Contrato Existente")
                await putContract(newContract.id, newContractRes)

                powerContract.map(async (elem) => {
                    let objPow = {
                        contract: newContract.id,
                        hired_demand: elem.hired_demand,
                        name: elem.name,
                        time_slot: elem.time_slot
                    }
                    await putContractPower(elem.id, objPow)
                })

                setClose(true)
                setMsg({
                    msg: "Se modifico contrato correctamente",
                    type: "success"
                })

                handleCancel()
            }
            catch (error) {

                setClose(true)
                setMsg({
                    msg: error.response.data.msg,
                    type: "error"
                })

            }
        }
    }

    const validatePublicContrac = () => {

        if (bestRate.provider === "Otra") {
            return true
        }
        else if (bestRate.public === false) {
            return true
        }
        else {
            return false
        }
    }

    //retorna html linea azul q divide bloques de franjas.
    const blueBar = () => {

        return <div style={{
            backgroundColor: "#2586bc",
            height: "1px ",
            width: " 100 %",
            marginTop: "10px"
        }}></div>


    }

    // //ordena el array de franjas de potencia de menor a mayor por horario.
    const orderArrayTime = (array) => {

        let order = array.sort((a, b) => {
            const timeA = new Date('1970-01-01 ' + a.time_from).getTime();
            const timeB = new Date('1970-01-01 ' + b.time_from).getTime();

            return timeA - timeB;
        });

        return order
    }

    return (
        <>
            <TopNav titulo='Facturación' subtitulo='| (Localizaciónes)'></TopNav>

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container>

                {/* LOADING   */}
                {
                    loading ?

                        <Box display='flex' justifyContent='center' mt={2}>
                            <CircularProgress />
                        </Box>

                        :

                        <Grid className={classes.root} container>

                            {
                                showLeftBlock &&

                                <Grid item xs={12} md={6} className={classes.leftContainer}>

                                    <List >
                                        {/* //si el usuario no tiene nodos */}
                                        {
                                            nodes.length === 0 &&

                                            <h1 style={{ textAlign: "center", fontSize: "19px" }}>

                                                Usted no posee ninguna localización configurada en su cuenta.<br></br>
                                                Por favor, configure al menos una localización para poder continuar.

                                                <NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
                                                    [link]
                                                </NavLink>

                                            </h1>
                                        }

                                        <div  >{nodeList()}</div>

                                    </List>

                                </Grid>
                            }

                            {
                                showRightBlock &&

                                <Grid item xs={12} md={6} className={classes.rightContainer}>

                                    {
                                        isMobile &&

                                        <div className={classes.buttonBack} onClick={BackPageMobile}>{'<'}</div>

                                    }

                                    {/* Si tengo un nodo seleccionado pero config es {} muestro <CircularProgress /> */}
                                    {
                                        selectedNode.id && !Object.keys(providerItems).length ?

                                            (
                                                <Box display='flex' justifyContent='center' mt={2}>
                                                    <CircularProgress />
                                                </Box>
                                            )

                                            :

                                            null
                                    }

                                    {/* Si tengo un valor de provider lo muestro */}
                                    {
                                        Object.keys(providerItems).length ?

                                            (
                                                <>
                                                    <Box mb={2}>

                                                        <Typography variant='h6' className={classes.title}>
                                                            Contrato y tarifa de Facturación
                                                        </Typography>

                                                    </Box>

                                                    {blueBar()}

                                                    <form onSubmit={handleSubmit} className={classes.form}>

                                                        <Grid container className={classes.tableContainer}>

                                                            <Grid item xs={3} className={classes.label}>
                                                                Nombre
                                                            </Grid>

                                                            <Grid item xs={9} className={classes.tableItem}>

                                                                {!createContract ?
                                                                    <FormControl fullWidth>

                                                                        <Select
                                                                            labelId='name'
                                                                            name='contractName'
                                                                            defaultValue=""
                                                                            placeholder="Seleccione contrato"
                                                                            onChange={e => handleChange(e)}
                                                                        >

                                                                            <MenuItem key={0} value={0}>

                                                                                <IconButton aria-label='nuevo contrato' className={classes.addBtn}>
                                                                                    <AddIcon fontSize='small' />
                                                                                </IconButton>

                                                                                Nuevo contrato

                                                                            </MenuItem>

                                                                            {
                                                                                contracts ?

                                                                                    contracts.map((contract, idx) => (

                                                                                        <MenuItem key={idx} value={contract}>
                                                                                            {contract ? contract.name : "Seleccione contrato"}
                                                                                        </MenuItem>

                                                                                    ))

                                                                                    :

                                                                                    ""
                                                                            }
                                                                        </Select>

                                                                    </FormControl>

                                                                    :

                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder='Nuevo contrato'
                                                                        name='newContractName'
                                                                        value={newContract.newContractName}
                                                                        onChange={e => handleChange(e)}
                                                                        color='primary'
                                                                        className={classes.input}

                                                                    />
                                                                }
                                                            </Grid>

                                                        </Grid>

                                                        <Grid container className={classes.tableContainer}>

                                                            <Grid item xs={3} className={classes.label}>
                                                                Empresa Distribuidora
                                                            </Grid>


                                                            <Grid item xs={9} className={classes.tableItem}>

                                                                {
                                                                    bestRate.type_name === 'TarifaCustom' ?

                                                                        <FormControl fullWidth>

                                                                            <Select labelId='provider'
                                                                                disabled={blockProvider}
                                                                                name='providerNewContract'
                                                                                defaultValue=''
                                                                                value={bestRate.provider}
                                                                            >

                                                                                <MenuItem value={bestRate.provider}>
                                                                                    {bestRate.provider}
                                                                                </MenuItem>


                                                                            </Select>

                                                                        </FormControl>

                                                                        :

                                                                        <FormControl fullWidth>

                                                                            <Select labelId='provider'
                                                                                disabled={blockProvider}
                                                                                name='providerNewContract'
                                                                                defaultValue=''
                                                                                value={bestRate.provider}
                                                                                onChange={e => handleChange(e)}>

                                                                                {providerItems.map((providerItem, index) => (

                                                                                    <MenuItem key={index} value={providerItem.name}>
                                                                                        {providerItem.name}
                                                                                    </MenuItem>

                                                                                ))}
                                                                            </Select>

                                                                        </FormControl>
                                                                }

                                                            </Grid>

                                                        </Grid>

                                                        <Grid container className={classes.tableContainer}>

                                                            <Grid item xs={3} className={classes.label}>
                                                                Tarifa
                                                            </Grid>


                                                            {bestRate.provider !== "Otra" &&

                                                                <Grid item xs={9} className={classes.tableItem}>
                                                                    {
                                                                        !showRate ?

                                                                            <FormControl fullWidth>

                                                                                {
                                                                                    bestRate.type_name &&

                                                                                    <Select labelId='name' disabled={blockProvider} name='rateName' key={config.contractItems ? config.contractItems.rate : ""} defaultValue={bestRate.type_name} value={bestRate.type_name} onChange={e => handleChange(e)}>
                                                                                        <MenuItem key={config.contractItems ? config.contractItems.rate : ""} value={bestRate.type_name}>
                                                                                            {bestRate.type_name}
                                                                                        </MenuItem>
                                                                                    </Select>

                                                                                }

                                                                            </FormControl>

                                                                            :

                                                                            <FormControl fullWidth>

                                                                                <Select labelId='name' disabled={blockProvider} name='type' defaultValue="" onChange={e => handleChange(e)}>
                                                                                    {rateItems ? rateItems.map((rate, idx) => (
                                                                                        <MenuItem key={idx} value={rate}>
                                                                                            {rate.name}
                                                                                        </MenuItem>
                                                                                    ))

                                                                                        :

                                                                                        ''}
                                                                                </Select>

                                                                            </FormControl>
                                                                    }

                                                                </Grid>
                                                            }

                                                            {/* si la opcion seleccionada es otra es una tarifa personalizada */}
                                                            {
                                                                bestRate.provider === "Otra" &&

                                                                <>
                                                                    <Grid item xs={9} className={classes.tableItem}>

                                                                        <FormControl fullWidth>
                                                                            <Select
                                                                                labelId="tarifa-personalizada"
                                                                                id="tarifa-personalizada"
                                                                                disabled={true}
                                                                                defaultValue={1}
                                                                            >
                                                                                <MenuItem value={1}>Personalizado</MenuItem>
                                                                            </Select>
                                                                        </FormControl>

                                                                    </Grid>
                                                                </>
                                                            }

                                                        </Grid>

                                                        <Grid container className={classes.tableContainer}>

                                                            <Grid >

                                                                <Grid item xs={3} className={classes.tableItem}>

                                                                    <Box className={classes.dateContainer} display='flex' justifyContent='center'>

                                                                        <Grid item xs={3} className={classes.labelDate}  >
                                                                            Desde:
                                                                        </Grid>

                                                                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                                                                            <KeyboardDatePicker
                                                                                className={classes.date}
                                                                                autoOk
                                                                                disableToolbar
                                                                                variant='inline'
                                                                                format='dd/MM/yyyy'
                                                                                margin='normal'
                                                                                initialFocusedDate={new Date()}
                                                                                // id='date-picker-inline'
                                                                                // label='Date picker inline'
                                                                                disabled={blockProvider}
                                                                                value={newContract.from_date}
                                                                                onChange={e => handleDateTo(e, "from")}
                                                                                KeyboardButtonProps={{
                                                                                    'aria-label': 'cambiar fecha',
                                                                                }}
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    readOnly: createContract ? false : true,
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Box>

                                                                </Grid>

                                                            </Grid>

                                                            <Grid >

                                                                <Grid item xs={3} className={classes.tableItem}>

                                                                    <Box className={classes.dateContainer} display='flex' justifyContent='center'>

                                                                        <Grid item xs={3} className={classes.labelDate}  >
                                                                            Hasta:
                                                                        </Grid>

                                                                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                                                                            <KeyboardDatePicker
                                                                                className={classes.date}
                                                                                autoOk
                                                                                disabled={blockProvider}
                                                                                disableToolbar
                                                                                variant='inline'
                                                                                format='dd/MM/yyyy'
                                                                                margin='normal'
                                                                                value={newContract.to_date}
                                                                                onChange={e => handleDateTo(e, "to")}

                                                                                KeyboardButtonProps={{
                                                                                    'aria-label': 'cambiar fecha',
                                                                                }}

                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    readOnly: createContract ? false : true,
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Box>

                                                                </Grid>

                                                            </Grid>

                                                        </Grid>

                                                        {
                                                            !showPowerrateList && bestRate.provider !== "Otra" &&

                                                            <Box display='flex' justifyContent='center'>
                                                                <Button disabled={blockButton} onClick={handlePowerrate} color='primary' variant='contained' className={classes.btn} >
                                                                    Siguiente
                                                                </Button>
                                                                <Button variant='contained' onClick={handleCancel} className={classes.btn}>
                                                                    Cancelar
                                                                </Button>
                                                            </Box>
                                                        }

                                                        {showPowerrateList && bestRate.public === true &&
                                                            <Box>

                                                                {/* CARGO FIJO INPUT */}
                                                                <Box style={{ display: "flex", marginBottom: "20px" }}>

                                                                    <Box mr={6} component='label' display='block' className={classes.labelCharge}>
                                                                        Cargo Fijo
                                                                    </Box>

                                                                    <TextField
                                                                        className={classes.inputCost}
                                                                        disabled={true}
                                                                        autoFocus
                                                                        type="number"
                                                                        placeholder='$'
                                                                        name='name'
                                                                        value={bestRate.fixed_charge}
                                                                        // disabled={selectedNode?.level_nombre === "sublocalización"}
                                                                        color='primary'
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position='end'>$</InputAdornment>,
                                                                        }}
                                                                    />

                                                                </Box>

                                                                <Box className={classes.containerinputs}>

                                                                    {/* Linea azul q separa bloques */}
                                                                    {blueBar()}

                                                                    {/*   Franjas Horarias de Energia  */}

                                                                    <Box mb={2} component='label' display='block' className={classes.label}>
                                                                        Franjas Horarias de Energia
                                                                    </Box>

                                                                    {/*   INPUTS ENERGIA  */}
                                                                    {orderArrayTime(bestRate.energyrate)?.map((element, idx) => {

                                                                        return (
                                                                            <InputsBox
                                                                                key={idx}
                                                                                disabled={true}
                                                                                element={element}
                                                                            />
                                                                        )

                                                                    })}

                                                                    {/* Linea azul q separa bloques */}
                                                                    {blueBar()}

                                                                    {/*   Franjas Horarias de Potencia  */}

                                                                    <Box mb={2} component='label' display='block' className={classes.label}>
                                                                        Franjas Horarias de Potencia
                                                                    </Box>

                                                                    {/*   INPUTS POTENCIA  */}

                                                                    {orderArrayTime(bestRate.powerrate)?.map((element, idx) => {
                                                                        return (
                                                                            <InputsBox
                                                                                key={idx}
                                                                                valuePower={valuePower}
                                                                                powerContract={powerContract}
                                                                                handlePower={handlePower}
                                                                                disabled={true}
                                                                                potencia={true}
                                                                                element={element}
                                                                            />
                                                                        )
                                                                    })}

                                                                    {/* Linea azul q separa bloques */}
                                                                    {blueBar()}

                                                                </Box>

                                                                <Box display='flex' justifyContent='center'>

                                                                    <Button type='submit' color='primary' variant='contained' className={classes.btn} disabled={getErrors()}>
                                                                        Guardar
                                                                    </Button>

                                                                    <Button variant='contained' onClick={handleCancel} className={classes.btn}>
                                                                        Cancelar
                                                                    </Button>

                                                                    {
                                                                        !createContract &&

                                                                        <Button variant='contained' color='secondary' onClick={handleDelete} className={classes.btn}>
                                                                            Eliminar
                                                                        </Button>

                                                                    }

                                                                </Box>

                                                            </Box>
                                                        }
                                                    </form>

                                                    {/* FORMULARIO DE CREACION DE TARIFAS */}
                                                    {
                                                        validatePublicContrac() &&

                                                        <>
                                                            <FormRatesBilling
                                                                handleCancel={handleCancel}
                                                                bestRate={bestRate}
                                                                setShowLeftBlock={setShowLeftBlock}
                                                                setShowRightBlock={setShowRightBlock}
                                                                isMobile={isMobile}
                                                                selectedNode={selectedNode}
                                                                setSelectedNode={setSelectedNode}
                                                                setClose={setClose}
                                                                setMsg={setMsg}
                                                                createContract={createContract}
                                                                newContract={newContract}
                                                                powerContract={powerContract}
                                                            />
                                                        </>
                                                    }
                                                </>
                                            )
                                            :

                                            <>

                                                {
                                                    !selectedNode.id &&

                                                    <h2 style={{ textAlign: "center" }}  >
                                                        Seleccione una localización para comenzar
                                                    </h2>

                                                }
                                            </>
                                    }

                                    {
                                        bestRate.provider === false && selectedNode.id &&

                                        <Box display='flex' justifyContent='center' mt={5}>
                                            <CircularProgress />
                                        </Box>

                                    }

                                </Grid>

                            }

                        </Grid>
                }

            </Container>
        </>
    );
}
export default ConfigContract;