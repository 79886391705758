import React, { useEffect, useState, useContext } from 'react';
import {
  Box, Button, Chip, CircularProgress, Dialog, DialogContent,
  Grid, IconButton, makeStyles, Tooltip
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TuneIcon from '@material-ui/icons/Tune';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {
  formatDateObjToSlash,
  getControlDay,
  getNextWeekMaxToday,
} from '../../helpers/common';
import Container from '../Elements/Container';
import TopNav from '../common/TopNav';
import Filter from './Filter';
//Funcion que trae LocalStorage
import { getInstantV2 } from '../../services/meters';
import { getUserInfoFromLocalStorage } from '../../services/auth';
import DualChart from './DualChart';
// import { getLabelText } from './helpers';
//plan
import UserContext from '../../context/UserContext';

const useStyles = makeStyles(theme => ({
  dateContainer: {
    padding: '0 8px',
    color: theme.palette.common.white,
    fontSize: 16,
    '& .MuiButtonBase-root': {
      color: theme.palette.common.white,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.common.white,
      width: 125,
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  date: {
    '& .MuiInputBase-root': {
      width: 150,
    },
  },
  nextDayBtn: {
    '&.Mui-disabled': {
      opacity: '0.5',
    },
  },
  btn: {
    margin: theme.spacing(2, 1),
    '& .MuiButton-label': {
      textTransform: 'none',
      // fontSize: 11,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  btnRefresh: {
    '&.MuiButton-root': {
      height: '30px',

      marginTop: '16px',
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiButton-label': {
      textTransform: 'none',
      paddingLeft: '5px',
      // fontSize: 11,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '@media (max-width: 700px)': {
      marginBottom: '10px',
      marginRight: '10px',
    },
  },
  devicesText: {
    marginLeft: theme.spacing(6),
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '200px',
    },
  },
  alert: {
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(0),
    },
  },
  tuneIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  nodosBoxesLabels: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: "wrap",
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  paper: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#cfd1d582',
      borderRadius: '8px',
    },
    '& .MuiPickersDay-dayDisabled': {
      backgroundColor: '#ff000000',
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      backgroundColor: '#ff000000',
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: '#2586bc',
    },
  },
  dialogStyle: {
    '& .MuiDialog-paperScrollPaper': {
      height: '100%'
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 900px)': {
        padding: '2px'
      },
    },
    '& .MuiDialog-paper': {
      '@media (max-width: 600px)': {
        margin: '0px'
      },
    },
    '& .MuiDialog-paperFullWidth': {
      '@media (max-width: 600px)': {
        width: '100%'
      },
    },
  }
}));

export default function Instant() {
  const classes = useStyles();
  const { plan } = useContext(UserContext);
  const filterInitialValues = {
    dispositivos: [],
    dispositivos2: [],
    variable: '',
    granularity: '',
    granularity2: '',
    selectedInitialDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
    selectedEndDate: new Date(),
    showSecondary: false,
    variable2: '',
  };

  const [filterOptions, setFilterOptions] = useState(filterInitialValues);
  const [filterPanel, setFilterPanel] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showAlertOption, setShowAlertOption] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [limitDate, setLimitDate] = useState({
    inst_days_historic: 10,
  });
  const [instantData, setInstantData] = useState([]);
  const [instantData2, setInstantData2] = useState([]);
  const [dataGraph, setDataGraph] = useState({
    dispositivos: [],
    dispositivos2: [],
  });
  const [tz, setTz] = useState('America/Argentina/Buenos_Aires');

  //Valida el timeZone
  useEffect(() => {
    setTz(plan?.info?.tz)
  }, [plan])

  //VARIABLES DE EL CATALOGO.
  const [varsCatalogo, setVarsCatalogo] = useState([]);
  useEffect(() => {
    const VARS_CATALOGO = plan?.sections["INSTANTANEOS"].filter(item => item.enabled);
    let array = VARS_CATALOGO?.map((elem) => {
      return {
        label: elem.tag ? elem.tag : elem.name,
        value: elem.key,
        fields: elem.fields,
        periods: elem.periods,
        phase: elem.phase,
        node: elem.node
      };
    });
    setVarsCatalogo(array)
  }, [plan])
  /////////////////////////////////////

  //Red alert
  useEffect(() => {
    if (showErrorAlert) {
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 7000);
    }
  }, [showErrorAlert])
  //Aca determino cuantos dias hacia atras puede pedir data el usuario  pra evitar consultas inenecesarias al back.
  useEffect(() => {
    setLimitDate(getUserInfoFromLocalStorage());
  }, []);

  //Funcion para buscar los labels de las variables de el catalogo.
  const getLabelText = value => {
    // Cubro caso en el que grafico variable principal y luego vuelvo al gráfico y copio dispositivos 
    if (!value)
      return ''

    const object = varsCatalogo?.find(option => option.value === value);
    return object.label;
  };

  const fetchInstantV2 = async (url, type = 'primary') => {
    try {
      setLoading(true);
      const res = await getInstantV2(url);
      setLoading(false);
      if (res.data.error) {
        setErrorMessage(res.data.error);
        setShowErrorAlert(true);
      } else {
        if (type === 'primary') {
          setInstantData(res.data);
        } else {
          setInstantData2(res.data);
        }
        setShowErrorAlert(false);
        setShowAlertOption(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setErrorMessage(
          `${error.response.data.msg}.`
        );
      } else {
        setErrorMessage(`Ocurrió un error inesperado`);
      }
      setShowErrorAlert(true);
    }
  };

  function decrementPeriod() {
    const initialDate = new Date(filterOptions.selectedInitialDate);
    const endDate = new Date(filterOptions.selectedEndDate);
    const aux = endDate.getTime() - initialDate.getTime();

    const difference = Math.ceil(aux / (24 * 60 * 60 * 1000));

    initialDate.setDate(initialDate.getDate() - difference);
    endDate.setDate(endDate.getDate() - difference);

    setFilterOptions({
      ...filterOptions,
      selectedInitialDate: initialDate,
      selectedEndDate: endDate,
    });
    handleSubmit({
      ...filterOptions,
      selectedInitialDate: initialDate,
      selectedEndDate: endDate,
    });
  }

  function incrementPeriod() {
    const initialDate = new Date(filterOptions.selectedInitialDate);
    const endDate = new Date(filterOptions.selectedEndDate);
    const aux = endDate.getTime() - initialDate.getTime();

    const difference = Math.ceil(aux / (1000 * 3600 * 24));

    initialDate.setDate(initialDate.getDate() + difference);
    endDate.setDate(endDate.getDate() + difference);

    setFilterOptions({
      ...filterOptions,
      selectedInitialDate: initialDate,
      selectedEndDate: endDate,
    });

    handleSubmit({
      ...filterOptions,
      selectedInitialDate: initialDate,
      selectedEndDate: endDate,
    });
  }

  const togglePanel = () => {
    setFilterPanel(!filterPanel);
  };

  const handleSubmit = filter => {
    setShowAlertOption(true)
    //Si tengo dispositivos seleccionados
    if (filter.dispositivos.length > 0) {
      let url = '';

      filter.dispositivos.forEach((set, index) => {
        if (index > 0) {
          url += '&';
        }
        url += `filter=${set.node.id}`;
        if (set.label.nombre) {
          //Si tengo un tag seleccionado: filter=5+tag1
          url += `+`;
          url += `${set.label.id}`;
        }
      });
      //Busco el fields perteneciente a la key seleccionada de el grafico 1.
      let fields1 = varsCatalogo.find(e => e.value === filter.variable).fields
      url += `&from=${formatDateObjToSlash(filter.selectedInitialDate)}`;
      url += `&to=${formatDateObjToSlash(filter.selectedEndDate)}`;
      //funcion q modifica el field de el endpoint para añadir el total a las consultas trifasicas.
      url += `&fields=${fields1}`
      url += `&response_format=v2`;
      url += `&granularity=${filter.granularity.replace(/_MINUTES/g, '')}`

      fetchInstantV2(url);
      setDataGraph(filter);
    }

    if (filter.showSecondary && filter.dispositivos2.length > 0) {
      let url2 = '';
      filter.dispositivos2.forEach((set, index) => {
        if (index > 0) {
          url2 += '&';
        }
        url2 += `filter=${set.node.id}`;
        if (set.label.nombre) {
          //Si tengo un tag seleccionado: filter=5+tag1
          url2 += `+`;
          url2 += `${set.label.id}`;
        }
      });
      //Busco el field perteneciente a la key seleccionada de el grafico 2.
      let fields2 = varsCatalogo.find(e => e.value === filter.variable2).fields
      url2 += `&from=${formatDateObjToSlash(filter.selectedInitialDate)}`;
      url2 += `&to=${formatDateObjToSlash(filter.selectedEndDate)}`;
      //funcion q modifica el field de el endpoint para añadir el total a las consultas trifasicas.
      url2 += `&fields=${fields2}`
      url2 += `&response_format=v2`;
      url2 += `&granularity=${filter.granularity2.replace(/_MINUTES/g, '')}`

      fetchInstantV2(url2, 'secondary');
    }
    setFilterOptions({
      ...filter,
      dispositivos: filter.dispositivos,
      dispositivos2: filter.dispositivos2,
    });
    //Cierro el panel de Filtros
    setFilterPanel(false);
  };

  //Hanle date del filtro Este handle solamente maneja el date de el filtro el cual se activa con el boton consultar
  const handleDateChange = (date, field, topNav) => {
    let differenceBetweens =
      format(date, 'MM/dd/yyyy') <
      getControlDay(
        filterOptions.selectedEndDate,
        limitDate?.menu?.inst_days_request
      );
    //Este if es para q ue si la primer fecha DESDE es mayor a la segunda fecha HASTA  la iguale a primera.
    if (
      field === 'selectedInitialDate' &&
      date > filterOptions.selectedEndDate
    ) {
      setFilterOptions({
        ...filterOptions,
        selectedEndDate: date,
        [field]: date,
      });
      if (topNav === 'nav') {
        handleSubmit({
          ...filterOptions,
          selectedEndDate: date,
          [field]: date,
        });
      }
    }
    //Este if es para q si la fecha DESDE es inferior a la cantidad de dias q tiene eñ usuario con respecto a HASTA calendario.
    else if (
      field === 'selectedInitialDate' &&
      differenceBetweens &&
      date < filterOptions.selectedEndDate
    ) {
      //Aca cambio la fecha de nuevo a formato Wed Sep 21 2022 17:38:00 GMT-0300 (Argentina Standard Time)
      let aux = new Date(
        getNextWeekMaxToday(date, limitDate?.menu?.inst_days_request)
      );
      setFilterOptions({
        ...filterOptions,
        selectedEndDate: aux,
        [field]: date,
      });
      if (topNav === 'nav') {
        handleSubmit({
          ...filterOptions,
          selectedEndDate: date,
          [field]: date,
        });
      }
    } else {
      setFilterOptions({
        ...filterOptions,
        [field]: date,
      });
      if (topNav === 'nav') {
        handleSubmit({
          ...filterOptions,
          [field]: date,
        });
      }
    }
  };

  const handleClose = () => {
    setFilterPanel(false);
  };

  const isFuture = someDate => {
    const today = new Date();
    return someDate > today;
  };
  return (
    <>
      <TopNav titulo="Analisis" subtitulo="| Instantáneos">
        <Grid container justifyContent="flex-end">
          {limitDate && (
            <Box
              className={classes.dateContainer}
              display="flex"
              justifyContent="center"
            >
              <IconButton
                aria-label="período anterior"
                onClick={decrementPeriod}
                disabled={loading}
              >
                <NavigateBeforeIcon />
              </IconButton>

              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.date}
                  readOnly={true}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  // id='date-picker-inline'
                  // label='Date picker inline'
                  value={filterOptions.selectedInitialDate}
                  onChange={e =>
                    handleDateChange(e, 'selectedInitialDate', 'nav')
                  }
                  minDate={getControlDay(
                    new Date(),
                    limitDate?.menu?.inst_days_historic
                  )}
                  maxDate={new Date()}
                  maxDateMessage=""
                  minDateMessage=""
                  KeyboardButtonProps={{
                    'aria-label': 'cambiar fecha',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}

                // PopoverProps={{ classes: classes }}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  readOnly={true}
                  className={classes.date}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  // id='date-picker-inline'
                  // label='Date picker inline'
                  value={filterOptions.selectedEndDate}
                  onChange={e => handleDateChange(e, 'selectedEndDate', 'nav')}
                  KeyboardButtonProps={{
                    'aria-label': 'cambiar fecha',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  minDate={new Date(filterOptions.selectedInitialDate)}
                  maxDate={getNextWeekMaxToday(
                    filterOptions.selectedInitialDate,
                    limitDate?.menu?.inst_days_request
                  )}
                  maxDateMessage=""
                  minDateMessage=""
                // PopoverProps={{ classes: classes }}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                className={classes.nextDayBtn}
                aria-label="período siguiente"
                onClick={incrementPeriod}
                disabled={isFuture(filterOptions.selectedEndDate) || loading}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          )}

          <Button
            className={classes.btn}
            type="button"
            onClick={togglePanel}
            size="small"
            startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
          >
            | Filtrar
          </Button>

          {/* Boton refresh */}
          <Tooltip
            title="Última consulta."
            arrow
          >
            <Button
              className={classes.btnRefresh}
              type="button"
              onClick={() => handleSubmit(filterOptions)}
              // size='small'
              startIcon={<FlipCameraAndroidIcon />}
            ></Button>
          </Tooltip>

        </Grid>
      </TopNav>

      <Dialog
        className={classes.dialogStyle}
        open={filterPanel}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        disableScrollLock
      >
        {/* <DialogTitle id="form-dialog-title">Filtrar</DialogTitle> */}
        <DialogContent>
          <Filter
            varsCatalogo={varsCatalogo}
            onSubmit={handleSubmit}
            filter={filterOptions}
            setFilterPanel={setFilterPanel}
            handleDateChange={handleDateChange}
            limitDate={limitDate}
            setFilterOptions={setFilterOptions}
          />
        </DialogContent>
      </Dialog>

      <Container>
        {showErrorAlert ? (
          <Box className={classes.alert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  // color='error'
                  size="small"
                  onClick={() => {
                    setShowErrorAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Box>
        ) : null}

        {(filterOptions.dispositivos.length > 0 ||
          filterOptions.dispositivos2.length > 0) &&
          !showAlertOption ? (
          <>
            <Box className={classes.nodosBoxesLabels}>
              <p className={classes.devicesText}>Gráfico principal:</p>
              {filterOptions.dispositivos.map((device, indx) => (
                <Box key={indx} >
                  <Chip
                    className={classes.chip}
                    key={device.id}
                    icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                    label={`${device.node.nombre}  ${device.label.nombre ? `+ ${device.label.nombre}` : ''
                      }`}
                    clickable
                    color="primary"
                    onClick={() => setFilterPanel(true)}
                  />
                  <Chip
                    className={classes.chip}
                    label={
                      <Box component="span">
                        <strong>Variable:</strong>{' '}

                        {getLabelText(filterOptions?.variable)}
                      </Box>
                    }
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => setFilterPanel(true)}
                  />
                </Box>
              ))}
            </Box>
            <Box className={classes.nodosBoxesLabels}>
              {
                // Contemplo caso de gráfico de dispositivo principal. Vuelta al filtro habilitación de secundario y click outside.
                filterOptions.dispositivos2.length > 0 && filterOptions.variable2 !== '' && filterOptions.granularity2 !== '' && instantData2.length !== 0
                &&
                <p className={classes.devicesText}>Gráfico secundario:</p>
              }
              {
                // Contemplo caso de gráfico de dispositivo principal. Vuelta al filtro habilitación de secundario y click outside.
                filterOptions.variable2 !== '' && filterOptions.granularity2 !== '' && instantData2.length !== 0 && filterOptions.dispositivos2.map(device => (
                  <Box key={device.id}>
                    <Chip
                      className={classes.chip}
                      icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                      label={`${device.node.nombre}  ${device.label.nombre ? `+ ${device.label.nombre}` : ''
                        }`}
                      clickable
                      color="secondary"
                      onClick={() => setFilterPanel(true)}
                    />
                    <Chip
                      className={classes.chip}
                      label={
                        <Box component="span">
                          <strong>Variable:</strong>{' '}
                          {getLabelText(filterOptions?.variable2)}
                        </Box>
                      }
                      color="secondary"
                      variant="outlined"
                      clickable
                      onClick={() => setFilterPanel(true)}
                    />
                  </Box>
                ))}
            </Box>
          </>
        ) : (
          <Alert className={classes.alert} severity="info">
            Bienvenido a Instantáneos. En esta sección podrá consultar la
            información de consumos instantáneos de sus dispositivos. Para
            comenzar haga click en el botón
            <span className={classes.tuneIcon}>
              <TuneIcon />
            </span>
            , ingrese los parámetros y haga click en CONSULTAR.
          </Alert>
        )}
        {loading ? (
          <div className={classes.circular}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {dataGraph.dispositivos.length > 0 &&
              !showErrorAlert &&
              !showAlertOption && (
                <DualChart
                  tz={tz}
                  chartData={instantData}
                  chartData2={instantData2}
                  chartOptions={dataGraph}
                  granularityDinamicDash={filterOptions.granularity.replace(/_MINUTES/g, '')}
                />
              )}
          </>
        )}
      </Container>
    </>
  );
}
