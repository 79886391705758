import React, { useContext } from 'react';

//components
import TopNav from '../common/TopNav';

// context
import UserContext from '../../context/UserContext';
import SubaccountsCreator from './SubaccountsCreator';
import SubaccountsViewer from './SubaccountsViewer';


function SubaccountsConfig() {
  const { plan } = useContext(UserContext)

  // console.log(plan)

  return (
    <>
      <TopNav titulo="Configuración" subtitulo='| Sub-Cuentas' />

      {plan?.access?.configuracion_usuario_subcuentas &&
        <SubaccountsCreator plan={plan} />}

      {plan?.access?.configuracion_usuario_invitaciones &&
        <SubaccountsViewer plan={plan} />}
    </>
  )
}

export default SubaccountsConfig