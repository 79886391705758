import { Box, Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip } from '@material-ui/core';

import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
//Icons
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import SpeedIcon from '@material-ui/icons/Speed';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import SyncAltSharpIcon from '@mui/icons-material/SyncAltSharp';

let locationMobileStyles = {
	height: "50px",
	padding: "10px",
	marginBottom: "1px"
}
const useStyles = makeStyles(theme => ({
	nested: {
		paddingLeft: theme.spacing(4),
		"@media (max-width: 700px)": {
			position: "relative",
			left: "-32px",
			width: 'calc(100% + 32px)',
		},
	},
	selected: {
		'&.MuiListItem-root.Mui-selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},

		'&.MuiListItem-root.Mui-selected p': {
			color: theme.palette.common.white,
		},
	},
	nodeHeader: {
		display: 'flex',
		'& span': {
			fontSize: "larger",
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap",
		},
		'& p': {
			marginLeft: '5px',
			fontSize: "larger"
		},
		"@media (max-width: 700px)": {
			flexDirection: " column",
			'& span': {
				marginLeft: '5px',
				fontSize: "small",
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
			},
			'& p': {
				marginLeft: '5px',
				fontSize: "small",
			},
		},

	},
	empresa: {
		"@media (max-width: 700px)": {
			// backgroundColor: theme.palette.primary.main,
			...locationMobileStyles
		},
	},
	zona: {
		"@media (max-width: 700px)": {
			// background: "#c1c1c1",
			marginLeft: theme.spacing(1),
			...locationMobileStyles
		}
	},
	localizacion: {
		"@media (max-width: 700px)": {
			// background: "#e0e0e0",
			marginLeft: theme.spacing(2),
			...locationMobileStyles
		}
	},
	sublocaclizacion: {
		"@media (max-width: 700px)": {
			// background: "#f1efef",
			marginLeft: theme.spacing(3),
			...locationMobileStyles
		}
	},
	wordCut: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	iconMedidor: {
		"@media (max-width: 700px)": {
			marginRight: theme.spacing(6)
		}
	}
}));


export default function Node({ node, selectedNode, handleSelectedNode, dashboard, optionVariableNode, statusNodes, diagramDynamic }) {
	const classes = useStyles();
	const [open, setOpen] = useState(dashboard ? true : (node?.level_nombre === 'localización' ? false : true));
	const [statusNode, setStatusNode] = useState(true);

	//Verifica status de el medidor.
	useEffect(() => {
		if (!node?.virtual) {
			let nodeId = node?.id
			let nodeAux = statusNodes?.find(e => e.node_id === nodeId)
			setStatusNode(nodeAux?.online)
		}
	}, [statusNodes, node])

	const handleArrows = () => {
		setOpen(!open);
	}

	const classNodeSelect = (node) => {
		if (node.level_nombre === "empresa") {
			return classes.empresa
		}
		else if (node.level_nombre === "zona") {
			return classes.zona
		}
		else if (node.level_nombre === "localización") {
			return classes.localizacion
		}
		else if (node.level_nombre === "sublocalización") {
			return classes.sublocaclizacion
		}
		else {
			return ""
		}
	}

	//Valida la propiedad node de las opciones de la variable seleccionada con el fin 
	//de saber si la variable esta habilitada para VIRTUAL o fISICO
	const validateNodeVariableCkeck = () => {
		let findVirtual = optionVariableNode?.includes('VIRTUAL')
		let findFisical = optionVariableNode?.includes('FISICO')
		if (node.virtual && findVirtual) {
			return false
		}
		if (!node.virtual && findFisical) {
			return false
		}
		return true
	}

	const disabledNode = () => {
		if (diagramDynamic) {
			return false
		}
		if (dashboard) {
			if (validateNodeVariableCkeck()) {
				return true
			}
			return false
		}
		else {
			if (node.level_nombre === 'localización') {
				// if (node?.has_default_contract) {
				return false
			}
			else {
				return true
			}
		}
	}

	return (
		<>
			<ListItem
				button
				selected={selectedNode ? selectedNode.id === node.id : false}
				className={clsx(classes.selected, classNodeSelect(node))}
				onClick={() => handleSelectedNode(node)}
				disabled={disabledNode()}
			>
				<ListItemText primary={node.nombre} secondary={`(${node.level_nombre})`} className={classes.nodeHeader} />



				{/* Icono usuario generador*/}
				{node.es_generador &&
					<Tooltip title={`Usuario generador`}>
						<IconButton
							edge='end'
							aria-label='es generador'
						>
							<WbSunnyIcon style={{ color: '#F59500' }} />
						</IconButton>
					</Tooltip>
				}

				{/* Icono bidireccional */}
				{node.es_bidireccional &&
					<Tooltip title={`Bidireccional`}>
						<IconButton
							edge='end'
							aria-label='bidireccional'
						>
							<SyncAltSharpIcon style={{ color: '#0000008a' }} />
						</IconButton>
					</Tooltip>
				}

				{/* ICON MEDIDOR FISICO */}
				<Box width={30}
					className={clsx(classes.iconMedidor)}>
					{node.medidor &&
						<Tooltip title={`${node.nombre} tiene un medidor asociado`}>
							<IconButton	>
								<SpeedIcon style={statusNode ? { color: '#66bb6a' } : { color: '#ef5350' }} />
							</IconButton>
						</Tooltip>
					}
				</Box>

				{/* ICON OPEN CLOSE */}
				<ListItemSecondaryAction >
					<Box width={30}>
						{node.hijos &&
							node.hijos.length > 0 &&
							(open ? (
								<IconButton edge='end' aria-label='contraer' onClick={handleArrows}>
									<ExpandLess />
								</IconButton>

							) : (

								<IconButton edge='end' aria-label='expandir' onClick={handleArrows}>
									<ExpandMore />
								</IconButton>
							))}
					</Box>
				</ListItemSecondaryAction>

			</ListItem>

			{node.hijos ? (
				<Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
					<List dense component='div' disablePadding>
						{node.hijos.map(c => (
							<Node
								optionVariableNode={optionVariableNode}
								node={c} k
								ey={c.id}
								handleSelectedNode={handleSelectedNode}
								selectedNode={selectedNode}
								diagramDynamic={diagramDynamic}
								dashboard={dashboard}
								key={c.id}
								statusNodes={statusNodes}
							/>
						))}
					</List>
				</Collapse>
			) : null}
		</>
	);
}
