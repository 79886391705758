import React, { useState } from 'react';

// @material-ui/core
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';

// @material-ui/icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

// common components
import ErrorAlert from '../common/ErrorAlert';
import StatusChip from './StatusChip';

// custom hooks
import useInvitations from '../../hooks/useInvitations';

// helpers
import { truncateWithEllipsis } from '../../helpers/common';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(8),
    "@media (max-width: 700px)": {
      marginLeft: 0
    },
  },
  row: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(1),
    "& .MuiGrid-item": {
      textAlign: 'center'
    },

    "@media (max-width: 700px)": {
      padding: theme.spacing(1),
      flexDirection: 'column',
      "&>*:nth-child(2)": {
        order: -1,
      },
    },
  },
  nodeList: {
    listStyleType: 'none',
    textAlign: 'center',
    padding: 0,
    margin: 0,
    "& li": {
      display: 'inline',
      paddingLeft: theme.spacing(1),
      fontWeight: 'bold',
      "@media (max-width: 700px)": {
        display: 'block'
      }
    }
  },
  btns: {
    marginLeft: 'auto',
    display: 'flex',
    "@media (max-width: 700px)": {
      marginLeft: 'inherit'
    }
  },
}));

function SubaccountsViewer({ plan }) {
  const classes = useStyles();
  const { invitations, loading, error, revokeInvitation } = useInvitations()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedInvite, setSelectedInvite] = useState(null)

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmRevoke = async () => {
    await revokeInvitation(selectedInvite);
    setIsDialogOpen(false)
  };

  // console.log(invitations)
  return (
    <Box className={classes.container} display='flex' justifyContent='center' flexDirection='column' px={2} py={1}>
      <Box component="h3" color="primary.main" ml={0.5} my={0.5}>Gestión de Invitaciones</Box>
      {error && (
        <Box>
          <ErrorAlert message={error} severity="warning" />
        </Box>
      )}
      {loading &&
        <Box display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      }
      {/* Para evitar un nuevo request cuando elimino al último elemento */}
      {
        !loading && !error && invitations.length === 0 &&
        < ErrorAlert message="Su cuenta no posee medidores compartidos." severity='warning' />

      }

      {invitations.length > 0 &&
        <Box bgcolor="background.paper" color="primary.main" px={1} borderRadius={4}>
          <Box component="h4">Invitaciones Recibidas</Box>

          {invitations.map(({ id, status, hierarchy, owner }) => (
            <Box className={classes.row} key={id}>
              <Box><StatusChip status={status} /></Box>
              <Box><ul className={classes.nodeList}>{hierarchy.map(h => <li key={h.id}>{h.nombre}</li>)}</ul></Box>
              <Box>{truncateWithEllipsis(owner, 15)}</Box>
              <Box className={classes.btns}>
                <Box>
                  <IconButton aria-label='editar invitación' disabled={true} >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Box>
                  {plan?.info?.plan !== "Demo" &&
                    <IconButton aria-label='revocar invitación' onClick={() => { setIsDialogOpen(true); setSelectedInvite(id) }}>
                      <DeleteIcon />
                    </IconButton>
                  }
                </Box>
              </Box>
            </Box>
          ))}

        </Box>
      }
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>¿Desea revocar la invitación?</DialogTitle>
        <DialogContent>Una vez eliminada la invitación la misma no podrá ser restaurada. Para volver a tener acceso, el usuario administrador tendrá que gestionar la Sub-Cuenta nuevamente</DialogContent>
        <DialogActions>
          <Button color='default' onClick={handleCloseDialog}>
            Cancelar
          </Button>
          <Button type='submit' color='secondary' variant='outlined' onClick={handleConfirmRevoke}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>

  )
}

export default SubaccountsViewer