import { useEffect, useState } from "react";
import { getSubaccountInvites, inviteRevoke } from "../services/client";

const useInvitations = () => {
  const [loading, setLoading] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [error, setError] = useState(null);

  const fetchInvitations = async () => {
    try {
      setLoading(true);
      setError(null)
      const res = await getSubaccountInvites();
      setInvitations(res.data)
      setLoading(false);
    }
    catch (error) {
      // console.error(error);
      setLoading(false);
      setInvitations([])
      if (error.response) {
        setError(`${error.response.data.msg}`);
      } else {
        setError(`Ocurrió un error inesperado`);
      }

    };
  }
  const revokeInvitation = async (id) => {

    try {

      setLoading(true);
      setError(null)
      await inviteRevoke(id);

      setLoading(false);
      setInvitations(invitations => invitations.filter(invitation => invitation.id !== id))
    }

    catch (error) {
      // console.error(error);
      setLoading(false);

      if (error.response) {
        setError(`${error.response.data.msg}.`);
      }

      else {
        setError(`Ocurrió un error inesperado`);
      }
    };
  }

  useEffect(() => {

    fetchInvitations()

  }, [])

  return { invitations, loading, error, revokeInvitation };

}

export default useInvitations;