import { useEffect, useState, } from 'react';
//Material ui
import {
    Box, makeStyles, Tooltip
} from '@material-ui/core';
//icons 
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import NorthIcon from '@mui/icons-material/North';
//Style
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    containerMain: {
        padding: theme.spacing(0, 4, 4.5, 4),
        height: "100%",
    },
    mainTitle: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.2),
        fontWeight: "600",
        height: '39%'
    },
    containerTop: {
        height: "80%"
    },
    paddingOption: {
        padding: theme.spacing(2, 0, 0, 0),
    },
    containerUnityValueAux: {
        "@media (max-width: 1650px)": {
            width: '50%',
        },
    },
    unity: {
        fontSize: theme.spacing(3.5),
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        cursor: 'pointer'
    },
    value: {
        fontSize: theme.spacing(4),
    },
    containerUnityValue: {
        color: theme.palette.grey.hairline,
        gap: theme.spacing(0.5),
        "@media (max-width: 1600px)": {
            fontSize: theme.spacing(3),
        },
    },
    containerFooter: {
        borderTop: `solid 1px ${theme.palette.primary.main}`,
        width: "100%",
    },
    containerFooterTitleIcon: {
        marginTop: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 0, 0)
    },
    titleFooter: {
        margin: theme.spacing(0, 0, 0, 0),
        color: theme.palette.primary.dark,
        fontSize: theme.spacing(2.7),
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        "@media (max-width: 1300px)": {
            fontSize: theme.spacing(2.5),
        },
        "@media (max-height: 830px)": {
            fontSize: theme.spacing(2.3),
        }
    },
    qualityFooterText: {
        color: theme.palette.primary.main,
        fontSize: theme.spacing(2),
        margin: theme.spacing(0, 0, 0, 0),
    },
}));

//si quality es enviado  el componente se adapta para dibujar la calidad de energia debera recibir el objeto quality.
export default function IndictorCard({
    title, titleFooter, value, unity,
    icon, arrow, subTitle, compareValue,
    quality, img, state, size, nameCompareValue, marginTitle, valueForGenerator }) {

    const classes = useStyles();
    const [compareaNameString, setcompareaNameString] = useState('ayer')
    const [objetData, setObjetData] = useState(
        {
            arrowColor: "red",
            arrowDirection: "rotate(0deg)",
            percentageValue: 0,
            text: ""
        })

    useEffect(() => {
        returnStringPeriod()
        calcPercentage(value, compareValue)
        // eslint-disable-next-line 
    }, [])

    const calcPercentage = (value, compareValue) => {
        if (value < 0 || compareValue < 0) {
            setObjetData({
                arrowColor: "green",
                arrowDirection: "rotate(180deg)",
                percentageValue: '-',
                text: "menos"
            })
            return
        }

        if (compareValue) {
            // Convertir los strings a números.
            const valueNum = value > 0 ? parseFloat(value.replace(/,/g, '')) : 0;
            const compareValueNum = compareValue > 0 ? parseFloat(compareValue.replace(/,/g, '')) : 0;
            // Calcular la diferencia en términos porcentuales.
            const diferencia = ((valueNum - compareValueNum) / compareValueNum) * 100;
            // Crear la cadena con la información requerida .        
            if (diferencia > 0) {
                // mensaje = `El valor ${value} es ${diferencia.toFixed(2)}% mayor que ${compareValue}.`;
                setObjetData((prevState) => ({
                    ...prevState,
                    percentageValue: diferencia.toFixed(2),
                    text: "mas"
                }))
            }
            else if (diferencia < 0) {
                // mensaje = `El valor ${value} es ${Math.abs(diferencia).toFixed(2)}% menor que ${compareValue}.`;
                setObjetData({
                    arrowColor: "green",
                    arrowDirection: "rotate(180deg)",
                    percentageValue: Math.abs(diferencia).toFixed(2),
                    text: "menos"
                })
            }
            else {
                // mensaje = `El valor ${value} es igual a ${compareValue}.`;
            }
            // Devolver la cadena con el resultado        
        }
    }

    //Funcion ara añadir padding dinamicamente segun el componente requerido
    const usePadding = (variation) => {
        if (variation) {
            return ""
        } else {
            return classes.paddingOption
        }
    }

    const returnStringPeriod = () => {
        if (nameCompareValue === 'Este mes') {
            setcompareaNameString('el mes pasado')
        }
        if (nameCompareValue === 'Este año') {
            setcompareaNameString('el año pasado')
        }
    }

    return (
        <Box className={classes.containerMain}>

            <Box mt={marginTitle ? marginTitle : 0} className={classes.containerTop} >
                {/* Aca se coloca el titulo principal del componente card */}
                <Box className={classes.mainTitle} style={size ? { fontSize: "14px" } : {}} >
                    {title} <span style={{ fontWeight: '400' }}>{subTitle}</span>
                </Box>
                {/* Aca se coloca la unidad del titulo principal*/}
                <Box className={clsx(classes.containerUnityValue, usePadding(state))} display={img ? "flex" : ""} justifyContent="space-between">

                    {state && <p className={classes.qualityFooterText} >Capacidad instalada:</p>}

                    <Box className={classes.containerUnityValueAux} display={state ? "flex" : ""} >
                        <Box className={classes.value} style={{ fontWeight: "600", marginRight: "5px" }}>{value === -1 ? 0 : value}</Box>
                        <Tooltip
                            title={`${value} ${unity}`}
                            arrow
                            placement='right'
                        >
                            <Box className={classes.unity}>{unity}</Box>
                        </Tooltip>
                    </Box>

                    {img &&
                        <Box  >
                            <img style={{
                                width: "125px",
                                position: 'relative',
                                bottom: '22px'
                            }} src={`/images/dashboard/${img}.svg`} alt='svg' />
                        </Box>
                    }

                    {state &&
                        <Box >
                            <Box display="flex" alignItems={"center"}>
                                <Box>
                                    <p className={classes.qualityFooterText} >Estado:</p>
                                    {valueForGenerator > 0  ? <img style={{
                                        width: "104px",
                                    }} src={`/images/dashboard/${"planta-generacion-verde"}.svg`} alt='svg' /> :
                                    <img style={{
                                        width: "70px", marginTop:'15px'
                                    }} src={`/images/dashboard/${"planta-generacion-rojo"}.svg`} alt='svg' />
                                    }
                                </Box>
                                <Box style={{ color: valueForGenerator > 0 ? "#11bb11" : "#ff0000", fontSize: "19px", marginLeft: "10px" }}>
                                    {valueForGenerator > 0 ? 'ONLINE' : 'OFFLINE'}
                                </Box>
                            </Box>
                        </Box>
                    }

                </Box>
            </Box>

            {
                !state &&
                <Box className={classes.containerFooter}>
                    <Box className={classes.containerFooterTitleIcon}>
                        {/* propiedad arrow es para activar la flecha de comparacion de valores */}
                        {arrow ?
                            <>
                                {compareValue > 0 ?
                                    <>
                                        {<NorthIcon style={{ color: objetData.arrowColor, transform: objetData.arrowDirection }} />}
                                        <p className={classes.titleFooter}>{`${objetData.percentageValue}% que ${nameCompareValue ? compareaNameString : 'el mes anterior'}`}</p>
                                    </>
                                    :
                                    <>
                                        <Tooltip
                                            title={'El valor previo a comparar es cero o negativo.'}
                                            arrow
                                            placement="bottom"
                                        >
                                            <WarningAmberIcon style={{ fontSize: '35px', cursor: 'pointer', color: '#7a6f6f' }} />
                                        </Tooltip>
                                    </>
                                }
                            </>
                            :
                            <>
                                <p className={classes.titleFooter}>{titleFooter}</p>
                            </>
                        }
                        {/* Propiedad quality es para activar los valores de la card de energia */}
                        {
                            quality &&
                            <Box display="flex" flexDirection={"column"} >
                                <p className={classes.qualityFooterText}>{`Sobretensión ${quality?.vmax?.ocurrencia !== undefined ? quality?.vmax?.ocurrencia : "-"}%`}</p>
                                <p className={classes.qualityFooterText}>{`Subtensión ${quality?.vmin?.ocurrencia !== undefined ? quality?.vmin?.ocurrencia : "-"}%`}</p>
                            </Box>
                        }
                    </Box>
                </Box>
            }

        </Box >
    )
}

