import { useState, useEffect, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, makeStyles } from '@material-ui/core';
//Componentes.
import ModalSettings from '../ModalSettings';
//Hook.
import DiagramContext from '../Store/DiagramContext';
//Helper
import { getSettingBlock } from '../helpers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#f5f5f5',
        border: `1px solid ${`black`}`,
        width: `70px`,
        height: `70px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    },
    disabledStyle: {
        pointerEvents: 'none',
        opacity: 0.5,
        backgroundColor: '#f0f0f0',
        borderRadius: '100%',
        color: '#a0a0a0',
        padding: '20px',
        width: `70px`,
        height: `70px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function ALERT({ id, isConnectable, type, selected }) {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false)
    const [disabledNode, setDisabledNode] = useState(false);
    const { stateDiagram } = useContext(DiagramContext)

    useEffect(() => {
        getSettingBlock(type)
        // eslint-disable-next-line
    }, [])

    //Deshabilita el nodo segun el nodo el cual se esta conectando.
    useEffect(() => {
        let res = stateDiagram.disabledNodes?.includes(type)
        setDisabledNode(res)
        // eslint-disable-next-line
    }, [stateDiagram.disabledNodes])

    const handleOpenModal = () => {
        setOpenModal(!openModal)
    }

    return (
        <Box
            className={disabledNode ? classes.disabledStyle : classes.root}
            key={id}
            style={{
                border: selected ? '1px dashed #2586bc' : '1px solid black',
            }}
            onDoubleClick={handleOpenModal}
        >

            {/* Modal Dinamico */}
            <ModalSettings
                handleOpenModal={handleOpenModal}
                openModal={openModal}
                id={id}
                type={type}
                userConfiguration={true}
            />

            <Box fontSize={11}   >
                ALERT
            </Box>

            <Handle
                type="target"
                position={Position.Left}
                id="1"
                style={{ width: 9, height: 9 }}
                isConnectable={disabledNode ? false : isConnectable}
            />
        </Box>
    );
}
export default ALERT;