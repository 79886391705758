import { Alert } from "@material-ui/lab";
import ShowDiferenceBar from "./ShowDiferenceBar";
import { Box, CircularProgress } from "@material-ui/core";
import { useState } from "react";

export default function ContainerDiferenceBar({ title, loading, subtitle, unity = 'kWh', value1, value2, labelValue1, labelValue2, backgroundColorValue1, backgroundColorValue2 }) {
    const [msg] = useState({
        msg: 'No se dispone de datos suficientes para hacer la comparación',
        type: 'error'
    })

    return (
        <>
            {!loading ?
                <>
                {(value1 && value2) ? <ShowDiferenceBar
                        title={title}
                        subTitle={subtitle}
                        unity={unity}
                        value1={value1}
                        value2={value2}
                        labelValue1={labelValue1}
                        labelValue2={labelValue2}
                        backgroundColorValue1={backgroundColorValue1}
                        backgroundColorValue2={backgroundColorValue2} />
                    :
                    <Alert severity={msg.type}>{msg.msg}</Alert>
                    }
                </>
                :
                <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                </Box>
            }
        </>
    )
}