import { useEffect, useState } from "react";
import { EnergyFlowChart } from "./EnergyFlowChart";
import { getPowers } from "../../../services/meters";
import { Box, CircularProgress } from "@material-ui/core";
import { Alert } from "@mui/material";
//Helpers
import { helperComponent } from '../../DinamicDashboard/helpers';


function ContainerEnergyFlowChart({ id, title, config_key, ky, content, height, refresh, positions }) {
    const [powerData, setPowerData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    useEffect(() => {
        getPowerData();
        // eslint-disable-next-line
    }, [id, config_key])

    const getPowerData = async () => {
        try {
            let { fields } = returnVariable()
            let idDevice = id
            setLoading(true);
            setShowErrorAlert(false);
            setErrorMessage('');
            let res = await getPowers(idDevice, fields)
            let powers = res.data[0].data;
            if (powers) {
                let powerData = {
                    networkPower: powers.P ? powers.P[0].valor : 0,
                    generatorPower: powers.P_G ? powers.P_G[0].valor : 0,
                    consumptionPower: powers.P_C ? powers.P_C[0].valor : 0,
                    timestamp: powers.P[0].timestamp ?? powers.P_G[0].timestamp ?? powers.P_C[0].timestamp ?? 0
                }
                setPowerData(powerData)
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            if (error?.response?.data?.msg) {
                setErrorMessage(
                    `${error.response.data.msg}.`
                );
            }
            else {
                setErrorMessage(`Ocurrió un error inesperado.`);
            }
            setShowErrorAlert(true);
        }
    }

    //funcion para dar las opciones segun la variable seleccionada.
    const returnVariable = () => {
        let resObj = {
            variable: '',
            fields: '',
        }
        Object.entries(helperComponent('DASHBOARDS_SOLAR_FLOW')).forEach(([clave, valor]) => {
            let kEY_CONFIG = config_key.split('-')[1]
            let KEY_CATALOGUE = valor.value.split('-')[0]
            if (kEY_CONFIG === KEY_CATALOGUE) {
                resObj.variable = valor.value?.split('-')[0]
                resObj.fields = valor.value?.split('-')[1]
            }
        })
        return resObj
    }

    return (
        <Box style={{ height: height }}>
            {loading ? (
                <Box display='flex' justifyContent='center' mt={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {showErrorAlert ?
                        <Alert severity='error'>{errorMessage}</Alert>
                        :
                        <EnergyFlowChart
                            powerData={powerData}
                            title={title}
                            subtitle={'| Ahora'}
                            positions={positions}
                        />
                    }
                </>
            )}
        </Box>
    )

}

export default ContainerEnergyFlowChart