import React from 'react';
import { Alert } from '@material-ui/lab';
// import { makeStyles } from '@material-ui/core';

// const useStyles = makeStyles(theme => ({


// }));

function ErrorAlert({message, severity = 'error'}) {
  // const classes = useStyles();

  return <Alert severity={severity}>{message}</Alert>;
}

export default ErrorAlert;
