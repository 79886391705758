//Material ui
import {
    Box, makeStyles, Tooltip, IconButton,
} from '@material-ui/core';
//icons 
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
//Helpers
import { toFormattedNumber } from '../../../helpers/common'
import { getFormattedNumber, getUnit, returnEmpty } from '../../../helpers/common';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    containerMain: {
        borderRadius: "5px",
        minHeight: "300px",
        height: "100%",
        overflow:'hidden'
    },
    containerHeader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        backgroundColor: theme.palette.primary.light,
        height: "40%",
        color: theme.palette.common.white,
        padding: theme.spacing(1, 1, 1, 1),
        boxShadow: "4px 4px 4px #cbc1c157",
    },
    containerHeaderBoxTop: {
        fontSize: theme.spacing(2),
        fontWeight: 600,
        padding: theme.spacing(0, 0, 1, 1),
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        borderBottom: `solid 1px ${theme.palette.common.white}`,
        "@media (max-height: 900px)": {
            fontSize: theme.spacing(2),
        },
        "@media (max-width: 900px)": {
            height: "50px",
        }
    },
    containerHeaderBoxBottom: {
        padding: "10px 0 5px 0"
    },
    containerBody: {
        height: "60%"
    },
    containerBodyBoxTop: {
        display: "flex",
        height: "54%",
        alignItems: "center",
        // borderBottom: `solid 1px ${"#47a6db"}`,
        "@media (max-height: 900px)": {
            height: "unset",
        }
    },
    containerBodyBoxTopBox: {
        width: "50%",
        padding: theme.spacing(2, 1, 1, 3),
        overflow:'hidden'
    },
    containerBodyBoxBottom: {
        height: "42%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "@media (max-height: 900px)": {
            height: "100px",
        }
    },
    containerBodyBoxBottomBox: {
        padding: theme.spacing(1, 1, 2, 1),
    },
    unity: {
        fontSize: theme.spacing(3.5),
        "@media (max-width: 900px)": {
            fontSize: theme.spacing(2),
        }
    },
    value: {
        fontSize: theme.spacing(5),
        overflow:'hidden',
        textOverflow: 'ellipsis',
        "@media (max-width: 900px)": {
            fontSize: theme.spacing(4),
        }
    },
    textValues: {
        fontSize: theme.spacing(3.5),
        color: '#9d9898',
        "@media (max-height: 900px)": {
            fontSize: theme.spacing(2.5),
        }
    },
    signalSup: {
        "@media (max-width: 900px)": {
            fontSize: "15px"
        }
    },
    tooltipStatus: {
        display: "flex",
        marginRight: "10px",
        height: "22px",
    },
    addresTitle: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginLeft: "18px",
        fontSize: "15px"
    },
    navLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export default function CardNodeData({
    stateData, location, showYearlyConsume = true
}) {
    const classes = useStyles();

    const objData = {
        titleHeader: returnEmpty(location?.nombre),
        address: stateData?.address,
        phoneNumber: stateData?.telephone,
        power_month: toFormattedNumber(stateData?.power_month),
        supTotal: stateData?.sup || stateData?.superficie,
        energyYear: getFormattedNumber(stateData?.energy_year)
    }
    const TOOLTIP_TEXT = {
        adress: 'No se seteó ninguna direccion para esta localización, puede hacerlo en configuracion dispositivos',
        phone: 'No se seteó ningun numero de telefono para esta localización, puede hacerlo en configuracion dispositivos',
        sup: 'No se seteó ninguna superficie para esta localización, puede hacerlo en configuracion dispositivos'
    }
    return (
        <Box className={classes.containerMain}>

            {/* HEADER CARD */}
            <Box className={classes.containerHeader}>

                <Box className={classes.containerHeaderBoxTop}>
                    <Box  >
                        {objData.titleHeader}
                    </Box>
                </Box>

                <Box className={classes.containerHeaderBoxBottom}>
                    <Box
                        display="flex"
                        alignItems="center"
                        mb={1}
                    >
                        <RoomOutlinedIcon style={{ fontSize: "22px" }} />

                        <Box className={classes.addresTitle}>{
                            objData.address
                                ?
                                objData.address
                                :
                                <Tooltip title={TOOLTIP_TEXT.adress} arrow placement='right'>
                                    <NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
                                        <IconButton >
                                            <Box style={{ fontSize: "16px", color: "#fff" }}>
                                                {'- !'}
                                            </Box>
                                        </IconButton>
                                    </NavLink>
                                </Tooltip>
                        }
                        </Box>

                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <MessageOutlinedIcon style={{ fontSize: "19px" }} />
                        <Box style={{ marginLeft: "19px", fontSize: "15px" }}>
                            {
                                objData.phoneNumber
                                    ?
                                    objData.phoneNumber
                                    :
                                    <Tooltip title={TOOLTIP_TEXT.phone} arrow placement='right'>
                                        <NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
                                            <IconButton	>
                                                <Box style={{ fontSize: "16px", color: "#fff" }}>
                                                    {'- !'}
                                                </Box>
                                            </IconButton>
                                        </NavLink>
                                    </Tooltip>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* BODY CARD */}
            <Box className={classes.containerBody}>
                {/* contenedor top de el cuerpo de la carta */}
                <Box className={classes.containerBodyBoxTop}>
                    <Box style={{ borderBottom: `solid 1px ${"#47a6db"}`, borderRight: `solid 1px ${"#47a6db"}` }} className={classes.containerBodyBoxTopBox}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            height="12px"
                            color="#47a6db"
                        >
                            <ElectricBoltOutlinedIcon style={{ fontSize: "21px", }} />
                            <p style={{ marginLeft: "4px" }}>Mensual</p>
                        </Box>

                        <Box className={classes.textValues} >
                            <Box className={classes.value} style={{ fontWeight: "600", marginRight: "5px" }}>{objData.power_month}</Box>
                            <Box className={classes.unity}>{'kW'}</Box>
                        </Box>
                    </Box>

                    <Box style={{ borderBottom: `solid 1px ${"#47a6db"}` }} className={classes.containerBodyBoxTopBox}>
                        <Box
                            display="flex"
                            alignItems="center"
                            height="12px"
                            color="#47a6db"
                        >
                            <OpenInNewOffIcon style={{ fontSize: "21px", }} />
                            <p style={{ marginLeft: "4px" }}>Sup.total</p>
                        </Box>


                        <Box className={classes.textValues}>
                            <Box className={classes.value} style={{ fontWeight: "600", marginRight: "5px" }}>
                                {
                                    objData.supTotal !== 1 ?
                                        objData.supTotal
                                        :
                                        <Tooltip title={TOOLTIP_TEXT.sup} arrow placement='right'>
                                            <NavLink className={classes.navLink} to={`/configuracion-organizacion`}>
                                                <IconButton 	>
                                                    <Box className={classes.signalSup}>
                                                        {'- !'}
                                                    </Box>
                                                </IconButton>
                                            </NavLink>
                                        </Tooltip>
                                }
                            </Box>
                            <Box className={classes.unity}>{'m2'}</Box>
                        </Box>


                    </Box>
                </Box>

                {/* contenedor bottom de el cuerpo de la carta */}
                {showYearlyConsume && <Box className={classes.containerBodyBoxBottom}>
                    <Box className={classes.containerBodyBoxBottomBox}>
                        <Box
                            display="flex"
                            alignItems="center"
                            height="28px"
                            color="#47a6db"
                        >
                            <ElectricBoltOutlinedIcon style={{ fontSize: "20px", }} />
                            <p style={{ marginLeft: "4px" }}>Consumo Anual</p>
                        </Box>


                        <Box className={classes.textValues} display="flex" justifyContent={"center"} alignItems={"center"} >
                            <Box className={classes.value} style={{ fontWeight: "600", marginRight: "5px" }}>{objData.energyYear}</Box>
                            <Box className={classes.unity}>{`${getUnit(stateData?.energy_year)}Wh`}</Box>
                        </Box>
                    </Box>
                </Box>}

            </Box>
        </Box >
    )
}

