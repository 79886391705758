// Material UI
import {
    Box, Button, Grid, makeStyles,
    Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "40px",
        color: theme.palette.primary.main,
    },
    circular: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
    },
    btnSpacing: {
        margin: theme.spacing(0, 1),
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '16px',
    },
    btnDeleteAccount: {
        margin: "20px 40px"
    }
}));

const DeletePanel = ({ openDeletePanel, handleOpenCloseDeletePanel, deleteLocal }) => {
    const classes = useStyles();

    return (
        <Dialog open={openDeletePanel} onClose={handleOpenCloseDeletePanel} aria-labelledby='form-dialog-title' fullWidth>
            <DialogTitle id='form-dialog-title'>Cancelar</DialogTitle>
            <DialogContent>
                <>
                    <Grid className={classes.label}>
                        El proceso de cancelar es irreversible y representará la pérdida total de la configuración previa a ser guardada, incluyendo su diagrama."                    </Grid>
                    <p>Para cancelar su diagrama haga click en "Volver al estado inicial"</p>
                    <Box display='flex' justifyContent='center' mt={1} mb={1}>
                        <Button
                            onClick={() => { deleteLocal(); handleOpenCloseDeletePanel() }}
                            variant='outlined' color='secondary' className={classes.btnSpacing}>
                            Volver al estado inicial
                        </Button>
                    </Box>
                </>
            </DialogContent>
        </Dialog>
    )
}

export default DeletePanel 