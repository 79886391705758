//Fecha
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

// Función encargada de devolver una fecha 7 días posterior a la recibida, como string y con la forma mm/dd/yyyy
export const getNextWeek = str => {
  // Fecha inicial
  const auxDate = new Date(str);
  // Máxima fecha que puede ser elegida
  const endDate = new Date(auxDate.setDate(auxDate.getDate() + 7));
  // La formateamos como MM/DD/YYYY
  const endDateString = [
    (endDate.getMonth() + 1).toString().padStart(2, '0'),
    endDate.getDate().toString().padStart(2, '0'),
    endDate.getFullYear().toString().padStart(2, '0'),
  ].join('/');
  // console.log(endDateString)
  return endDateString;
};

//funcion para devolver x camntidad de dias mas q el dia enviado por parametro, pero si el resultado va a ser mayor al dia actual se envia el actual
export const getNextWeekMaxToday = (str, numberDaysbetweens) => {
  // Fecha inicial
  const auxDate = new Date(str);
  // Máxima fecha que puede ser elegida
  const endDate = new Date(
    auxDate.setDate(auxDate.getDate() + numberDaysbetweens)
  );
  // La formateamos como MM/DD/YYYY
  const endDateString = [
    (endDate.getMonth() + 1).toString().padStart(2, '0'),
    endDate.getDate().toString().padStart(2, '0'),
    endDate.getFullYear().toString().padStart(2, '0'),
  ].join('/');
  // console.log(endDateString)
  if (endDate >= new Date()) {
    return new Date();
  } else {
    return endDateString;
  }
};

// Función encargada de devolver una fecha  x cantidad de días posterior a la recibida, como string y con la forma mm/dd/yyyy
export const getControlDay = (str, numberOfDays) => {
  // Fecha inicial
  const auxDate = new Date(str);
  // Máxima fecha que puede ser elegida
  const endDate = new Date(
    auxDate.setDate(auxDate.getDate() - numberOfDays + 1)
  );
  // La formateamos como MM/DD/YYYY
  const endDateString = [
    (endDate.getMonth() + 1).toString().padStart(2, '0'),
    endDate.getDate().toString().padStart(2, '0'),
    endDate.getFullYear().toString().padStart(2, '0'),
  ].join('/');
  return endDateString;
};

// Función encargada de devolver una fecha 1 día posterior a la recibida, como string y con la forma mm/dd/yyyy
export const getTomorrowDate = str => {
  // Fecha inicial
  const auxDate = new Date(str);
  // Máxima fecha que puede ser elegida
  const endDate = new Date(auxDate.setDate(auxDate.getDate() + 1));
  // La formateamos como MM/DD/YYYY
  const endDateString = [
    (endDate.getMonth() + 1).toString().padStart(2, '0'),
    endDate.getDate().toString().padStart(2, '0'),
    endDate.getFullYear().toString().padStart(2, '0'),
  ].join('/');
  // console.log(endDateString)
  return endDateString;
};

//Función encargada de convertir una fecha YYYY-MM.DD en DD/MM/YYYY
export const formatDateStrDashToSlash = date => {
  //2022-02-01
  const dateArray = date.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];
  // console.log(`${month}/${day}/${year}`);
  return `${day}/${month}/${year}`;
};

// * Función encargada de convertir una fecha YYYY-MM.DD en MM/dd/yyyy
export const formatDateStrDashToSlashReverse = date => {
  //MM/dd/yyyy
  const dateArray = date?.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];
  return `${month}/${day}/${year}`;
};

export const formatDateObjToSlash = dateObj => {
  return `${dateObj.getDate()}/${dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`;
};

// Recibo una fecha del formato 2022-05-01 y lo retorno un objeto Date con el último día de ese mes.
export const getLastDayOfMonthFromStr = str => {
  // Como obtengo el primer día del mes y el endpoint de eventos me devolvería solo los eventos de ese día, quiero el mes completo.
  const dateObj = new Date(str);
  const userTimezoneOffset = dateObj.getTimezoneOffset() * 60000;
  const newDate = new Date(dateObj.getTime() - userTimezoneOffset);
  return new Date(newDate.getFullYear(), newDate.getMonth() + 2, 0);
};

export const truncateWithEllipsis = (s, maxLength = 15) => {
  if (s === null) {
    return '';
  }
  if (s.length > maxLength) {
    return s.substring(0, maxLength) + '...';
  }
  return s;
};

// Función encargada de convertir un string a un número con el formato esperado.
// Usar "," como separadores de miles.
// Usar "." como separador decimal.
// Usar 2 posiciones decimales.
export const toFormattedNumber = (str, unit = '') => {
  if (typeof str === 'object') return '-';

  if (str === 0) {
    return 0
  }
  else {
    if (!str) return '-';
    const usNumber = Number(str).toLocaleString('en-US', {
      maximumFractionDigits: 2,
    });

    if (unit === '$') return `${unit} ${usNumber}`;
    return `${usNumber} ${unit}`;
  }
};


export const toFormattedNumberNoUnit = (str) => {
  if (str === 0) {
    return 0
  }
  else {
    if (!str) return '-';

    // Verificar si la cadena contiene un punto decimal
    if (str.includes('.')) {
      // Dividir el número en parte entera y parte decimal
      var partes = str.split(".");
      var parteEntera = partes[0];
      var parteDecimal = partes[1] || "";
      // Agregar comas para separar la parte entera en grupos de tres dígitos
      var parteEnteraFormateada = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Combinar la parte entera formateada con la parte decimal
      var numeroFormateado = parteEnteraFormateada + "." + parteDecimal;
      return numeroFormateado;
    }
    else {
      // Si no hay punto decimal, simplemente agregar comas de mil
      return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};

// Función encargada de mezclar colores.
// Conservo los primeros tres dígitos de un color, los últimos tres del otro y los invierto.
// generateColor('#123456', '#789ABC') // '#ABC123'
export const mixColors = (color1, color2) => {
  if (!color2) {
    return color1;
  }

  return '#' + color2.slice(4) + color1.slice(1, 4);
};

//Funcion para formatear el numero con respecto a la cantidad de unidades q tenga,
// esta funcion es combinada con getUnit para obtener la unidad para dicho numero.
export const getFormattedNumber = x => {
  // Entrega el multiplicador para convertir
  // 1 kWh = 0.001 MWh
  // 1 kWh = 0.000001 GWh
  // obtener valor absoluto para casos <0
  const abs = Math.abs(x);
  const magnitude = Math.log(abs) / Math.log(10);
  if (magnitude < 3) return toFormattedNumber(1 * x);
  if (magnitude < 6) return toFormattedNumber(0.001 * x);
  if (magnitude < 9) return toFormattedNumber(0.000001 * x);
  return toFormattedNumber(1 * x);
};

//Funcion encargada de formatear el la unidad 'kwh' segun cantidad de unidades ej: 3000'kw'  3'Mw'.
export const getUnit = x => {
  if (!x) {
    return ''
  }
  // obtener valor absoluto para casos <0
  const abs = Math.abs(x);
  const magnitude = Math.log(abs) / Math.log(10);
  if (magnitude < 0) return '';
  if (magnitude < 3) return 'k';
  if (magnitude < 6) return 'M';
  if (magnitude < 9) return 'G';
  return '';
};

//funcion para optimizar condicion si existe valor devolver el mismo valor si no existe devuelve "-"
export const returnEmpty = value => {
  if (value) return value;
  return '-';
};

//function q transfomra la primera letra en mayuscula
export const capitalizeFirstLetter = str => {
  if (!str) return str; // Manejo de casos en los que la cadena sea null, undefined o vacía
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  // if (capitalized.length > 14) {
  //   return capitalized.substring(0, 19);
  // }
  return capitalized;
};

//funcion q devuelve en forma de string el nombre de el mes formato  "marzo 2023"    o el año  "este año 2023" //funcion para optimizar condicion si existe valor devolver el mismo valor si no existe devuelve "-"
export const returnDateString = option => {
  if (option === 'm') {
    return `${format(new Date(), 'MMMM yyyy', { locale: es })}`;
  }
  if (option === 'y') {
    return `este año ${format(new Date(), 'yyyy', { locale: es })}`;
  }
  if (option === 'h') {
    return `Historico`;
  }
};

//retorna html linea azul q divide bloques de franjas.
export const blueBar = () => {
  return (
    <div
      style={{
        backgroundColor: '#2586bc69',
        height: '1px ',
        width: ' 100 %',
        margin: '10px 0 10px 0',
      }}
    ></div>
  );
};

export const isValidEmail = str => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(str);
};
