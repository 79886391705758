import { useEffect, useState } from "react";
// @material-ui/core
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AccountTreeIcon from "@material-ui/icons/AccountTree";
// Services
import { getNodesTree } from "../../services/hierarchy";
// Components
import Node from "./Node";

const scrollsSettings = {
  overflow: 'scroll',
  overflowX: 'hidden',
};

const webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  width: '9px',
};

const webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles((theme) => ({
  container:{
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  circular: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px"
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogSubtitle: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      display: 'none',      
    },
  },
  selectedItemsContainer: {
    height: '35px',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  devicesBtn: {
    margin: theme.spacing(3, 1),
  },
  buttonsContainer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function DevicesList({ open, handleClose, handleSubmit, fromIntegrations = false, statusNodes }) {
  const classes = useStyles();
  const [nodes, setNodes] = useState(null);
  const [loadingNodes, setLoadingNodes] = useState(false);
  const [selectedNode, setSelectedNode] = useState({});

  const handleDeleteNode = () => {
    setSelectedNode({})
  };

  useEffect(() => {
    const fetchNodesTreeData = async () => {
      try {
        setLoadingNodes(true);
        const res = await getNodesTree();
        setNodes(res?.data);
        setLoadingNodes(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchNodesTreeData();
  }, [])

  const handleSelectedNode = node => {
    const childrenList = []
    const generateChildList = (myTree) => {
      for (let leaf of myTree.hijos) {
        childrenList.push(leaf.id)

        if (leaf.hijos.length > 0) {
          generateChildList(leaf)
        }
      }
    }
    childrenList.push(node.nombre)
    generateChildList(node)
    // Si el nodo elegido es el mismo que el actualmente seleccionado lo deselecciono
    if (node === selectedNode) setSelectedNode({});
    else setSelectedNode({ nombre: node.nombre, id: node.id, childrenList });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableScrollLock
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Selección de Dispositivos
      </DialogTitle>
      {!fromIntegrations && <div className={classes.dialogSubtitle}>
        Seleccionar un elemento de la organización y de manera opcional una
        etiqueta.
      </div>}
      <DialogContent className={classes.container}>
        <Box display="flex" className={classes.selectedItemsContainer}>
          {selectedNode.nombre ? (
            <Chip
              icon={<AccountTreeIcon />}
              label={selectedNode.nombre}
              clickable
              color="primary"
              onDelete={handleDeleteNode}
            />
          ) : null}
        </Box>

        <Grid container display="block">
          <Grid item xs={12}>
            <Box component="label" display="block" className={classes.label}>
              Organización
            </Box>

            {loadingNodes &&
              <div className={classes.circular}>
                <CircularProgress />
              </div>
            }

            {!loadingNodes && nodes &&
              <List
                component="div"
                disablePadding
                dense
                className={classes.scrollableContainer}
              >
                {nodes.map(node => (
                  <Node statusNodes={statusNodes} node={node} handleSelectedNode={handleSelectedNode} selectedNode={selectedNode} key={node.id} />
                ))}
              </List>
            }
          </Grid>

        </Grid>
        
      </DialogContent>
      <Box className={classes.buttonsContainer} display="flex" justifyContent="center">
          <Button
            className={classes.devicesBtn}
            variant="contained"
            color="primary"
            onClick={() => { setSelectedNode({}); handleSubmit(selectedNode) }}
            disabled={selectedNode.nombre ? false : true}
          >
            Guardar
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            className={classes.devicesBtn}
          >
            Cancelar
          </Button>
        </Box>
    </Dialog>
  );
}
