

import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import {
    Box, IconButton
} from '@material-ui/core';
//Component
import ComponentChildren from './ComponentChildren';
//Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles(theme => ({
    labelInputSelectVar: {
        fontSize: '16px',
        marginBottom: '3px'
    },
    containerLabelIconOpen: {
        borderBottom: `0.2px solid ${'#00000082'}`,
        cursor: 'pointer'
    },
    containerGroups: {
        backgroundColor: 'white',
        position: 'absolute',
        zIndex: 2,
        borderRadius: '2px',
        boxShadow: '10px 8px 25px -2px #02020240',
        width: '50%',
        '@media (max-width: 960px)': {
            width: '80%',
        },
    },
    iconButtonArrowDrow: {
        '&.MuiIconButton-root': {
            paddingRight: 0,
            paddingBottom: 0
        }
    }
}));
let disabledElement = {
    pointerEvents: 'none',
    opacity: 0.5,
    color: '#ccc',
}

const DynamicSelect = ({ section, InputLabelProp, handleChangeProp, keyName, disabledSelect, disabledVarianteInstant, valueSelect, disabledVarianteHistoric }) => {
    const classes = useStyles();
    const [groupList, setGroupList] = useState([])
    const [openSelect, setOpenSelect] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const componentRef = useRef(null);
    const [groupTitleKey, setGroupTitleKey] = useState('ENERGIA_ELECTRICA');

    //Verifica si el user clickeo fuera de el componente.
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const groupList = getCatalogo('groups_vars', section);
        const varsList = getCatalogo('sections', section);
        const updatedGroupList = groupList.map(group => {
            const children = varsList.filter(variable => variable.group.key === group.key);
            return {
                ...group,
                children: group.children ? [...group.children, ...children] : children
            };
        });
        setGroupList(updatedGroupList);
    }, [section]);

    useEffect(() => {
        if (valueSelect) {
            const varsList = getCatalogo('sections', section);
            const groupList = getCatalogo('groups_vars', section);
            let varSelected = varsList.find(e => e.key === valueSelect)
            setSelectedValue([varSelected.name])
            let findTtitleGroup = groupList?.find((e) => e.key === varSelected?.group?.key)
            setGroupTitleKey(findTtitleGroup.key)
        }
    }, [valueSelect, section])

    const handleOpen = () => {
        setOpenSelect(true);
    };

    const handleClose = (element) => {
        setOpenSelect(false);
        if (element?.key) {
            let e = {
                target: {
                    name: keyName,
                    value: element.key
                }
            }
            handleChangeProp(e)
            handleChange(element.name)
        }
    };

    const handleChange = (name) => {
        setSelectedValue([name]);
    };

    // Obtiene el catalogo
    const getCatalogo = (option, section) => {
        let nameVarLocal = 'UI_ROLES'
        let VARS_CATALOGO = JSON.parse(localStorage.getItem(nameVarLocal))?.[option][section]
        return VARS_CATALOGO
    };

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            handleClose()
        }
    };

    return (
        <Box
            display="flex"
            width='100%'
        >
            {/* ICON OPEN CLOSE */}
            {openSelect ||
                <Box
                    className={classes.containerLabelIconOpen}
                    onClick={handleOpen}
                    style={disabledSelect ? disabledElement : {}}
                    width={'90%'} display='flex' justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Box
                        className={classes.labelInputSelectVar}
                    >
                        {disabledSelect ? '' : selectedValue}
                    </Box>
                    <IconButton
                        className={classes.iconButtonArrowDrow}
                        onClick={handleOpen}>
                        <ArrowDropDownIcon />
                    </IconButton>
                </Box>
            }
            {/* INPUT SELECTOR DISEÑADO A MANO */}
            {
                openSelect &&
                <Box
                    ref={componentRef}
                    className={classes.containerGroups}
                    width={'100%'}>
                    {groupList.map((element) => {
                        return (
                            <Box key={element.key}>
                                <ComponentChildren
                                    selectedValue={selectedValue}
                                    groupTitleKey={groupTitleKey}
                                    setGroupTitleKey={setGroupTitleKey}
                                    disabledVarianteInstant={disabledVarianteInstant}
                                    disabledVarianteHistoric={disabledVarianteHistoric}
                                    handleClose={handleClose}
                                    element={element}
                                />
                            </Box>

                        )
                    })}
                </Box>
            }
        </Box >
    )
}
export default DynamicSelect

