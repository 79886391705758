import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import {
  Box, Button, Chip, CircularProgress, Dialog, DialogContent,
  Grid, IconButton, makeStyles, Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TuneIcon from '@material-ui/icons/Tune';
import Container from '../Elements/Container';
import TopNav from '../common/TopNav';
import ChartV2 from './ChartV2';
import Filter from './Filter';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import { getHistoryV2 } from '../../services/meters';
import {
  CALENDAR_INITIAL_CONFIG,
  FILTER_INITIAL_VALUES,
} from './helpers';

const useStyles = makeStyles(theme => ({
  btn: {
    margin: theme.spacing(2, 1),
    '& .MuiButton-label': {
      textTransform: 'none',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  dateContainer: {
    color: theme.palette.common.white,
    fontSize: 16,
    '& .MuiButtonBase-root': {
      color: theme.palette.common.white,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.common.white,
      width: 125,
      '@media (max-width: 420px)': {
        display: 'none',
      },
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  containerChips: {
    marginLeft: '55px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media (max-width: 920px)': {
      flexDirection: 'column',
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tuneIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5em',
      paddingTop: theme.spacing(0.5),
    },
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  nextPeriod: {
    '&.Mui-disabled': {
      opacity: '0.5',
    },
  },
  btnRefresh: {
    '&.MuiButton-root': {
      height: '30px',

      marginTop: '16px',
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiButton-label': {
      textTransform: 'none',
      paddingLeft: '5px',
      // fontSize: 11,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '@media (max-width: 700px)': {
      marginBottom: '10px',
      marginRight: '10px',
    },
  },
  dialogStyle: {
    '& .MuiDialog-paperScrollPaper': {
      height: '100%'
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 900px)': {
        padding: '2px'
      },
    },
    '& .MuiDialog-paper': {
      '@media (max-width: 600px)': {
        margin: '0px'
      },
    },
    '& .MuiDialog-paperFullWidth': {
      '@media (max-width: 600px)': {
        width: '100%'
      },
    },
  }
}));

export default function Historical() {
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = useState(FILTER_INITIAL_VALUES);
  const [calendarConfig, setcalendarConfig] = useState(CALENDAR_INITIAL_CONFIG);
  const [filterPanel, setFilterPanel] = useState(true);
  const [historicalData, setHistoricalData] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchHistory = async url => {
    try {
      setLoading(true);
      const res = await getHistoryV2(url);
      // console.log(res.data);
      setLoading(false);
      if (res.data.error) {
        setErrorMessage(res.data.error);
        setShowErrorAlert(true);
      } else {
        setHistoricalData(res.data);
        setShowErrorAlert(false);
      }
    }
    catch (error) {
      // console.error(error);
      setLoading(false);
      setHistoricalData([]);
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage(
            `No se encontraron datos para el período seleccionado.`
          );
        } else {
          setErrorMessage(
            `${error.response.data.msg}.`
          );
        }
      } else {
        setErrorMessage(`Ocurrió un error inesperado`);
      }
      setShowErrorAlert(true);
    }
  };

  function decrementMonths() {
    const result = new Date(filterOptions.selectedDate);
    result.setMonth(result.getMonth() - 1);
    setHistoricalData([]);
    setFilterOptions({ ...filterOptions, selectedDate: result });
    handleSubmit({ ...filterOptions, selectedDate: result })
  }
  function incrementMonths() {
    const result = new Date(filterOptions.selectedDate);
    result.setMonth(result.getMonth() + 1);
    setHistoricalData([]);
    setFilterOptions({ ...filterOptions, selectedDate: result });
    handleSubmit({ ...filterOptions, selectedDate: result })
  }
  function decrementYears() {
    const result = new Date(filterOptions.selectedDate);
    result.setFullYear(result.getFullYear() - 1);
    setHistoricalData([]);
    setFilterOptions({ ...filterOptions, selectedDate: result });
    handleSubmit({ ...filterOptions, selectedDate: result })
  }
  function incrementYears() {
    const result = new Date(filterOptions.selectedDate);
    result.setFullYear(result.getFullYear() + 1);
    setHistoricalData([]);
    setFilterOptions({ ...filterOptions, selectedDate: result });
    handleSubmit({ ...filterOptions, selectedDate: result })
  }

  const handleFilterClick = () => {
    setFilterPanel(!filterPanel);
  };

  const handleCloseFilter = () => {
    setFilterPanel(false)
  }

  const handleCloseDialog = () => {
    setFilterPanel(false);
  };

  const handleSubmit = filter => {
    //Si tengo dispositivos seleccionados
    if (filter?.variableFields) {
      let url = '';
      const year = filter.selectedDate.getFullYear();
      const month = filter.selectedDate.getMonth() + 1;

      filter?.dispositivos.forEach((set, index) => {
        if (index > 0) {
          url += '&';
        }
        url += `filter=${set.node.id}`;
        if (set.label.nombre) {
          url += `+`;
          url += `${set.label.id}`;
        }
      });

      url += '&fields=';
      url += filter.variableFields || '';
      if (filter.granularity === 'DAILY') {
        url += `&year=${year}&month=${month}`;
      }
      if (filter.granularity === 'MONTHLY') {
        url += `&year=${year}`;
      }
      url += `&period=${filter.granularity}`;
      url += filter.showPhase ? `&showPhase=true` : '';
      url += `&response_format=v2`;

      fetchHistory(url);
      //Cierro el panel de Filtros
      setFilterOptions(filter)
      configCalendar(filter);
      setFilterPanel(false);
    }
  };

  const configCalendar = filter => {
    const config = {};
    switch (filter.granularity) {
      case 'DAILY':
        config.views = ['month', 'year'];
        config.disabled = false;
        config.format = 'MM/yyyy';
        //setSelectedDate(new Date());
        break;
      case 'MONTHLY':
        config.views = ['year'];
        config.disabled = false;
        config.format = 'yyyy';
        //setSelectedDate(new Date());
        break;
      case 'YEARLY':
        config.disabled = true;
        config.format = 'yy';
        //Como voy a mostrar todos los anuales muestro la fecha de hoy
        //setSelectedDate(new Date());
        break;
      default:
        return;
    }
    setcalendarConfig(config);
  };

  const isFuture = date => {

    const today = new Date();
    const currentMonth = today.getMonth() + 1; // Obtener el mes actual (1-12)
    const currentYear = today.getFullYear(); // Obtener el año

    if (filterOptions.granularity === "DAILY") {
      // Chequear si date forma parte del año y mes actual
      return (
        date.getMonth() + 1 === currentMonth && date.getFullYear() === currentYear
      );
    }
    if (filterOptions.granularity === "MONTHLY") {
      // Chequear si date forma parte del año actual
      return date.getFullYear() === currentYear;
    }
    // En cualquier otro caso retornar false
    return false;
  }

  return (
    <>
      <TopNav titulo="Análisis" subtitulo="| Históricos">
        <Grid container justifyContent="flex-end">
          {filterOptions.granularity !== 'YEARLY' ? (
            <Box
              className={classes.dateContainer}
              display="flex"
              justifyContent="center"
            >
              <IconButton
                aria-label="Período anterior"
                // Si la granularidad es 'DAILY' decremento meses, si es 'MONTHLY' decremento años (si es 'YEARLY' no se verá este componente)
                onClick={
                  filterOptions.granularity === 'DAILY'
                    ? decrementMonths
                    : decrementYears
                }
                disabled={loading}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={calendarConfig.disabled}
                  autoOk
                  views={calendarConfig.views}
                  disableToolbar
                  variant="inline"
                  format={calendarConfig.format}
                  margin="normal"
                  // id='date-picker-inline'
                  // label='Date picker inline'
                  value={filterOptions.selectedDate}
                  onChange={date =>
                    setFilterOptions({ ...filterOptions, selectedDate: date })
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'Establecer fecha',
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                aria-label="Período siguiente"
                // Si la granularidad es 'DAILY' incremento meses, si es 'MONTHLY' incremento años (si es año no se verá este componente)
                onClick={
                  filterOptions.granularity === 'DAILY'
                    ? incrementMonths
                    : incrementYears
                }
                className={classes.nextPeriod}
                disabled={isFuture(filterOptions.selectedDate) || loading}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          ) : (
            <Box
              className={classes.dateContainer}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <p>Acumulado Histórico</p>
            </Box>
          )}

          <Button
            className={classes.btn}
            type="button"
            onClick={handleFilterClick}
            size="small"
            startIcon={filterPanel ? <ClearIcon /> : <TuneIcon />}
          >
            | Filtrar
          </Button>

          {/* Boton refresh */}
          <Tooltip
            title="Última consulta."
            arrow
          >
            <Button
              className={classes.btnRefresh}
              type="button"
              onClick={() => handleSubmit(filterOptions)}
              // size='small'
              startIcon={<FlipCameraAndroidIcon />}
            ></Button>
          </Tooltip>

        </Grid>
      </TopNav>

      <Dialog
        className={classes.dialogStyle}
        open={filterPanel}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        disableScrollLock
      >
        <DialogContent>
          <Filter
            onSubmit={handleSubmit}
            savedOptions={filterOptions}
            handleCloseFilter={handleCloseFilter}
          />
        </DialogContent>
      </Dialog>
      <Container>
        {showErrorAlert && <Alert severity="error">{errorMessage}</Alert>}

        {filterOptions?.dispositivos?.length > 0 && !showErrorAlert ? (
          <Box className={classes.containerChips}>
            <p>Dispositivos a graficar:</p>

            {filterOptions?.dispositivos?.map(device => (

              <Chip
                className={classes.chip}
                key={device.id}
                icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                label={`${device.node.nombre}  ${device.label.nombre ? `+ ${device.label.nombre}` : ''
                  }`}
                clickable
                color="primary"
                onClick={handleFilterClick}
              />
            ))}
            <Chip
              className={classes.chip}
              label={
                <Box component="span">
                  <strong>Variable:</strong>{' '}
                  {filterOptions.variableName}
                </Box>
              }
              color="secondary"
              variant="outlined"
              clickable
              onClick={() => setFilterPanel(true)}
            />
          </Box>
        ) : (
          <Alert className={classes.alert} severity="info">
            Bienvenido a Históricos. En esta sección podrá consultar la
            información de consumos acumulados de energía de sus dispositivos.
            Para comenzar haga click en el botón
            <span className={classes.tuneIcon}>
              <TuneIcon />
            </span>
            , ingrese los parámetros y haga click en CONSULTAR.
          </Alert>
        )}

        {
          loading &&
          <div className={classes.circular}>
            <CircularProgress />
          </div>
        }
        {
          !loading && historicalData.length > 0 &&
          <ChartV2
            chartData={historicalData}
            chartOptions={filterOptions}
            height={'70vh'}
            showLegend={true}
          />
        }

      </Container>
    </>
  );
}
