
import { useState, useEffect } from 'react';
import {
    Box, Stepper, StepLabel, Step, Tooltip, makeStyles, TextField,
    FormControl, Select, MenuItem, FormControlLabel, Switch, FormGroup
} from '@material-ui/core';
//Style
import clsx from 'clsx';
//Icons
import DoneIcon from '@material-ui/icons/Done';
//Helpers.
import { blueBar } from '../../../helpers/common'
import { getCatalogo } from '../helpers';
const useStyles = makeStyles(theme => ({
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3.1),
        width: theme.spacing(3.1),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
}));

const AlertFilterOptions = ({ alertState, setAlertState, stateDiagram, setDisabledButtonSave }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [frequencies, setFrequencies] = useState([]);
    const [notificationType, setNotificationType] = useState([]);
    //Steeps options.
    const steps = [
        'Elija un dispositivo',
        'Elija una frecuencia',
        'Active o desactive notificaciones'
    ]

    useEffect(() => {
        let res = getCatalogo('ALERTS_VARIABLES', 'generic_data')
        setNotificationType(res?.noti_choices)
        setFrequencies(res?.alerts_frequencies ? res.alerts_frequencies : [])
        //Cargo el estado local de el formulario si ya existe.
        setAlertState(stateDiagram.alertObject.alert)
        let alertAux = stateDiagram.alertObject.alert
        if (alertAux?.frequency?.length > 0) {
            setActiveStep(3)
        }

        // eslint-disable-next-line
    }, [])

    //Handle q toma el estado configuracion Alerta.
    const handleChangeAlertSettings = (e) => {
        let value = e.target.value
        let name = e.target.name
        let type = e.target.type
        setDisabledButtonSave(false)
        setAlertState({
            ...alertState,
            [name]: type === 'checkbox' ? e.target.checked : value
        })

        if (name === 'name') {
            setActiveStep(1)
        }
        if (name === 'frequency') {
            setActiveStep(2)
        }
        if (name === 'notify') {
            setActiveStep(3)
        }
        if (name === 'type_notify') {
            setActiveStep(4)
        }

    }

    return (
        <Box>
            {/* TITULO */}
            <Box m={1} >
                <h3 style={{ fontSize: '18px' }}>  Configuración de Alerta.</h3>
            </Box >

            {/* STEPPERS */}
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box width={'90%'}>
                <Box m={1} >
                    {/* STEP 1 */}
                    <Box display='flex' alignItems='flex-start'  >

                        <Tooltip
                            title='Elija el nombre de la alarma.'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 0 })} >
                                {1}
                            </Box>
                        </Tooltip>

                        <Box width={'90%'}>
                            <Box className={classes.devicesLabel}>
                                Selecciona el nombre de su alarma.
                            </Box>
                            <TextField
                                style={{ width: '90%' }}
                                autoFocus
                                type='text'
                                name='name'
                                value={alertState?.name ? alertState?.name : ''}
                                onChange={handleChangeAlertSettings}
                                color='primary'
                            />
                        </Box>
                    </Box>

                    {blueLine}

                    {/* STEP 2 */}
                    {/* Selector de Componente */}
                    <Box display='flex' alignItems='flex-start' flexWrap='wrap' width={'90%'}>

                        <Tooltip
                            title='Elija la frecuencia.'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 2 })}  >
                                {activeStep >= 2 ? <DoneIcon style={{ padding: '2px' }} /> : 2}
                            </Box>
                        </Tooltip>

                        <Box width={'90%'}>
                            <Box className={classes.devicesLabel}>
                                Seleccione una frecuencia.
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    name='frequency'
                                    value={alertState?.frequency ? alertState?.frequency : ''}
                                    onChange={handleChangeAlertSettings}
                                // disabled={activeStep < 2}
                                >
                                    {frequencies.map((elem, indx) => (
                                        <MenuItem
                                            key={indx}
                                            value={elem.key}
                                        >
                                            {elem.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    {blueLine}

                    {/* STEP 3 */}
                    <Box display='flex' alignItems='flex-start' >

                        <Tooltip
                            title='Elija la frecuencia.'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 3 })}  >
                                {activeStep >= 3 ? <DoneIcon style={{ padding: '2px' }} /> : 3}
                            </Box>
                        </Tooltip>

                        <Box display='flex'>
                            <Box className={classes.devicesLabel}>
                                notificaciones.
                            </Box>
                            <Box ml={6} >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='notify'
                                                // disabled={(general ? false : !disabledOption[elem.name]) || (elem.name === 'telegram_notification')}
                                                checked={alertState?.notify ? alertState?.notify : false}
                                                onChange={handleChangeAlertSettings}
                                                color='primary' />
                                        }
                                    />
                                </FormGroup>
                            </Box>
                        </Box>
                    </Box >


                    {/* STEP 4 */}
                    {/* Selector de Componente */}
                    <Box display='flex' alignItems='flex-start' flexWrap='wrap' width={'90%'}>

                        <Tooltip
                            title='Elija la frecuencia.'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 4 })}  >
                                {activeStep >= 4 ? <DoneIcon style={{ padding: '2px' }} /> : 4}
                            </Box>
                        </Tooltip>

                        <Box width={'90%'}>
                            <Box className={classes.devicesLabel}>
                                Tipo de notificacion.
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    name='type_notify'
                                    value={alertState?.type_notify ? alertState?.type_notify : ''}
                                    onChange={handleChangeAlertSettings}
                                >
                                    {notificationType?.map((elem, indx) => (
                                        <MenuItem
                                            key={indx}
                                            value={elem.key}
                                        >
                                            {elem.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box >
            </Box>
        </Box >
    )
}
export default AlertFilterOptions