//React
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//Material ui
import { IconButton } from '@mui/material';
import {
    Box, Grid, makeStyles,
    InputAdornment, TextField
} from '@material-ui/core';
// Custom hook
import useDeviceDetect from "../../hooks/useDeviceDetect";
//Styles
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        "@media (max-width: 1450px)": {
            justifyContent: 'initial',
        },
    },
    timeSelector: {
        width: '100%',
    },
    dividerBar: {
        height: "1px",
        backgroundColor: "#84787845",
        marginTop: "10px"
    },
    inputActive: {
        backgroundColor: "#00800026",
        borderRadius: "5px",
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    },
}))

export default function InputsBox({
    potencia, data, setData,
    element, idx, deleteElement,
    disabled, handlePower, powerContract, valuePower,
    option, selectedNode
}) {
    const { name, time_from, time_to, hired_demand_cost, peak_demand_cost, energy_cost, hired_demand } = element
    const classes = useStyles();
    const { isMobile } = useDeviceDetect(1450);

    const handleChange = (e) => {
        setData(
            data.map((element, index) => {

                if (index === idx) {
                    return {
                        ...element,
                        [e.target.name]: e.target.value
                    }
                }
                else {
                    return element
                }
            })
        )
    }

    const validateTruePrivate = () => {
        if (potencia && !disabled) {
            return true
        }
        else {
            return false
        }
    }

    const disabledInputFunction = () => {
        let validOption = option === "Default" && selectedNode?.level_nombre === "sublocalización"
        if (validOption || disabled) {
            return true
        }
        else {
            return false
        }
    }

    const backgroundGreen = (disabled) => {
        if (disabled === false) {
            return classes.inputActive
        }
        else {
            return ""
        }
    }

    return (
        <Box >
            <Grid container spacing={1} className={classes.rowContainer}  >
                {/* NOMBRE DE LA FRANJA */}
                <Grid item xs={isMobile ? 6 : true} >
                    <TextField
                        fullWidth
                        placeholder=''
                        name='name'
                        label="nombre"
                        value={name}
                        onChange={e => handleChange(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size='small'
                        color='primary'
                        disabled={disabledInputFunction()}
                        className={clsx(classes.input, backgroundGreen(disabledInputFunction()))}
                        variant='outlined'
                    />
                </Grid>

                {/* DESDE */}
                <Grid item xs={isMobile ? 6 : 2}>
                    <TextField
                        name="time_from"
                        type='time'
                        label='Desde:'
                        size='small'
                        variant='outlined'
                        value={time_from}
                        disabled={disabledInputFunction()}
                        onChange={e => handleChange(e)}
                        // disabled={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 900, // 15 min
                        }}
                        className={clsx(classes.timeSelector, backgroundGreen(disabledInputFunction()))}
                    />
                </Grid>

                {/* HASTA */}
                <Grid item xs={isMobile ? 6 : 2}>
                    <TextField
                        name="time_to"
                        type='time'
                        label='Hasta:'
                        size='small'
                        variant='outlined'
                        value={time_to}
                        disabled={disabledInputFunction()}
                        onChange={e => handleChange(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 900, // 15 min
                        }}
                        className={clsx(classes.timeSelector, backgroundGreen(disabledInputFunction()))}
                    />
                </Grid>

                {/* POTENCIA CONTRATADA */}
                <Grid item xs={isMobile ? 6 : true}>
                    <TextField
                        fullWidth
                        type="number"
                        name={potencia ? 'hired_demand_cost' : 'energy_cost'}
                        label={potencia ? 'Cost Contratad' : 'Cost Energia'}
                        size='small'
                        variant='outlined'
                        value={potencia ? hired_demand_cost : energy_cost}
                        disabled={disabledInputFunction()}

                        onChange={e => handleChange(e)}
                        color='primary'
                        InputLabelProps={{
                            shrink: true,
                        }}

                        InputProps={{
                            endAdornment: <InputAdornment position='end'>{potencia ? "$" : '$'}</InputAdornment>,
                        }}
                        className={clsx(classes.input, backgroundGreen(disabledInputFunction()))}
                    />
                </Grid>

                {/* COSTO DE POTENCIA */}
                {
                    potencia &&

                    <Grid item xs={isMobile ? 6 : true}>
                        <TextField
                            fullWidth
                            type='number'
                            label='Cost Adquirida'
                            name='peak_demand_cost'
                            size='small'
                            variant='outlined'
                            color='primary'
                            value={peak_demand_cost}
                            disabled={disabledInputFunction()}
                            onChange={e => handleChange(e)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>$</InputAdornment>,
                            }}
                            className={clsx(classes.input, backgroundGreen(disabledInputFunction()))}
                        />
                    </Grid>
                }
                {
                    disabledInputFunction() ||

                    <IconButton
                        color="primary"
                        aria-label="delete"
                        component="label"
                        onClick={() => deleteElement(idx, potencia)}
                    >
                        <DeleteForeverIcon />

                    </IconButton>

                }
            </Grid>

            {/* POTENCIA CONTRATADA PRIVADA*/}
            {
                validateTruePrivate() &&
                <Box mb={2} >

                    <Grid item xs={isMobile ? 6 : 3}>
                        <TextField
                            type="number"
                            disabled={option ? disabledInputFunction() : false}
                            // className={classes.inputActive}
                            fullWidth
                            label='Pot Contratada'
                            name='hired_demand'
                            size='small'
                            variant='outlined'
                            color='primary'
                            value={hired_demand}
                            onChange={e => handleChange(e)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
                            }}
                            className={clsx(classes.input, backgroundGreen(option ? disabledInputFunction() : false))}
                        />
                    </Grid>

                    <Box className={classes.dividerBar} ></Box>

                </Box>
            }

            {/* POTENCIA CONTRATADA PUBLICA*/}
            {

                disabled && potencia &&

                <Box mb={2} >

                    <Grid item xs={isMobile ? 6 : 3}>
                        <TextField
                            fullWidth
                            label='Pot Contratada'
                            name='hired_demand'
                            size='small'
                            variant='outlined'
                            color='primary'
                            value={valuePower(element.name, powerContract)}
                            onChange={e => handlePower(e, idx, element.name)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
                            }}
                            className={clsx(classes.input, classes.inputActive)}
                        />
                    </Grid>

                    <Box className={classes.dividerBar} ></Box>

                </Box>
            }
        </Box>
    );
}





