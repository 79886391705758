import React, { useEffect, useState } from 'react';
//@material-ui/core
import {
  Box, Button, Chip, CircularProgress,
  Grid, makeStyles, Snackbar, TextField, Tooltip
} from '@material-ui/core';
//@material-ui/lab
import { Alert } from '@material-ui/lab';
// @material-ui/icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import TuneIcon from '@material-ui/icons/Tune';
// services
import { addSubaccount, deleteSubaccount, editSubaccount, getSubaccountEmails, getSubaccounts } from '../../services/client';
//components
import ErrorAlert from '../common/ErrorAlert';
import DevicesList from './DevicesList';
import StatusChip from './StatusChip';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(8),
    "@media (max-width: 700px)": {
      marginLeft: 0
    },
  },
  row: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    background: 'white',
    flexWrap: 'wrap',
    "& .MuiGrid-item": {
      textAlign: 'center'
    },
    "@media (max-width: 700px)": {
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      flexDirection: 'column',
      "&>*:nth-child(2)": {
        order: -1,
      },
    },
  },
  nodeList: {
    listStyleType: 'none',
    textAlign: 'center',
    padding: 0,
    margin: 0,
    "& li": {
      display: 'inline',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontWeight: 'bold',
      "@media (max-width: 700px)": {
        display: 'block'
      }
    }
  },
  btns: {
    marginLeft: 'auto',
    display: 'flex',
    "@media (max-width: 700px)": {
      marginLeft: 'inherit'
    }
  },
  filterChip: {
    margin: theme.spacing(0.5),
    "@media (max-width: 700px)": {
      width: "200px",
    }
  },
}));

function SubaccountsCreation({ plan }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [subaccounts, setSubaccounts] = useState([]);
  const [emailList, setEmailList] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [hierarchies, setHierarchies] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [subaccountId, setSubaccountId] = useState('');
  const [email, setEmail] = useState("")
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success"
  })

  useEffect(() => {
    const fetchSubaccounts = async () => {
      try {
        setLoading(true);
        // console.log('fetchSubaccounts');
        const res = await getSubaccounts();

        // console.log('res.data', res.data)

        const aux = res.data.map(subaccount => {
          return {
            id: subaccount.id,
            status: subaccount.status,
            hierarchiesArr: subaccount.hierarchy.map(h => ({ id: h.id, nombre: h.nombre, hijos: [h.nombre, ...h.hijos.map(n => n.nombre)] })),
            subaccountEmail: subaccount.subaccount,
          }
        }
        )

        // TODO Chequear que hierarchiesArr tenga los hijos en formato similar al devuelto por handleselected

        setSubaccounts(aux);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    const fetchEmails = async () => {
      try {
        setLoading(true)
        // setError(null)
        const res = await getSubaccountEmails();
        setEmailList(res.data)
        setLoading(false)
      }
      catch (error) {
        // console.error(error);
        setLoading(false);
        setEmailList([])
        if (error.response) {
          // setError(`${error.response.data.msg}.`);
        } else {
          // setError(`Ocurrió un error inesperado`);
        }

      };
    }

    fetchSubaccounts();
    fetchEmails()
  }, []);

  const handleEditSubaccount = (id, hierarchiesArr, subaccountEmail) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setIsEditing(true);
    setHierarchies(hierarchiesArr)
    setSubaccountId(id); setEmail(subaccountEmail);
  }

  const handleDeleteSubaccount = async (id, email) => {
    try {
      setLoading(true);
      const res = await deleteSubaccount(id);
      setLoading(false);
      setNotification({
        isOpen: true,
        msg: res.data?.msg,
        severity: "success"
      })
      setSubaccounts(subaccounts.filter(subaccount => subaccount.id !== id))

      setEmailList(emailList.filter(e => e !== email))


      // setNotificationSuccess(true);
    } catch (error) {
      setLoading(false);
      if (error.response.data.msg) {
        setNotification({
          isOpen: true,
          msg: `${error.response.data.msg} (${error.response.data.error_code}).`,
          severity: "error"
        })

      } else {
        setNotification({
          isOpen: true,
          msg: `Ocurrió un error inesperado.`,
          severity: "error"
        })
      }
    }
  }

  const generateHierarchyQuery = () => {
    // console.log('generateHierarchyQuery', hierarchies)
    let query = ""

    for (let i = 0; i < hierarchies.length; i++) {
      if (i > 0) {
        query += '&'
      }
      query += `hierarchy=${hierarchies[i].id}`
    }

    return query;
  }

  const handleCreate = async () => {
    try {
      setLoading(true);
      // hierarchy = 1325 & hierarchy=1326 & hierarchy=1329



      const res = await addSubaccount(email, generateHierarchyQuery());
      setEmail("")

      // console.log(res)
      const aux = {
        id: res.data?.id > 0 ? res.data?.id : -1 * res.data?.id,
        status: res.data?.id > 0 ? 'ACEPTADO' : 'INVITACIONENVIADA',
        hierarchiesArr: hierarchies,
        subaccountEmail: email,
      }
      setHierarchies([])

      setSubaccounts([...subaccounts, aux])

      // Me aseguro que el mail recien creado forme parte de la lista por si el usuario lo ingresa nuevamente antes de refrescar
      setEmailList([...emailList, email])

      setLoading(false);

      setNotification({
        isOpen: true,
        msg: res.data?.msg,
        severity: "success"
      })
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error.response) {
        setNotification({
          isOpen: true,
          msg: `${error.response.data.msg} (${error.response.data.error_code}).`,
          severity: "error"
        })

      } else {
        setNotification({
          open: true,
          message: `Ocurrió un error inesperado.`,
          severity: "error"
        })
      }
    }
  }
  const handleEdit = async () => {
    try {
      setLoading(true);

      setIsEditing(false);
      setEmail("")

      const res = await editSubaccount(subaccountId, generateHierarchyQuery())

      // console.log(hierarchies)

      const aux = subaccounts.map(subaccount => {
        if (subaccount.id === subaccountId) {
          return {
            ...subaccount,
            hierarchiesArr: hierarchies,
          }
        }
        return subaccount;
      })

      setSubaccounts(aux);
      setHierarchies([])

      setLoading(false);

      setNotification({
        isOpen: true,
        msg: res.data?.msg,
        severity: "success"
      })
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error.response) {
        setNotification({
          isOpen: true,
          msg: `${error.response.data.msg} (${error.response.data.error_code}).`,
          severity: "error"
        })

      } else {
        setNotification({
          open: true,
          message: `Ocurrió un error inesperado.`,
          severity: "error"
        })
      }
    }
  }


  const handleClose = () => {
    setIsOpen(false);
  }

  const handleSelectedHierarchy = (node) => {
    // console.log('node', node)
    const set1 = new Set(node.childrenList);
    let overlap = false

    for (let h of hierarchies) {
      for (let child of h.hijos) {
        // console.log('#', child)
        if (set1.has(child)) {
          overlap = true
        }
      }

    }
    if (overlap) {
      setNotification({
        isOpen: true,
        msg: `Uno o más nodos elegidos forman parte de una selección anterior. Si desea dar curso a esta solicitud es necesario eliminarlos.`,
        severity: "error"
      })
    }
    else {
      setHierarchies([...hierarchies, { nombre: node.nombre, id: node.id, hijos: node.childrenList }])
    }

    setIsOpen(false);

  }

  const isValidEmail = (str) => {
    if (/^[a-zA-Z0-9][a-zA-Z0-9-_.]+@([a-zA-Z]|[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9])\.[a-zA-Z0-9]{2,10}(?:\.[a-zA-Z]{2,10})?$/.test(str)) {

      return true
    }
    return false
  }

  const isUniqueEmail = (str) => {
    if (emailList.includes(str)) {
      return false;
    }
    return true;
  }

  const handleDeleteHierarchy = (id) => {
    setHierarchies(hierarchies.filter(h => h.id !== id))
  }

  // console.log({ subaccounts })

  // console.log(hierarchies)

  return (
    <Box className={classes.container} display='flex' justifyContent='center' flexDirection='column' px={2} py={1}>
      <Box component="h3" color="primary.main" ml={0.5} my={0.5}>Gestión de Sub-Cuentas</Box>
      {
        !loading && subaccounts.length === 0 &&
        <ErrorAlert message="No posee ninguna subcuenta puede crear una a continuación." severity='warning' />
      }

      {loading &&
        <Box display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      }

      <Box bgcolor="background.paper" color="primary.main" p={1} borderRadius={4}>
        {isEditing ? <Box component="h4" my={1}>Editar Subcuenta N°{subaccountId}</Box> : <Box component="h4" my={1}>Nueva Subcuenta</Box>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              color='primary' variant='contained'
              onClick={() => setIsOpen(true)}
              startIcon={<TuneIcon />}
            >
              Dispositivo
            </Button>

            {hierarchies.length > 0 &&
              hierarchies.map(({ id, nombre }) => (
                <Chip
                  key={id}
                  icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                  label={nombre}
                  clickable
                  color='primary'
                  onDelete={() => handleDeleteHierarchy(id)}
                  className={classes.filterChip}
                />
              ))}
          </Grid>

          <Grid item xs={12} md>
            <TextField
              placeholder="Correo Electrónico"
              label="Correo Electrónico"
              name="email"
              fullWidth
              color='primary'
              variant="outlined"
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isEditing}
            />
          </Grid>
        </Grid>

        {!isEditing &&
          <>
            <Box display='flex' justifyContent="center" mt={1} mx="auto" p={1}>
              <Box mr={1}>
                <Button color='primary' variant='contained' onClick={handleCreate} disabled={!isValidEmail(email) || !isUniqueEmail(email) || hierarchies.length === 0}>Crear</Button>
              </Box>
              <Box ml={1}>
                <Button color='primary' variant='contained' onClick={() => { setEmail(""); setHierarchies([]) }}>Cancelar</Button>
              </Box>
            </Box>

            <Box style={{ textAlign: 'center' }}>
              {!isValidEmail(email) && <div>El correo electrónico ingresado no es válido</div>}
              {!isUniqueEmail(email) && <div>Ya existe una SubCuenta asociada a este correo electrónico es necesario editarla.</div>}
              {hierarchies.length === 0 && <div>Debe seleccionar una jerarquía</div>}
            </Box>
          </>
        }

        {isEditing &&
          <Box display='flex' justifyContent="center" mt={1} mx="auto" p={1}>
            <Box mr={1}>
              <Button color='primary' variant='contained' onClick={handleEdit} disabled={hierarchies.length === 0}>Actualizar</Button>
            </Box>
            <Box ml={1}>
              <Button color='primary' variant='contained' onClick={() => { setIsEditing(false); setEmail(""); setHierarchies([]) }}>Cancelar</Button>
            </Box>
          </Box>
        }
      </Box>

      {
        !loading && subaccounts.length > 0 &&
        <Box bgcolor="background.paper" color="primary.main" p={1} borderRadius={4}>
          <Tooltip title={`Ha utilizado ${subaccounts.length} Sub-Cuentas de ${plan?.menu?.max_subaccounts} disponibles`} arrow placement='right'>
            <Box component="h4" my={1}>
              Subcuentas Existentes {subaccounts.length}/{plan?.menu?.max_subaccounts}
            </Box>
          </Tooltip>
          {
            subaccounts.map(({ id, status, hierarchiesArr, subaccountEmail }) => (
              <Box className={classes.row} key={id}>
                <Box>{subaccountEmail}</Box>
                <Box><StatusChip status={status} /></Box>
                <Box><ul className={classes.nodeList}>{hierarchiesArr.map(h => <li key={h.id}>{h.nombre}</li>)}</ul></Box>
                <Box className={classes.btns}>
                  <Box display='flex' justifyContent="center" mt={1} mx="auto" p={1}>
                    <Box mr={1}>
                      <Tooltip title='Editar Sub-Cuenta' arrow placement='right'>
                        <Button color="primary" startIcon={<EditIcon />} variant="contained" aria-label='Editar Sub-Cuenta' disabled={status === 'REVOCADO'} onClick={() => handleEditSubaccount(id, hierarchiesArr, subaccountEmail)}>
                          Editar
                        </Button>
                      </Tooltip>
                    </Box>
                    <Box mr={1}>
                      <Tooltip title='Eliminar Sub-Cuenta' arrow placement='right'>
                        <Button color="primary" variant="contained" disabled={id < 0} startIcon={<DeleteIcon />} aria-label='Eliminar Sub-Cuenta' onClick={() => handleDeleteSubaccount(id, subaccountEmail)}>
                          Eliminar
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          }
        </Box>
      }




      <DevicesList open={isOpen} handleClose={handleClose} handleSubmit={handleSelectedHierarchy} />

      <Snackbar open={notification.isOpen} autoHideDuration={7000} onClose={() => setNotification({ ...notification, isOpen: false })}>
        <Alert severity={notification.severity} onClose={() => setNotification(false)}>
          {notification.msg}
        </Alert>
      </Snackbar>

    </Box>
  )
}

export default SubaccountsCreation