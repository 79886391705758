import { useContext, useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
//Hook personalizado.
import DiagramContext from '../Store/DiagramContext';
//Helper
import { getSettingBlock } from '../helpers';
//Components.
import ModalSettings from '../ModalSettings';
//React flow
import { useEdges, useNodes } from 'reactflow';

let styleAux = {
    width: `60px`,
    height: `50px`,
}

const useStyles = makeStyles(theme => ({
    root: {
        ...styleAux,
        backgroundColor: '#f5f5f5',
        border: `1px solid ${`black`}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    disabledStyle: {
        ...styleAux,
        pointerEvents: 'none',
        opacity: 0.5,
        backgroundColor: '#f0f0f0',
        color: '#a0a0a0',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function ContainerBlockGenericType
    ({ id, isConnectable, type, selected, labelBlock,
        flagNumber, showLabelInput, LabelInputRight,
        adderFlag, mTopValid, userConfiguration }) {
    const classes = useStyles();
    const edges = useEdges();
    const nodes = useNodes()
    const { stateDiagram, setStateDiagram } = useContext(DiagramContext)
    const [blockNodeFilter, setBlockNodeFilter] = useState({});
    const [blockNodeSettings, setBlockNodeSettings] = useState({});
    const [openModal, setOpenModal] = useState(false)
    const [disabledNode, setDisabledNode] = useState(false);

    let flagNumberTopInputs = flagNumber

    //Estado para buscar los datos de la propiedad 'a' para mostrar en el bloque.
    useEffect(() => {
        let findElement = nodes.find((elem) => elem.id === id)
        setBlockNodeFilter(findElement)
        // eslint-disable-next-line
    }, [nodes])

    useEffect(() => {
        let res = getSettingBlock(type)
        setBlockNodeSettings(res)
        // eslint-disable-next-line
    }, [])

    //Deshabilita el nodo segun el nodo el cual se esta conectando.
    useEffect(() => {
        let res = stateDiagram.disabledNodes?.includes(type)
        setDisabledNode(res)
        // eslint-disable-next-line
    }, [stateDiagram.disabledNodes])

    const handleOpenModal = () => {
        //Guardo el estado de el modal para desactivar el cntr + V
        setStateDiagram({
            ...stateDiagram,
            openModal: !openModal
        })
        setOpenModal(!openModal)
    }

    const checkTargetConnect = (idAux, elementAux) => {
        let flag = true
        for (let i = 0; i < edges.length; i++) {
            const element = edges[i];
            if (element.target === idAux) {
                if (element.targetHandle === elementAux.key) {
                    flag = false
                }
            }
        }
        return flag
    }

    const validType = () => {
        if (type === 'OUTA' || type === 'OUTD') {
            return false
        }
        return true
    }

    const onDoubleClickAux = () => {
        if (!openModal) {
            handleOpenModal();
        }
    }

    //Retorna el label de el bloque CONST.
    const labelReturn_CONST_ = (value) => {
        let res = 'CONST'
        if (value?.a) {
            res = value?.a?.v.toString()
        }
        if (value?.values) {
            res = value?.values
        }
        return res
    }

    return (
        <Box>

            <Box fontSize={6} display={'flex'} justifyContent={'center'}>
                {`${type} (id:${id})`}
            </Box>

            <Box
                className={disabledNode ? classes.disabledStyle : classes.root}
                key={id}
                style={{
                    border: selected ? '1px dashed #2586bc' : `1px solid black`,

                }}
                // onClick={handleClick}
                onDoubleClick={onDoubleClickAux}
            >

                {openModal &&
                    <ModalSettings
                        type={type}
                        blockNodeSettings={blockNodeSettings}
                        handleOpenModal={handleOpenModal}
                        openModal={openModal}
                        userConfiguration={userConfiguration}
                        id={id}
                    />
                }

                {labelBlock ?
                    <Box fontSize={11}  >
                        {labelBlock === 'CONST' ?
                            <>
                                {labelReturn_CONST_(blockNodeFilter)}
                            </>
                            :
                            <>
                                {labelBlock}
                            </>
                        }
                    </Box>
                    :
                    <Box mt={mTopValid ? mTopValid : 0} display={'flex'}>
                        <img src={`/images/diagram/${type.toLowerCase()}.svg`} alt="" width="35" height="35" />
                    </Box>
                }

                {validType() &&
                    <Tooltip
                        title={'Salida'}
                        arrow
                        placement='right'
                    >
                        <Handle
                            type="source"
                            position={Position.Right}
                            isConnectable={isConnectable}
                            id="1"
                            style={{ top: 33, width: 8, height: 8, backgroundColor: '#1d1c33d1' }}
                        />
                    </Tooltip>
                }

                {(Array.isArray(blockNodeSettings?.inputs_keys) ? blockNodeSettings.inputs_keys : []).map((element, index) => {
                    let flagAux = flagNumberTopInputs
                    flagNumberTopInputs += adderFlag ? adderFlag : 11
                    return (
                        <Box key={element.key}>

                            <Tooltip
                                title={element?.name}
                                arrow
                                placement='right'
                            >
                                <Handle
                                    key={index}
                                    type="target"
                                    position={type === 'MUX2' && element.key === 's' ? Position.Bottom : Position.Left}
                                    id={element.key}
                                    isConnectable={disabledNode ? false : isConnectable}
                                    style={{ left: type === 'MUX2' && element.key === 's' ? 8 : '', top: type === 'MUX2' && element.key === 's' ? 55 : flagAux, width: 7, height: 7, border: `1px solid ${`black`}`, backgroundColor: checkTargetConnect(id, element) ? 'white' : '#b7b4b4' }}
                                />
                            </Tooltip>

                            {showLabelInput &&
                                <Box style={{
                                    position: 'absolute',
                                    top: type === 'MUX2' && element.key === 's' ? 44 : flagAux - 5,
                                    right: LabelInputRight,
                                    fontSize: '8px',
                                    width: '10px',
                                }}>
                                    {element.key}
                                </Box>
                            }
                        </Box>
                    )
                })}
            </Box>
        </Box >

    );
}
export default ContainerBlockGenericType
