import { useEffect, useState, useContext } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@mui/material';
//Servicios
import { getInstantV2 } from '../../../services/meters';
//Componentes
import DualChart from '../../Instant/DualChart';
import { getInitialEndDate } from '../../../services/helpers';
//Helpers
import { helperComponent } from '../../DinamicDashboard/helpers';
import { chekUrlPublic } from '../../DinamicDashboard/helpers';
//Context
import UserContext from '../../../context/UserContext';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.spacing(2.2),
    margin: theme.spacing(0, 0, 1, 3),
    fontWeight: '600',
    paddingTop: theme.spacing(1.5),
    color: theme.palette.grey.hairline,
  },
}));

function ContainerChartInstant({ id, title, config_key, ky, content, height, refresh, nodes }) {
  const { plan } = useContext(UserContext)
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showMain, setShowMain] = useState(false);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = useState({});
  const [granularity, setGranularity] = useState('5');
  const [subTitle, setSubtitle] = useState('')
  const [tz, setTz] = useState('America/Argentina/Buenos_Aires');

  //Funcion q me da la hora de el final de el dia segun la opcion.
  const initialEndDay = async () => {
    let range = config_key.split('-')[3]
    let res = await getInitialEndDate(range)
    let to = res?.data?.to
    let obj = returnVariable()
    setFilterOptions({
      ...obj,
      selectedEndDate: to
    });
  }

  //Valida el timeZone
  useEffect(() => {
    let tz='America/Argentina/Buenos_Aires'
    if (chekUrlPublic()) {
      var valor = localStorage.getItem('PUBLIC');
      tz = JSON.parse(valor).info?.tz
    }
    else {
      tz = plan?.info?.tz
    }
    setTz(tz)
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    const fetchInstantV2 = async url => {
      try {
        setLoading(true);
        setData([]);
        setShowErrorAlert(false);
        setErrorMessage('');
        if (content && chekUrlPublic()) {
          const propiedades = Object.keys(content);
          if (propiedades.length > 0) {
            setData(content);
          }
          else {
            setShowMain(false)
            setErrorMessage(`Ocurrió un error inesperado.`);
            setShowErrorAlert(true)
          }
          const numeroRandom = Math.floor(Math.random() * 4) + 1
          setTimeout(() => {
            setLoading(false)
          }, numeroRandom * 1000)
        }
        else {
          let res = await getInstantV2(url);
          setData(res.data);
          setLoading(false);
        }
        setShowMain(true);
      }
      catch (error) {
        setLoading(false);
        if (error?.response?.data?.msg) {
          setErrorMessage(
            `${error.response.data.msg}.`
          );
        }
        else {
          setErrorMessage(`Ocurrió un error inesperado.`);
        }
        setShowErrorAlert(true);
      }
    }
    //Configura hora o día  final de el grafico para dejar espacio de el recorrido total de el día.
    initialEndDay()

    let obj = returnVariable()
    let url = createUrl(config_key)
    url += `&fields=${obj.fields}`;
    url += `&response_format=v2`;

    fetchInstantV2(url)
    return () => {
      setData([]);
    }
    // eslint-disable-next-line
  }, [id, config_key, refresh, nodes]);

  //funcion para dar las opciones segun la variable seleccionada.
  const returnVariable = () => {
    let resObj = {
      variable: '',
      fields: '',
    }
    Object.entries(helperComponent('DASHBOARDS_INSTANT')).forEach(([clave, valor]) => {
      let kEY_CONFIG = config_key.split('-')[1]
      let KEY_CATALOGUE = valor.value.split('-')[0]
      if (kEY_CONFIG === KEY_CATALOGUE) {
        //configuracion de el sub titulo.
        let titleKeyGranularity = config_key.split('-')[4]
        let titleKeyPeriod = config_key.split('-')[3]
        let values = valor.periods?.find(e => e.key === titleKeyGranularity)?.values
        let name = values?.find(e => e.key === titleKeyPeriod)?.name
        setSubtitle(name ? name : '')
        resObj.variable = valor.value?.split('-')[0]
        resObj.fields = valor.value?.split('-')[1]
      }
    })

    return resObj
  }

  const createUrl = (config_key) => {
    let range = config_key.split('-')[3]
    let granularity = config_key.split('-')[4].split('_')[0]

    let idAux = `filter=${''}`
    let auxNodes = nodes

    if (id) {
      idAux = `filter=${id}`
    }

    if (auxNodes?.length > 0) {
      let arryIds = []
      auxNodes.forEach((elem) => {
        let string = `filter=${elem.id}`
        if (elem?.tag?.id) {
          string = `filter=${elem.id}+${elem?.tag?.id}`
        }
        arryIds.push(string)
      })
      idAux = arryIds?.join()
      idAux = idAux.replace(/,/g, '&');
    }

    let url = `${idAux}&range=${range}&granularity=${granularity}`;
    setGranularity(granularity)
    return url
  }

  //Función encargada de dar el título al componente si son múltiples nodos o uno solo.
  const checkMultiplesNodesSubTitle = () => {
    let res = ''
    if (nodes?.length > 1) {
      res = `Multiples nodos`
    }
    else {
      res = (nodes && data[0]?.node?.nombre ? (` (${data[0]?.node?.nombre})`) : '')
    }
    return res
  }


  return (
    <Box style={{ height: height }} >
      {showErrorAlert && <Alert severity='error'>{errorMessage}</Alert>}
      {loading ? (
        <Box display='flex' justifyContent='center' mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {showMain && (
            <>
              <Box className={classes.title} style={nodes ? { fontSize: '14px' } : {}} >
                {title ? title : checkMultiplesNodesSubTitle()}
                <span style={{ fontWeight: '400' }}>{` | ${subTitle}`}</span>
              </Box>
              <DualChart
                ky={ky}
                className={classes.chart}
                chartData={data}
                chartOptions={filterOptions}
                auxHeight={'98%'}
                zoomBar={true}
                granularityDinamicDash={granularity}
                tz={tz}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
}
export default ContainerChartInstant;
