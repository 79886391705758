import React, { useContext, useState,useEffect } from 'react';
// Material UI
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel,
  Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Tooltip,
  makeStyles
} from '@material-ui/core';
import { format as formatDate, isPast } from 'date-fns';
// icons
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import DevicesDialog from './DevicesDialog';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import {
  FORMAT_OPTIONS,
  FREQUENCY_OPTIONS,
  TYPE_OPTIONS,
} from './helpers';
// datepickers
import DatepickerSingle from '../DatePicker/DatepickerSingle';
import UserContext from '../../context/UserContext';
import { isValidEmail } from '../../helpers/common';
import {
  createAutomaticReport,
  createReport,
  deleteReport,
  editReport,
  updateAutomaticReport,
} from '../../services/reports';
import ConfirmationDialog from './ConfirmationDialog';
import { convertToTimeZone } from 'date-fns-timezone';
//Servicios
import { getStatusNodes } from '../../services/meters';

const useStyles = makeStyles(theme => ({
  devicesBtn: {
    margin: theme.spacing(3, 1),
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
    minWidth:24
  },
  formGrow: {
    flexGrow: 1,
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  filterChip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '250px',
    },
  },

  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    '& .MuiTypography-body1': {
      fontSize: '0.75rem',
    },
  },
  error: {
    marginLeft: theme.spacing(1),
    color: '#af2c47',
    fontSize: '0.75rem',
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      gap: theme.spacing(1)
    },
  },
  email: {
    marginLeft: theme.spacing(1),
    '& .MuiInputBase-input': {
      fontSize: '12px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  radio: {
    height: '150px',
    marginLeft: theme.spacing(1),
    '& .MuiTypography-body1': {
      fontSize: '0.75rem',
    },
    '& > *': {
      flexBasis: '33%',
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
    '@media (max-width: 700px)': {
      height: 'auto',
    },

  },
  help: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5),
  },
  pending: {
    color: '#af2c47',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5),
    textAlign: 'center'
  },
  tabsTitle: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'uppercase',
    // color: 'rgba(0, 0, 0, 0.54)',
    color: theme.palette.primary.main,
    textAlign: 'center',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  }
}));

function Filter({
  filterOptions,
  setFilterOptions,
  togglePanel,
  handleSubmit,
}) {
  const classes = useStyles();
  const { plan } = useContext(UserContext);
  const [showDevices, setShowDevices] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [statusNodes, setStatusNodes] = useState([]);

  //Verifica status de el medidor.
  useEffect(() => {
    const getStatusNodesAux = async () => {
      let res = await getStatusNodes()
      let arry = res.data
      setStatusNodes(arry)
    }
    getStatusNodesAux()
  }, [])

  // TODO Cuando cambia filterOptions.devices tengo que limpiar la variable seleccionada.
  const handleFilterChange = e => {
    if (e.target.type === 'checkbox') {
      return setFilterOptions({
        ...filterOptions,
        [e.target.name]: JSON.parse(e.target.checked),
      });

    }
    if (e.target.type === 'number') {
      return setFilterOptions({
        ...filterOptions,
        [e.target.name]: parseInt(e.target.value),
      });
    }
    if (e.target.name === 'variable') {
      // Si tengo solo una variable seleccionada:
      // i) Selecciono la primer tab por si estoy en una que en la nueva variable no existe.
      // ii) Selecciono el primer período relativo porque cambié la variable.
      // Si tengo mas de una variable no cambio ninguno de los dos campos.
      const chosen_range = e.target.value.length === 1 ? objectCatalog[e.target.value]?.periods[0]?.values?.[0]?.key : filterOptions.chosen_range;
      const frequency_date = e.target.value.length === 1 ? objectCatalog[e.target.value]?.periods[0].key : filterOptions.frequency_date;

      setFilterOptions({
        ...filterOptions,
        variable: e.target.value || [],
        tabValue: 0,
        chosen_range,
        frequency_date
      });
    }
    else {
      setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }

  };

  // Click en la cruz del óvalo azul del panel de filtrar
  const handleDeleteOval = id => {
    const devices = filterOptions.devices.filter(device => {
      return device.id !== id;
    });
    setFilterOptions({ ...filterOptions, devices, variable: [] });
  };

  const handleClickOval = () => {
    setShowDevices(true);
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
    setErrorMessage('');
  };

  const handleAddEmail = () => {
    // Si el correo ingresado ya existe no lo agrego.
    if (filterOptions.emails.indexOf(email) !== -1) {
      setErrorMessage(`${email} ya forma parte del listado`);
      return;
    }

    const emails = [...filterOptions.emails, email];
    setEmail('');
    setFilterOptions({ ...filterOptions, emails });
  };

  const handleDeleteEmail = email => {
    const emails = filterOptions.emails.filter(
      savedEmail => savedEmail !== email
    );
    setFilterOptions({ ...filterOptions, emails });
  };

  const handlePreDeleteReport = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteReport = async () => {
    try {
      await deleteReport(filterOptions.id);
      const notification = {
        isOpen: true,
        message: 'Reporte eliminado exitosamente',
        severity: 'success',
      };
      setIsDeleteDialogOpen(false);
      // Quiero mostrar la alerta y luego cierro.
      handleSubmit(notification);
    } catch (error) {
      let msg = 'Ocurrió un error inesperado';
      if (error.response) {
        // TODO A futuro deberíamos recibir el mensaje de error en error.response.data.msg
        msg = `${error.response.data.detail}.`
      }

      handleSubmit({
        isOpen: true,
        message: msg,
        severity: 'error',
      });
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleTabChange = (event, tabNumber) => {

    const frequency_date = objectCatalog[filterOptions.variable[0]]?.periods[tabNumber]?.key;
    // Al cambiar de pestaña quiero elegir al primer período
    const chosen_range = objectCatalog[filterOptions.variable[0]]?.periods[tabNumber]?.values?.[0]?.key;


    setFilterOptions({
      ...filterOptions,
      tabValue: tabNumber,
      frequency_date,
      chosen_range
    });
  };

  const handleRadioChange = e => {
    setFilterOptions({ ...filterOptions, chosen_range: e.target.value });
  };

  const handleCreate = async () => {
    try {
      const {
        report_name,
        type,
        variable,
        format,
        report_automatic,
        devices,
        frequency_date,
        chosen_range,
        frequency,
        frequency_custom,
        dateFrom,
        emails,
      } = filterOptions;

      const filter = devices.map(device => String(device.id));

      setWaiting(true);
      const res = await createReport({
        report_name,
        type,
        variable: variable.join(','),
        format,
        // No se lo paso ya que si es true y el segundo endpoint no prospera quedaría en un estado incoherente.
        // report_automatic,
        filter,
        frequency_date,
        chosen_range,
      });

      let notification = {
        isOpen: true,
        message: 'Reporte creado exitosamente',
        severity: 'success',
      };

      // Si tengo report_automatic en true debo esperar el resultado de la creación del reporte para crear el reporte automático.
      const reportId = res.data?.id;
      if (report_automatic) {
        const formattedDate = formatDate(
          dateFrom ?? oneHourLater,
          'dd/MM/yyyy HH:mm:ss'
        );

        const payload = {
          frequency: frequency !== 'CUSTOM' ? frequency : null,
          frequency_custom: frequency === 'CUSTOM' ? frequency_custom : null,
          start: formattedDate,
          emails: emails.join(', '),
        };

        await createAutomaticReport(reportId, payload);

        notification = {
          isOpen: true,
          message: 'Reporte automático creado exitosamente',
          severity: 'success',
        };
      }

      setWaiting(false);

      // Quiero mostrar los mensajes con el modal abierto
      handleSubmit(notification);
    } catch (error) {
      let msg = 'Ocurrió un error inesperado';
      if (error.response) {
        if (error?.response?.data?.msg) {
          msg = `${error?.response?.data?.msg}.`;
        }
        // TODO Deberíamos recibir todos los errores en error.response.data.msg
        if (error?.response?.data?.type) {
          msg = `${error.response.data.type}.`;
        }
      }

      handleSubmit({
        isOpen: true,
        message: msg,
        severity: 'error',
      });

    }
  };

  const handleEdit = async () => {
    try {
      const {
        id,
        report_name,
        type,
        variable,
        format,
        report_automatic,
        reportAutomatic,
        devices,
        frequency_date,
        chosen_range,
        frequency,
        frequency_custom,
        dateFrom,
        emails,
      } = filterOptions;

      const filter = devices.map(device => String(device.id));
      setWaiting(true);
      await editReport(id, {
        report_name,
        type,
        variable: variable.join(','),
        format,
        report_automatic,
        filter,
        frequency_date,
        chosen_range,
      });

      let notification = {
        isOpen: true,
        message: 'Reporte editado exitosamente',
        severity: 'success',
      };

      if (report_automatic) {
        const formattedDate = formatDate(dateFrom, 'dd/MM/yyyy HH:mm:ss');

        const payload = {
          frequency: frequency !== 'CUSTOM' ? frequency : null,
          frequency_custom: frequency === 'CUSTOM' ? frequency_custom : null,
          start: formattedDate,
          emails: emails.join(', '),
        };

        if (!reportAutomatic) {
          // Si report_automatic es true y reportAutomatic es null debo crear un nuevo reporte automático
          // console.log('Creo nuevo reporte automático');
          await createAutomaticReport(id, payload);
          notification = {
            isOpen: true,
            message: 'Reporte automático creado exitosamente',
            severity: 'success',
          };
        } else {
          // Su report_automatic es true y reportAutomatic no es null debo actualizar el reporte existente
          // console.log('Actualizo reporte automático');
          await updateAutomaticReport(id, reportAutomatic.id, payload);
          notification = {
            isOpen: true,
            message: 'Reporte automático editado exitosamente',
            severity: 'success',
          };
        }
      }
      setWaiting(false);
      handleSubmit(notification);

    } catch (error) {
      let msg = 'Ocurrió un error inesperado';
      if (error.response) {
        if (error.response?.data.msg) {
          msg = `${error.response.data.msg}.`;
        }
        // TODO Deberíamos recibir todos los errores en error.response.data.msg
        if (error.response?.data.type) {
          msg = `${error.response.data.type}.`;
        }
      }
      handleSubmit({
        isOpen: true,
        message: msg,
        severity: 'error',
      });
    }
  };

  // Calcular la fecha de inicio en la hora siguiente y configurando los minutos en 0
  const now = new Date();
  now.setMinutes(0);

  const a = convertToTimeZone(now, { timeZone: plan?.info?.tz })

  const oneHourLater = new Date(new Date(a).getTime() + 60 * 60 * 1000);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (isValidEmail(email)) {
        handleAddEmail();
      } else {
        setErrorMessage('El mail ingresado es inválido');
      }
    }
  };

  const enabledItems = plan?.sections['ALERTAS_REPORTES'].filter(item => item.enabled);
  const objectCatalog = {};
  enabledItems.forEach(item => {
    objectCatalog[item.key] = {
      key: item.key,
      name: item.name,
      periods: item.periods,
      endpoint_key: item?.endpoint?.key,
    };
  });

  const hasSomeVirtualNodes = () => {
    return filterOptions.devices.some(item => item?.virtual);
  };

  const handleCheckboxEnabledItemsChange = (event) => {
    const { value, checked } = event.target;
    handleFilterChange({
      target: {
        name: 'variable',
        value: checked
          ? [...new Set([...filterOptions.variable, value])]
          : filterOptions.variable.filter((v) => v !== value)
      }
    });
  }; 

  return (
    <>
      <Grid container spacing={4} component='form'>
        {/* Nombre del reporte */}
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' ml={2}>
            <Tooltip
              title='Indique el nombre del reporte'
              arrow
              placement='right'
            >
              <Box className={classes.hint}>1</Box>
            </Tooltip>
            <FormControl className={classes.formGrow}>
              <TextField
                label='Nombre del reporte'
                name='report_name'
                value={filterOptions.report_name || ''} //Contemplo casos creado con nombre=null con Postman
                onChange={handleFilterChange}
                color='primary'
              />
            </FormControl>
          </Box>
        </Grid>

        {/* Tipo de reporte */}
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' ml={2}>
            <Tooltip title='Elija el tipo de reporte' arrow placement='right'>
              <Box className={classes.hint}>2</Box>
            </Tooltip>
            <FormControl className={classes.formGrow}>
              <InputLabel id='typeLabel'>Tipo reporte</InputLabel>
              <Select
                labelId='typeLabel'
                name='type'
                value={filterOptions.type}
                onChange={handleFilterChange}
              >
                {TYPE_OPTIONS.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {/* Formato del reporte */}
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' ml={2}>
            <Tooltip
              title='En caso de que sea de tipo tabla elija el formato del reporte'
              arrow
              placement='right'
            >
              <Box className={classes.hint}>3</Box>
            </Tooltip>
            <FormControl className={classes.formGrow}>
              <InputLabel id='formatLabel'>Formato reporte</InputLabel>
              <Select
                labelId='formatLabel'
                name='format'
                value={filterOptions.format}
                onChange={handleFilterChange}
                disabled={filterOptions.type === 'DASHBOARD'}
              >
                {FORMAT_OPTIONS.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {/* Dispositivos */}
        <Grid item xs={12}>
          <Box
            display='flex'
            alignItems='center'
            flexWrap='wrap'
            minHeight={48}
            ml={2}
          >
            {/* Tooltip dispositivos */}
            <Tooltip
              title='Elija los dispositivos que desea añadir al reporte'
              arrow
              placement='right'
            >
              <Box className={classes.hint}>4</Box>
            </Tooltip>
            <Box className={classes.label}>Agregar dispositivo</Box>
            <Tooltip
              title='Haga click aquí para agregar dispositivo'
              arrow
              placement='right'
            >
              <span>
                <IconButton
                  className={classes.addBtn}
                  aria-label='agregar dispositivo'
                  onClick={() => setShowDevices(true)}
                >
                  <AddIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
            {filterOptions.devices.length > 0 &&
              filterOptions.devices.map(device => (
                <Chip
                  key={device.id}
                  icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                  label={device.name}
                  clickable
                  color='primary'
                  onDelete={() => handleDeleteOval(device.id)}
                  className={classes.filterChip}
                  onClick={handleClickOval}
                />
              ))}
          </Box>
        </Grid>
        {/* Variable del reporte */}
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' ml={2}>
            <Tooltip
              title='Elija la variable sobre la cual desea obtener el reporte'
              arrow
              placement='right'
            >
              <Box className={classes.hint}>5</Box>
            </Tooltip>
            <FormControl className={classes.formGrow}>
              <InputLabel id='variableLabel'>Variables (Selección múltiple)</InputLabel>
              <Select
                labelId='variableLabel'
                name='variable'
                value={filterOptions.variable}
                onChange={handleFilterChange}
                disabled={filterOptions.devices.length === 0}
                multiple
                renderValue={(selected) => (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value) => {
                      const item = enabledItems.find(i => i.key === value);
                      return (
                        <span key={value}>{item?.tag || item?.name} </span>
                      );
                    }).reduce((prev, curr) => [prev, ', ', curr])}
                  </div>
                )}
              >
                {enabledItems.map(item => (
                      <MenuItem key={item.key} value={item.key} disabled={(item.node === 'FISICO' && hasSomeVirtualNodes() === true) || (filterOptions.variable.length > 0 && objectCatalog[filterOptions.variable?.[0]]?.endpoint_key !== item?.endpoint?.key)}>
                      <Checkbox
                        checked={filterOptions.variable.includes(item.key)}
                        onChange={handleCheckboxEnabledItemsChange}
                        value={item.key}
                        color='primary'
                        disabled={(item.node === 'FISICO' && hasSomeVirtualNodes() === true) || (filterOptions.variable.length > 0 && objectCatalog[filterOptions.variable?.[0]]?.endpoint_key !== item?.endpoint?.key)}
                      />
                      {item.tag || item.name}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>



          </Box>
          {
            filterOptions.devices.length === 0 &&
            <Box className={classes.help}>Debe elegir al menos un dispositivo antes de elegir la variable.</Box>
          }
        </Grid>
        {/* Período relativo */}
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' ml={2}>
            <Tooltip
              title='Seleccione la granularidad de la variable que desea obtener y el período de la consulta'
              arrow
              placement='right'
            >
              <Box className={classes.hint}>6</Box>
            </Tooltip>
            <label className={classes.label}>Granularidad y período</label>
          </Box>
          {
            filterOptions.variable.length === 0 &&
            <Box className={classes.help}>Debe elegir una variable antes de elegir la granularidad y período.</Box>
          }


          {objectCatalog[filterOptions.variable[0]]?.periods?.length > 0 &&
            <Box mt={2}>
              <Paper>
                <Box display='flex' justifyContent='center'>
                  <span className={classes.tabsTitle}>Granularidad</span>
                </Box>
                <Tabs
                  value={filterOptions.tabValue}
                  onChange={handleTabChange}
                  indicatorColor='primary'
                  textColor='primary'
                  centered
                >

                  {
                    objectCatalog[filterOptions.variable[0]]?.periods.map(period => <Tab key={period.key} label={period.label} />)
                  }
                </Tabs>
                <Box>
                  <FormControl component='fieldset' fullWidth>
                    <RadioGroup
                      aria-label='chosen_range'
                      name='chosen_range'
                      value={filterOptions.chosen_range}
                      onChange={handleRadioChange}
                      className={classes.radio}
                    >
                      {
                        objectCatalog[filterOptions.variable[0]]?.periods[filterOptions.tabValue]?.values?.map(option => (
                          <FormControlLabel
                            key={option.key}
                            value={option.key}
                            control={<Radio color='primary' />}
                            label={option.name}
                          />
                        ))
                      }
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Paper>
            </Box>
          }
        </Grid>
        {/* Envío automático */}
        <Grid item xs={12} style={{ margin: '0 16px', padding: 0 }}>
          <Accordion expanded={filterOptions.report_automatic}>
            <AccordionSummary>
              <Box display='flex' alignItems='center'>
                <Tooltip
                  title='Elija si desea programar el envío automático del reporte'
                  arrow
                  placement='right'
                >
                  <Box className={classes.hint}>7</Box>
                </Tooltip>
                <FormControl className={classes.formGrow}>
                  <FormControlLabel
                    className={classes.label}
                    control={
                      <Checkbox
                        checked={filterOptions.report_automatic}
                        color='primary'
                        name='report_automatic'
                        onChange={handleFilterChange}
                      />
                    }
                    label='Programar envío automático'
                  />
                </FormControl>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {/* Correos */}
                <Grid item xs={12}>
                  <Box display='flex' alignItems='center' minHeight={48}>
                    <Tooltip
                      title='Ingrese los correos en los que desea recibir el reporte'
                      arrow
                      placement='right'
                    >
                      <Box className={classes.hint}>8</Box>
                    </Tooltip>

                    <Box className={classes.label}>Agregar Email</Box>
                  </Box>
                  <Box className={classes.emailContainer}>
                    <TextField
                      className={classes.email}
                      name='email'
                      value={email}
                      error={email !== '' && !Boolean(isValidEmail(email))}
                      onChange={handleChangeEmail}
                      onKeyDown={handleKeyDown}
                      variant='outlined'
                      size='small'
                      placeholder={filterOptions.emails.length > 0 ? 'Ingrese un nuevo email' : 'Ingrese un email'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      variant='outlined'
                      onClick={handleAddEmail}
                      disabled={!isValidEmail(email)}
                    >
                      Confirmar
                    </Button>
                    <Box className={classes.error}>{errorMessage}</Box>
                  </Box>

                  <Grid item xs={12}>
                    {filterOptions.emails.length > 0 &&
                      filterOptions.emails.map(email => (
                        <Chip
                          key={email}
                          icon={<EmailIcon style={{ fontSize: '15px' }} />}
                          label={email}
                          color='primary'
                          onDelete={() => handleDeleteEmail(email)}
                          className={classes.filterChip}
                        />
                      ))}
                  </Grid>
                </Grid>

                {/* Frecuencia */}
                <Grid item xs={12} sm={6}>
                  <Box display='flex' alignItems='center' minHeight={64}>
                    <Tooltip
                      title='Elija la frecuencia con la cual desea recibir el reporte'
                      arrow
                      placement='right'
                    >
                      <Box className={classes.hint}>9</Box>
                    </Tooltip>
                    <FormControl className={classes.formGrow}>
                      <InputLabel id='frequencyLabel'>Frecuencia</InputLabel>
                      <Select
                        labelId='frequencyLabel'
                        name='frequency'
                        value={filterOptions.frequency}
                        onChange={handleFilterChange}
                      >
                        {FREQUENCY_OPTIONS
                          .filter(option => {
                            // No quiero que los gratuitos puedan elegir DAILY.
                            if (plan?.info?.plan === 'Gratuito' && option.value === 'DAILY') {
                              return false;
                            }
                            return true;
                          })
                          .map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                {/* Frecuencia Personalizada */}
                <Grid item xs={12} sm={6}>
                  {filterOptions.frequency === 'CUSTOM' && (
                    <Box
                      display='flex'
                      alignItems='center'
                      minHeight={64}
                      style={{ marginTop: '2px' }}
                      ml={2}
                    >
                      <FormControl>
                        <FormLabel className={classes.label}>
                          Recibir reporte cada
                        </FormLabel>
                        <TextField
                          name='frequency_custom'
                          value={filterOptions.frequency_custom}
                          onChange={handleFilterChange}
                          type='number'
                          InputProps={{
                            endAdornment: 'días',
                          }}
                          inputProps={{
                            min: plan?.info?.plan === 'Gratuito' ? 7 : 1,
                          }}

                        />
                      </FormControl>
                    </Box>
                  )}
                </Grid>

                {/* Datepicker de inicio */}
                <Grid item xs={12}>
                  <Box display='flex' alignItems='center'>
                    <Tooltip
                      title='Elija el día en que desea comenzar a recibir el reporte'
                      arrow
                      placement='right'
                    >
                      <Box className={classes.hint}>10</Box>
                    </Tooltip>
                    <label className={classes.label}>Próximo envío</label>
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <DatepickerSingle
                      startDate={filterOptions.dateFrom ?? oneHourLater}
                      setDate={setFilterOptions}
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>


        {/* Botonera */}
        <Grid item xs={12}>
          {filterOptions.devices.length === 0 && (
            <Box className={classes.pending}>
              Debe seleccionar al menos un dispositivo.
            </Box>
          )}

          {filterOptions.variable.length === 0 && (
            <Box className={classes.pending}>
              Debe seleccionar una variable.
            </Box>
          )}

          {filterOptions.report_automatic && filterOptions.emails.length === 0 && (
            <Box className={classes.pending}>
              Debe ingresar al menos una dirección de correo electrónico.
            </Box>
          )}
          {filterOptions.report_automatic && !filterOptions.frequency && (
            <Box className={classes.pending}>
              Debe elegir la frecuencia del reporte automático.
            </Box>
          )}

          {/* El siguiente componente mostrará un mensaje de error */}
          {filterOptions.report_automatic && isPast(filterOptions.dateFrom) && (
            <Box className={classes.pending}>
              Debe ingresar una fecha futura.
            </Box>
          )}
          <Box display='flex' justifyContent='center'>
            {filterOptions.id && (
              <Button
                variant='outlined'
                color='secondary'
                onClick={handlePreDeleteReport}
                className={classes.devicesBtn}
              >
                Eliminar
              </Button>
            )}

            {!filterOptions.id ? (
              <Button
                className={classes.devicesBtn}
                variant='contained'
                color='primary'
                onClick={handleCreate}
                disabled={
                  waiting ||
                    filterOptions.variable.length === 0 ||
                    filterOptions.devices.length === 0 ||
                    (filterOptions.report_automatic === true &&
                      filterOptions.emails.length === 0)
                    ? true
                    : false
                }
              >
                Crear
              </Button>
            ) : (
              <Button
                className={classes.devicesBtn}
                variant='contained'
                color='primary'
                onClick={handleEdit}
                disabled={
                  waiting ||
                    (filterOptions.report_automatic && isPast(filterOptions.dateFrom)) ||
                    filterOptions.devices.length === 0 ||
                    filterOptions.variable.length === 0 ||
                    (filterOptions.report_automatic === true &&
                      filterOptions.emails.length === 0)
                    ? true
                    : false
                }
              >
                Guardar
              </Button>
            )}

            <Button
              variant='contained'
              onClick={togglePanel}
              className={classes.devicesBtn}
            >
              Cancelar
            </Button>
          </Box>
        </Grid>
      </Grid>
      <DevicesDialog
        isOpen={showDevices}
        setIsOpen={setShowDevices}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        statusNodes={statusNodes}
      />
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        handleConfirm={handleDeleteReport}
        handleCancel={handleDeleteCancel}
        title={`¿Desea eliminar el reporte '${filterOptions?.report_name}'?`}
        content='En caso de confirmar el reporte será eliminado'
      />
    </>
  );
}

export default Filter;
