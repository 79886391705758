import { useState, useEffect } from 'react';
import {
    Box, makeStyles,
} from '@material-ui/core';
//Helpers
import { blueBar } from '../../helpers/common'
import { OPTIONS_DEVICES, SMART_DATA, HOME_DATA, mergeDataAlarmSmart,mergeDataAlarmHome } from './helpers'
//Componentes
import ButtonComponent from "./ButtonComponent";
import TableInputsComponent from './TableInputsComponent';
//Services
import { putControlSetting } from '../../services/meters';

const useStyles = makeStyles(theme => ({
    root: {
        border: "0.5px solid #B6B1B1",
        borderRadius: "4px",
        padding: theme.spacing(1),
        width: "100%",     
    },
    configForAlarmsOnDevices :{
        border: "0.5px solid #B6B1B1",
        borderRadius: "4px",
        padding: theme.spacing(1),
        width: "100%",  
        heigth:"100vh",
        display:"grid"
    },
    title: {
        fontSize: theme.spacing(1.9),
        fontWeight: '600',
        color: theme.palette.primary.main
    },
    subTitle: {
        color: theme.palette.grey.hairline,
        fontWeight: '400'
    },
    titleSecondary: {
        fontSize: theme.spacing(1.6),
        fontWeight: '600',
        color: theme.palette.primary.main,
        backgroundColor: "#94949436",
        padding: theme.spacing(1),
        borderRadius: "4px"
    },

}));

export default function AlarmsControl({
    setLoading, setAlertState, options, stateData, fromAlertsOnDevices
}) {
    const classes = useStyles();
    const blueLine = blueBar()
    //Estado de la tabla de tension.
    const [stateDataVoltage, setStateDataVoltage] = useState({})
    //Estado de la tabla de corriente.
    const [stateDataCurrent, setStateDataCurrent] = useState({})
    //Estado de la tabla de potencia.
    const [stateDataPower, setStateDataPower] = useState({})

    useEffect(() => {
        if (options.plan === OPTIONS_DEVICES.SMART) {
            if (stateData?.UMBRALES_GET?.MSJ?.rta) {
                let mergeObj = mergeDataAlarmSmart(stateData?.UMBRALES_GET?.MSJ?.rta)
                setStateDataVoltage(mergeObj.VOLTAGE)
                setStateDataCurrent(mergeObj.CURRENT)
                setStateDataPower(mergeObj.POWER)
            }
            else {
                setStateDataVoltage(SMART_DATA.VOLTAGE)
                setStateDataCurrent(SMART_DATA.CURRENT)
                setStateDataPower(SMART_DATA.POWER)
            }
        }
        if (options.plan === OPTIONS_DEVICES.HOME) {
            if (stateData?.UMBRALES_GET?.MSJ?.rta) {
                let mergeObj = mergeDataAlarmHome(stateData?.UMBRALES_GET?.MSJ?.rta)
                setStateDataVoltage(mergeObj.VOLTAGE)
                setStateDataCurrent(mergeObj.CURRENT)
                setStateDataPower(mergeObj.POWER)
            }
            else {
                setStateDataVoltage(HOME_DATA.VOLTAGE)
                setStateDataCurrent(HOME_DATA.CURRENT)
                setStateDataPower(HOME_DATA.POWER)
            }
        }
        // eslint-disable-next-line  
    }, [])

    //Onchange de la tabla tension.
    const handleChangeVoltage = (e, name) => {
        setStateDataVoltage({
            ...stateDataVoltage,
            [name]: {
                ...stateDataVoltage[name],
                [e.target.name]: e.target.value
            }
        })

    }
    //Onchange de la tabla corriente.
    const handleChangeCurrent = (e, name) => {
        setStateDataCurrent({
            ...stateDataCurrent,
            [name]: {
                ...stateDataCurrent[name],
                [e.target.name]: e.target.value
            }
        })
    }
    //Onchange de la tabla potencia.
    const handleChangePower = (e, name) => {
        setStateDataPower({
            ...stateDataPower,
            [name]: {
                ...stateDataPower[name],
                [e.target.name]: e.target.value
            }
        })
    }
    //Transforma un objeto en array.
    const arryMergeObj = (objetoOriginal) => {
        var arrayObjetos = [];
        for (var clave in objetoOriginal) {
            if (objetoOriginal.hasOwnProperty(clave)) {
                var nuevoObjeto = {
                    name: clave,
                    values: objetoOriginal[clave]
                };
                arrayObjetos.push(nuevoObjeto);
            }
        }
        return arrayObjetos
    }
    //Funcion q realiza el merge de la data del componente a la q necesita el protocolo mqtt.
    const mergeData = () => {
        const params = [];
        const properties = {
            voltage: {
                R: { name: "r_v", max: "", min: "" },
                S: { name: "s_v", max: "", min: "" },
                T: { name: "t_v", max: "", min: "" }
            },
            current: {
                R: { name: "r_i", max: "", min: "" },
                S: { name: "s_i", max: "", min: "" },
                T: { name: "t_i", max: "", min: "" }
            },
            power: {
                R: { name: "r_p", max: "", min: "" },
                S: { name: "s_p", max: "", min: "" },
                T: { name: "t_p", max: "", min: "" },
                Σ: { name: "tot_p", max: "", min: "" }
            }
        };
        const stateData = [
            [stateDataVoltage, properties.voltage],
            [stateDataCurrent, properties.current],
            [stateDataPower, properties.power]
        ];
        stateData.forEach(([data, props]) => {
            Object.entries(data).forEach(([property, value]) => {
                if (props.hasOwnProperty(property)) {
                    const prop = props[property];
                    prop.max = value.max;
                    prop.min = value.min;
                    params.push({ name: prop.name + "max", value: prop.max });
                    params.push({ name: prop.name + "min", value: prop.min });
                }
            });
        });
        return params
    };

    const HandleSubmit = async () => {
        setLoading(true)
        let msg = "Ocurrio un error inesperado"
        let type = "error"
        let bodyAux = {
            key: "SET_ALARM",
            params: mergeData()
        }
        try {
            await putControlSetting(options?.id, bodyAux)
            type = "success"
            msg = "Su configuración se guardó correctamente."
        }
        catch (error) {
            if (error?.response?.data) {
                msg = error?.response?.data
            }
        }
        setAlertState({
            close: true,
            msg: msg,
            type: type
        })
        setLoading(false)
    }

    const classForAlertsOnDevices = () => {
        return {
            border: "0.5px solid #B6B1B1",
            borderRadius: "4px",
            width: "100%",  
            height:"100vh",
            display:"grid"
        }
    }

    const classRoot = () => {
        return{
            border: "0.5px solid #B6B1B1",
            borderRadius: "4px",
            width: "100%",     
        }
    }
    

    return (
        <Box style={fromAlertsOnDevices ? classForAlertsOnDevices() : classRoot()} >

            {/* titulo */}
            <Box style={fromAlertsOnDevices && {display:'flex',alignItems:'center'}} ml={1} className={classes.title} >
                {"Alarma"}
                <span className={classes.subTitle} >
                    {` | ${"umbrales"}`}
                </span>
            </Box>

            {blueLine}

            <Box>
                <Box ml={1} className={classes.titleSecondary} >
                    {"Tension"}
                    <span className={classes.subTitle} >
                        {` | ${"por fase (V)"}`}
                    </span>
                </Box>

                {arryMergeObj(stateDataVoltage)?.map((element, indx) => {
                    return (
                        <TableInputsComponent
                            stateDataVoltage={stateDataVoltage}
                            key={indx}
                            handleChange={handleChangeVoltage}
                            element={element}
                        />
                    )
                })}
            </Box>

            {blueLine}



            <Box>
                <Box ml={1} className={classes.titleSecondary} >
                    {"Corriente"}
                    <span className={classes.subTitle} >
                        {` | ${"por fase (A)"}`}
                    </span>
                </Box>
                {arryMergeObj(stateDataCurrent)?.map((element, indx) => {
                    return (
                        <TableInputsComponent
                            key={indx}
                            handleChange={handleChangeCurrent}
                            element={element}
                        />
                    )
                })}
            </Box>

            {blueLine}

            <Box>
                <Box ml={1} className={classes.titleSecondary} >
                    {"Potencia"}
                    <span className={classes.subTitle} >
                        {` | ${"por fase (W)"}`}
                    </span>
                </Box>
                {arryMergeObj(stateDataPower)?.map((element, indx) => {
                    return (
                        <TableInputsComponent
                            key={indx}
                            handleChange={handleChangePower}
                            element={element}
                        />
                    )
                })}
            </Box>


            {blueLine}

            <Box display='flex' justifyContent='center' flexDirection={"column"} alignItems={"center"}>
                < ButtonComponent
                    children={
                        <>
                            <Box ml={1} style={{ fontWeight: "bold" }}> {"Enviar"}</Box>
                        </>
                    }
                    HandleSubmit={HandleSubmit}
                    backgroundColorButton={"primary"}
                />
            </Box>
        </Box >
    )
}






