// import { useEffect, useState } from 'react';
//Material UI
import {
    Box,
    makeStyles,
    Tooltip
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    containerMain: {
        padding: theme.spacing(2, 4, 4, 4),
        height: "100%"
    },
    mainTitle: {
        color: theme.palette.grey.hairline,
        fontSize: theme.spacing(2.2),
        fontWeight: "600",
    },
    colorBoxCompare: {
        backgroundColor: "#d65473f5",
        height: "60px",
        display: "flex",
        alignItems: "center",
        cursor: 'pointer',
        justifyContent: 'end',
    },
    numbers: {
        color: "white",
        zIndex:99
    }
}));

export default function ShowDiferenceBar({ title, subTitle, value1, value2, unity, labelValue1, labelValue2, backgroundColorValue1 = '#00b8d7', backgroundColorValue2 = '#ef4b6b' }) {
    const classes = useStyles();
    const totalValue = value1 + value2;
    const percentageValue1 = ((value1 / totalValue) * 100).toFixed(2);
    const percentageValue2 = ((value2 / totalValue) * 100).toFixed(2);

    return (

        < Box className={classes.containerMain}>

            <Box className={classes.mainTitle}>
                {title} <span style={{ fontWeight: '400' }}>{subTitle}</span>
            </Box>

            <Box display="flex" height={"100%"} justifyContent={"center"} flexDirection="column">
                <Box>{`Total: ${totalValue.toFixed(2)} ${unity}`}</Box>

                {/* Caja de diferencia dos colores*/}
                <Box display="flex" width={"100%"} mt={1} mb={1}>
                    <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                        <Tooltip title={`${percentageValue1}%`}>
                            <Box
                                className={classes.colorBoxCompare}
                                style={{ backgroundColor: backgroundColorValue1, width: `${percentageValue1}%`, position: 'relative' }} >
                                <p className={classes.numbers} style={{ position: 'absolute', left: 5 }}>{percentageValue1}%</p>
                            </Box>
                        </Tooltip>
                        <Tooltip title={`${percentageValue2}%`}>
                            <Box
                                className={classes.colorBoxCompare}
                                style={{ backgroundColor: backgroundColorValue2, width: `${percentageValue2}%`, position: 'relative' }} >
                                <p className={classes.numbers} style={{ position: 'absolute', right: 5 }}>{percentageValue2}%</p>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>

                <Box display="flex" justifyContent={"space-between"}>
                    <Box
                        style={{ color: backgroundColorValue1 }}
                    >{`${labelValue1}: ${value1.toFixed(2)} ${unity}`}</Box>

                    <Box
                        style={{ color: backgroundColorValue2 }}
                    >{`${labelValue2}: ${value2.toFixed(2)} ${unity}`}</Box>
                </Box>
            </Box>
        </Box>
    )
}