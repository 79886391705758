import React, { useEffect, useState } from 'react';
import './Styles/sidebar.css';
import { Box, makeStyles } from '@material-ui/core';
//helpers.
import { getCatalogo } from './helpers';

let scrollsSettings = {
  overflow: 'scroll',
  marginBottom: '10px',
  overflowX: 'hidden',
};
let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};
let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  boxSvg: {
    backgroundColor: 'red',
    width: '20px',
    height: '20px'
  }
}));

const Sidebar = ({ componentType }) => {
  const classes = useStyles();
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };
  const [blocks, setBlocks] = useState({})

  //Carga catalogo segun componente PLC, ALERT.
  useEffect(() => {
    let res = getCatalogo(componentType, 'generic_data')
    if (res) {
      setBlocks(res['order_blocks'])
    }
    // eslint-disable-next-line
  }, [])


  let listNoneSvg = ['FFRS', 'CONST', 'VARIABLE', 'ALERT', 'LEQ', 'EQ',
    'LESS', 'GREATER', 'GEQ', 'ADDER', 'DIV', 'MULT', 'GAIN', 'MOD', 'INA', 'IND', 'OUTA', 'OUTD']

  let elementMap = {
    'FFRS': 'rs',
    'CONST': 'C',
    'VARIABLE': 'V',
    'ALERT': 'A',
    'LEQ': '<=',
    'EQ': '=',
    'LESS': '<',
    'GREATER': '>',
    'GEQ': '>=',
    'ADDER': '+',
    'DIV': '/',
    'MULT': 'x',
    'GAIN': 'G',
    'MOD': '%',
    'INA': 'INA',
    'IND': 'IND',
    'OUTA': 'OTA',
    'OUTD': 'OTD'
  };
  const returStringSVG = (element) => elementMap[element] || '';

  return (
    <Box
      className={classes.root}
      style={{ border: '1px solid #b5b5b578', padding: '5px', width: '300px', }}
    >
      {Object.entries(blocks).map(([sectionKey, items]) => (
        <Box key={sectionKey}>
          <Box ml={1} mt={1}
            style={{
              fontWeight: 'bold',
              userSelect: 'none'
            }}
          >
            {sectionKey}
          </Box>
          {items.map((elem, indx) => (
            <Box
              key={indx}
              mt={0.5}
              display={'flex'}
              alignItems={'center'}
              onDragStart={(event) => onDragStart(event, elem.key)}
              draggable
              style={{ cursor: 'pointer' }}
            >
              <Box style={{ display: 'flex', backgroundColor: '#f2c84aa1', width: '22px', height: '22px' }} >

                {!listNoneSvg.includes(elem?.key) ?
                  <img
                    style={{
                      width: elem.key === 'COUNT' ? '20px' : '25PX',
                      height: '15px',
                      margin: 'auto',
                    }}
                    src={`/images/diagram/${elem?.key.toLowerCase()}.svg`} alt='svg' />
                  :
                  <Box
                    style={{
                      width: '25px',
                      height: '15px',
                      margin: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '10px'
                    }}>
                    {returStringSVG(elem?.key)}
                  </Box>
                }
              </Box>

              <Box
                style={{
                  backgroundColor: 'white',
                  color: '#2586bc',
                  border: '1px solid #b5b5b578',
                  borderRadius: '3px',
                  padding: '3px',
                  margin: '0 4px 0 5px',
                  width: '280px',
                  fontSize: '12px',
                }}
              >
                {elem?.name}
              </Box>
            </Box>
          ))}
        </Box>
      ))
      }

    </ Box >
  );
};

export default Sidebar

