
import { useContext, useState, useEffect } from 'react';
import {
    Button, Dialog, Box, makeStyles
} from '@material-ui/core';
import { useReactFlow, useNodes } from 'reactflow';
//Hook personalizado.
import DiagramContext from "./Store/DiagramContext";
//Componentes.
import VariableFilterOptions from './FilterOptions/VariableFilterOptions';
import AlertFilterOptions from './FilterOptions/AlertFilterOptions';
import InputsFilterOptions from './FilterOptions/InputsFilterOptions';
import GenericFilterOptions from './FilterOptions/GenericFilterOptions';

const useStyles = makeStyles(theme => ({
    containerMain: {
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    btnSpacing: {
        margin: theme.spacing(0, 2),
    },
}));

const ModalSettings = ({ id, handleOpenModal, openModal, setHandleSourceQuantity, handleSourceQuantity, type, blockNodeSettings, userConfiguration }) => {
    const { stateDiagram, setStateDiagram } = useContext(DiagramContext);
    const classes = useStyles();
    const reactFlow = useReactFlow();
    const nodes = useNodes()
    const [disabledButtonSave, setDisabledButtonSave] = useState(true)
    //Estado de bloque alarma.
    const [alertState, setAlertState] = useState({
        name: '',
        frequency: 20,
        activate: false
    })
    //Estado de bloque variable.
    const [varState, setVarState] = useState({
        values: 'SECCION-VARIABLE',
        nodes: [],
        variable_operation: ''
    });

    useEffect(() => {
        let alertSettings = stateDiagram?.alertObject?.alert
        setAlertState({
            name: alertSettings?.name,
            frequency: alertSettings?.frequency,
            activate: alertSettings?.notification
        })
    }, [stateDiagram?.alertObject?.alert])

    useEffect(() => {
        let findNode = [...nodes].find(e => e.id === id)
        if (findNode) {
            setVarState(findNode)
        }
        // eslint-disable-next-line
    }, [])

    const save = (type) => {
        //Cierra el modal.
        handleOpenModal()
        if (type === 'ALERT') {
            setStateDiagram((prevState) => ({
                ...prevState,
                alertObject: {
                    ...prevState.alertObject,
                    alert: alertState
                }
            }))
        }
        let newproperties = varState
        let arrayEdited = [...nodes].map((elem) => {
            if (id === elem?.id) {
                elem = {
                    ...elem,
                    ...newproperties
                }
                return elem
            }
            else {
                return elem
            }
        })
        reactFlow.setNodes(arrayEdited)
    }

    //Funcion q valida el type de el componente.
    const validateAndComponent = () => {
        return ['AND', 'OR', 'NAND', 'XNOR', 'NOR', 'XOR', 'ADDER', 'MULT'].includes(type);
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleOpenModal}
            maxWidth={'sm'}
            fullWidth
            disableScrollLock
        >
            <Box className={classes.containerMain}>

                {/* BLOQUES */}
                {
                    type === 'ALERT' ?
                        //Componente con los selectores para el bloque 'ALERTA'
                        <AlertFilterOptions
                            setDisabledButtonSave={setDisabledButtonSave}
                            setAlertState={setAlertState}
                            alertState={alertState}
                            stateDiagram={stateDiagram}
                        />
                        :
                        type === 'VARIABLE' ?
                            //Componente con los selectores para el bloque 'VARIABLE'
                            <VariableFilterOptions
                                setDisabledButtonSave={setDisabledButtonSave}
                                setVarState={setVarState}
                                varState={varState}
                            />
                            :
                            validateAndComponent() ?
                                //  {/*'AND', 'OR', 'NAND', 'XNOR', 'NOR', 'XOR', 'ADDER', 'MULT'*/}
                                <InputsFilterOptions
                                    typeBlock={type}
                                    setDisabledButtonSave={setDisabledButtonSave}
                                    setHandleSourceQuantity={setHandleSourceQuantity}
                                    handleSourceQuantity={handleSourceQuantity}
                                    id={id}
                                />
                                :
                                // Bloques q no entran en la validación de arriba.
                                < GenericFilterOptions
                                    typeBlock={type}
                                    id={id}
                                    blockNodeSettings={blockNodeSettings}
                                    setDisabledButtonSave={setDisabledButtonSave}
                                    nodes={nodes}
                                    setVarState={setVarState}
                                    varState={varState}
                                    stateDiagram={stateDiagram}
                                />
                }

                {/* Botones */}
                {
                    userConfiguration &&
                    <Box m={2} mt={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Button
                            onClick={() => save(type)}
                            variant="contained"
                            color="primary"
                            disabled={disabledButtonSave}
                        >
                            Guardar
                        </Button>
                        <Button
                            onClick={handleOpenModal}
                            variant='contained'
                            className={classes.btnSpacing}>
                            Cancelar
                        </Button>
                    </Box>
                }
            </Box>
        </Dialog >
    )
}
export default ModalSettings
