import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
//Servicios
import { getHistoryV2 } from '../../../services/meters';
//Componentes
import ChartV2 from '../../Historical/ChartV2';
//Helpers
import { helperComponent } from '../../DinamicDashboard/helpers';
import { chekUrlPublic } from '../../DinamicDashboard/helpers';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.spacing(2.2),
    margin: theme.spacing(0, 0, 1, 3),
    fontWeight: '600',
    paddingTop: theme.spacing(1.5),
    color: theme.palette.grey.hairline,
  },
  description: {
    margin: theme.spacing(1.5, 0, 1, 3),
    fontSize: theme.spacing(1.5),
    color: theme.palette.primary.main
  }
}));

function ContainerChartHistorical({ id, title, ky, date, descriptcion, config_key, content, color = "default", height, refresh, nodes }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true)
  //estado q muestra page principal o alert de bienvenida
  const [showMain, setShowMain] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [data, setData] = useState([])
  const [subTitle, setSubtitle] = useState('')
  const [filterOptions, setFilterOptions] = useState({
    variable: 'ENERGIA_ACTIVA_TOTAL',
    showPhase: false,
  });

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setLoading(true);
        setData([]);
        setShowErrorAlert(false);
        setErrorMessage('');
        let obj = returnObjectConfig();
        let fieldsAux = obj.fields;
        setFilterOptions(obj);
        let url = createUrl(config_key)
        url += `&fields=${fieldsAux}`;
        url += `&response_format=v2`;
        if (obj.showPhase === true) {
          url += `&showPhase=true`;
        }
        if (content && chekUrlPublic()) {
          const propiedades = Object.keys(content);
          if (propiedades.length > 0) {
            setData(content);
          }
          else {
            setShowMain(false);
          }
          const numeroRandom = Math.floor(Math.random() * 4) + 1;
          setTimeout(() => {
            setLoading(false);
          }, numeroRandom * 1000)
        }
        else {
          let res = await getHistoryV2(url);
          setData(res?.data);
          setLoading(false);
        }
        setShowMain(true)
      }
      catch (error) {
        setShowMain(false);
        if (error?.response?.data?.msg) {
          setErrorMessage(`${error.response.data.msg}.`);
        }
        else {
          setErrorMessage('Ocurrió un error inesperado.');
        }
        setShowErrorAlert(true);
        setLoading(false);
      }
    };
    fetchHistory();

    return () => {
      setData([]);
    };
    // eslint-disable-next-line
  }, [id, date, config_key, refresh, nodes]);

  //funcion para dar las opciones segun la variable seleccionada
  const returnObjectConfig = () => {
    let resObj = {
      variable: 'ENERGIA_ACTIVA_TOTAL',
      fields: 'kwh',
      showPhase: false,
    }
    Object.entries(helperComponent('DASHBOARDS_HISTORY')).forEach(([clave, valor]) => {
      let kEY_CONFIG = config_key.split('-')[1]
      let KEY_CATALOGUE = valor.value.split('-')[0]
      if (kEY_CONFIG === KEY_CATALOGUE) {
        //configuracion de el sub titulo.
        let titleKeyGranularity = config_key.split('-')[4]
        let titleKeyPeriod = config_key.split('-')[3]
        let values = valor.periods.find(e => e.key === titleKeyGranularity).values
        let name = values.find(e => e.key === titleKeyPeriod).name
        setSubtitle(name)
        resObj.variable = valor.value?.split('-')[0]
        resObj.fields = valor.value?.split('-')[1]
        resObj.showPhase = valor.value?.split('-')[2] === 'true'
      }
    })
    return resObj
  }

  const createUrl = (config_key) => {
    let range = config_key.split('-')[3]
    let period = config_key.split('-')[4]

    let idAux = `filter=${''}`
    let auxNodes = nodes

    if (id) {
      idAux = `filter=${id}`
    }


    if (auxNodes?.length > 0) {
      let arryIds = []
      auxNodes?.forEach((elem) => {
        let string = `filter=${elem.id}`
        if (elem?.tag?.id) {
          string = `filter=${elem.id}+${elem?.tag?.id}`
        }
        arryIds.push(string)
      })
      idAux = arryIds?.join()
      idAux = idAux.replace(/,/g, '&');
    }

    let url = `${idAux}&range=${range}&period=${period}`;
    return url
  }


  //Función encargada de dar el título al componente si son múltiples nodos o uno solo.
  const checkMultiplesNodesSubTitle = () => {
    let res = ''
    if (nodes?.length > 1) {
      res = `Multiples nodos`
    }
    else {
      res = (nodes && ` (${data[0]?.node?.nombre})`)
    }
    return res
  }

  return (
    <Box style={{ height: '100%' }}>
      {showErrorAlert && <Alert severity='error'>{errorMessage}</Alert>}
      {loading ? (
        <Box display='flex' justifyContent='center' mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {showMain &&
            <>
              <Box className={classes.title} style={nodes ? { fontSize: '14px' } : {}} >
                {title ? title : checkMultiplesNodesSubTitle()} <span style={{ fontWeight: '400' }}>{` | ${subTitle}`}</span>
              </Box>

              <ChartV2
                chartData={data}
                chartOptions={filterOptions}
                height={height}
                id={id}
                ky={ky}
                dashboard={true}
                color={color}
              />
            </>}
        </>

      )}
    </Box>
  );
}
// export default ContainerChartHistorical;
export default React.memo(ContainerChartHistorical);
