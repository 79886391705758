
export const MAVG_OPTIONS = [
    {
        name: '4',
        key: '4'
    },
    {
        name: '8',
        key: '8'
    },
    {
        name: '16',
        key: '16'
    },
    {
        name: '32',
        key: '32'
    },
    {
        name: '64',
        key: '64'
    },
]

export const INA_OPTIONS = [
    {
        name: 'Tension 0 - 10V',
        key: '0'
    },
    {
        name: 'Tension 2 - 10V',
        key: '1'
    },
    {
        name: ' Tension 0 - 5V',
        key: '2'
    },
    {
        name: 'Tension 1 - 5V',
        key: '3'
    },
    {
        name: 'Corriente 0 - 20mA',
        key: '4'
    },
    {
        name: 'Corriente 4 - 20mA',
        key: '5'
    },
]

export const BOOL_OPTIONS = [
    {
        name: 'Verdadero',
        key: true
    },
    {
        name: 'Falso',
        key: false
    },
]


export const BOOLEAN_TEXT_OPTIONS = [
    {
        name: 'Booleano',
        key: '0'
    },
    {
        name: 'Entero',
        key: '1'
    },
    {
        name: 'Entero no signado',
        key: '2'
    },
    {
        name: 'Flotante',
        key: '3'
    },
]
export const CONST_OPTIONS = [
    {
        name: 'bool',
        key: '0'
    },
    {
        name: 'int',
        key: '1'
    },
    {
        name: 'uint',
        key: '2'
    },
    {
        name: 'float',
        key: '3'
    },
]

export const components = [
    {
        type: 'OR',
        description: 'Realiza la operación lógica OR entre todas sus entradas.',
        implementation: ''
    },
    {
        type: 'NOR',
        description: 'Realiza la operación lógica NOR entre todas sus entradas.',
        implementation: ''
    },
    {
        type: 'XOR',
        description: 'Realiza la operación lógica XOR entre todas sus entradas.',
        implementation: ''
    },
    {
        type: 'XNOR',
        description: 'Realiza la operación lógica XNOR entre todas sus entradas.',
        implementation: ''
    },
    {
        type: 'AND',
        description: 'Realiza la operación lógica AND entre todas sus entradas.',
        implementation: ''
    },
    {
        type: 'NOT',
        description: 'Realiza la operación lógica NOT a su única entrada.',
        implementation: ''
    },
    {
        type: 'NAND',
        description: 'Realiza la operación lógica NAND entre todas sus entradas.',
        implementation: ''
    },
    {
        type: 'INA',
        description: 'Este bloque entrega en su salida el valor analógico registrado en la entrada especificada (in). La salida por defecto del bloque es un int de valor 0 a 10000. Por atributo se debe especificar el número de la entrada (dependiente del producto) y su modo de adquisición (0: Tension 0-10V, 1: Tension 2-10V, 2: Tension 0-5V, 3: Tension 1-5V, 4: Corriente 0-20mA, 5: Corriente 4-20mA). El rango de 0 a 10000 se mapeara de acuerdo al modo de funcionamiento (por ejemplo el 0.0 en modo 4-20mA significa una que en la entrada se estan midiendo de 4mA).',
        implementation: ''
    },
    {
        type: 'IND',
        description: 'Este bloque entrega en su salida el valor digital registrado en la entrada especificada (in).',
        implementation: ''
    },
    {
        type: 'MOD',
        description: 'Obtiene el resto de la entrada divida por el atributo pasado.',
        implementation: ''
    },
    {
        type: 'GAIN',
        description: 'Multiplica una entrada por una ganancia configurada por atributo.',
        implementation: ''
    },
    {
        type: 'AVG',
        description: 'Este bloque realiza un promedio durante un tiempo definido y lo entrega en su salida.',
        implementation: ''
    },
    {
        type: 'MAVG',
        description: 'Este bloque filtra la entrada realizando un promedio de N muestras tipo ventana deslizante.',
        implementation: ''
    },
    {
        type: 'LPF',
        description:
            <div>
                'Este bloque es un filtro pasa bajo tipo IIR de primer orden conocido como Exponencial Moving Average. Recibe la entrada a filtrar ("i") y un atributo "d" que define que tan rápido responde la salida del bloque a los cambios en la entrada (en otras palabras, la frecuencia de corte del filtro). La formula del filtro es la siguiente:'
                <pre>
                    y[n]= d * y[n−1] + (1-d) * x[n]
                </pre>
                El atributo "d" tiene un valor que va desde 0.0 a 1.0 no inclusive. Con d = 0.0 se obtiene un filtro que responde inmediatamente a la entrada (no filtra nada), y con d = 0.99999 se obtiene un filtro que responde muy lentamente ante cambios en la entrada (filtra todo). El valor por defecto del filtro es 0.9.
            </div>,
        implementation: ''
    },
    {
        type: 'COUNT',
        description: 'Este bloque cuenta los flancos positivos recibidos en su entrada INPUT y devuelve la cuenta por la salida. La cuenta es ascendente o descendente dependiento del estado de su entrada DIR. Resetea su cuenta cuando se activa la entrada RESET. El valor inicial de la cuenta se puede pasar opcionalmente como atributo (si el contador arranca de 0, o cualquier otro numero, se puede configurar mediante este atributo).',
        implementation: ''
    },
    {
        type: 'EQ',
        description: 'Compara el valor de dos entradas y devuelve true si son iguales. Además se puede proporcionar por atributo un porcentual de tolerancia de igualdad (por defecto desactivado, es decir 0%).',
        implementation: ''
    },
    {
        type: 'THOLD',
        description: 'Compara el valor de una entrada contra dos umbrales on y off. Sirve para realizar comparaciones con histéresis o para verificar que una entrada este dentro de un rango (dependiendo de como se configuren los umbrales). En la siguiente figura se muestra un ejemplo del bloque funcionando como comparador con histéresis.',
        implementation: ''
    },
    {
        type: 'TON',
        description: 'La función de un temporizador de retardo de encendido es demorar por un tiempo prestablecido el encendido de su salida cuando la entrada cambia de FALSO a VERDADERO. Cada vez que la entrada se pone en FALSO, el tiempo acumulado se resetea y la salida pasa a FALSO. A continuación se muestra graficamente el comportamiento de este tipo de timer.',
        implementation: ''
    },
    {
        type: 'TOFF',
        description: 'La función de un temporizador de retardo de apagado es demorar por un tiempo prestablecido el apagado de su salida cuando la entrada cambia de VERDADERO a FALSO. El tiempo de demora puede interrumpirse si su entrada de reset pasa de FALSO a VERDADERO. A continuación se muestra graficamente el comportamiento de este tipo de timer.',
        implementation: ''
    },
    {
        type: 'TPUL',
        description: 'Un temporizador de pulso comienza a funcionar cuando su entrada cambia de estado de FALSO a VERDADERO. La salida se activa y el temporizador comienza a contar. Cuando el tiempo preestablecido expira, la salida se apaga. Así, se crea un pulso que tiene una duración igual al valor de tiempo preestablecido del temporizador. La unica forma de interrumpir este pulso es activar la entrada de reset. A continuación se muestra graficamente el comportamiento de este tipo de timer.',
        implementation: ''
    },
    {
        type: 'TRET',
        description: 'La función de un temporizador retentivo es registrar la cantidad de tiempo que la entrada está ENCENDIDA y activar la salida después de que el tiempo acumulado alcance el valor de tiempo preestablecido. El temporizador retentivo solo puede reiniciarse al activar la entrada de reinicio. A continuación se muestra graficamente el comportamiento de este tipo de timer.',
        implementation: ''
    },
    {
        type: 'TCRON',
        description: 'Este bloque posibilita la ejecución de acciones programadas en función del calendario, siguiendo el esquema establecido por CRON.',
        implementation: ''
    },
    {
        type: 'PULSEGEN',
        description: 'Este bloque genera pulsos asincrónicos. Se configura mediante dos atributos. Un atributo ("on") establece el tiempo de pulso (salida en VERDADERO) y otro atributo ("off") establece el tiempo de interpulso (salida en FALSO).',
        implementation: ''
    },
    {
        type: 'PWM',
        description: 'Este bloque filtra la entrada realizando un promedio de N muestras tipo ventana deslizante.',
        implementation: ''
    },
    {
        type: 'TUTC',
        description: 'Este bloque entrega en su salida el tiempo en formato Unix Timestamp (segundos transcurridos desde el 1 de enero de 1970 a las 00:00:00 UTC).',
        implementation: ''
    },
    {
        type: 'TLOC',
        description: 'Este bloque entrega en su salida el tiempo en formato Unix Timestamp (segundos transcurridos desde el 1 de enero de 1970 a las 00:00:00 UTC) ajustado por la zona horaria configurada en el equipo. Es decir, devuelve los segundos transcurridos desde el 1 de enero de 1970 a las 00:00:00 HORA LOCAL.',
        implementation: ''
    },
    {
        type: 'CONST',
        description: 'Entrega un valor constante configurado por atributo.',
        implementation: ''
    },
    {
        type: 'FEDGE',
        description: 'Detecta flancos negativos en su entrada y genera un pulso en su salida en consecuencia.',
        implementation: ''
    },
    {
        type: 'REDGE',
        description: 'Detecta flancos positivos en su entrada y genera un pulso en su salida en consecuencia.',
        implementation: ''
    },
    {
        type: 'CAST',
        description: 'Bloque encargado de realizar la conversión de un tipo de dato a otro.',
        implementation: ''
    },
    {
        type: 'FFRS',
        description: <div>Este bloque opera según el principio de un flip-flop RS o un relé de retención. Cuando la señal de entrada SET se activa, su salida se establece en VERDADERO. Posteriormente, la salida solo cambia a FALSO si la señal de entrada RESET se activa. RESET tiene prioridad por sobre SET.
            Además, cuenta con una entrada TOGGLE que solo funciona SET y RESET estan inactivas.Cuando TOGGLE recibe un flanco ascendente, se invierte la salida.
            La prioridad de las entradas es RESET - SET - TRIGGER.
        </div>,
        implementation: ''
    },
    {
        type: 'MUX2',
        description: 'Mediante dos pines de control permite multiplexar hasta 2 entradas a una salida. El tipo de dato de la salida, será el mismo tipo que en la entrada seleccionada. Si no se necesitara alguna entrada o pin de control, puede dejarse desconectado. En caso de que una entrada o un pin de control esten desconectados, se asume que en dicha entrada hay un cero (uint). El tipo de salida es identico al de la entrada seleccionada.',
        implementation: ''
    },
    {
        type: 'MUX4',
        description: 'Mediante dos pines de control permite multiplexar hasta 4 entradas a una salida. El tipo de dato de la salida, será el mismo tipo que en la entrada seleccionada. Si no se necesitara alguna entrada o pin de control, puede dejarse desconectado. En caso de que una entrada o un pin de control esten desconectados, se asume que en dicha entrada hay un cero (uint).',
        implementation: ''
    },
    {
        type: 'DELAY',
        description: 'Este bloque demora un valor exactamente un ciclo de operación. Es decir, toma un valor a su entrada, y al ciclo siguiente, lo coloca en su salida. Se puede utilizar para realizar filtros digitales.',
        implementation: ''
    },
    {
        type: 'SHOLD',
        description: 'Este bloque toma el valor de la entrada (i) y lo coloca en la salida únicamente cuando se detecta un VERDADERO en el enable (e). En otras palabras, cuando hay un uno lógico en el enable (e), el bloque captura el valor de la entrada en ese momento y lo coloca en la salida. Si se quisiera que este bloque funcione por flanco, es necesario agregar un bloque FEDGE o REDGE en la entrada enable.',
        implementation: ''
    },
    {
        type: 'OUTA',
        description: 'Este bloque fuerza la salida al valor indicado por la entrada. Esta debe ser un int/uint/float con un rango de 0 a 10000. Lo valores menores a 0 se recortan a 0 y los valores mayores a 10000 se recortan a 10000. Por atributo se debe especificar el número de la salida (dependiente del producto) y su modo de funcionamiento (0: Tension 0-10V, 1: Tension 2-10V, 2: Tension 0-5V, 3: Tension 1-5V, 4: Corriente 0-20mA, 5: Corriente 4-20mA). El rango de 0 a 10000 se mapeara a la salida de acuerdo al modo de funcionamiento (por ejemplo el 0.0 en modo 4-20mA significa una salida de 4mA).',
        implementation: ''
    },
    {
        type: 'OUTD',
        description: 'Este bloque fuerza la salida al valor booleano indicado por la entrada.',
        implementation: ''
    },
    {
        type: 'GREATER',
        description: 'Compara el valor de dos entradas y devuelve true si una es mayor que la otra (LHS > RHS).',
        implementation: ''
    },
    {
        type: 'LESS',
        description: 'Compara el valor de dos entradas y devuelve true si una es menor que la otra (LHS < RHS).',
        implementation: ''
    },
    {
        type: 'GEQ',
        description: 'Compara el valor de dos entradas y devuelve true si una es mayor o igual que la otra (LHS >= RHS).',
        implementation: ''
    },
    {
        type: 'LEQ',
        description: 'Compara el valor de dos entradas y devuelve true si una es menor o igual que la otra (LHS <= RHS).',
        implementation: ''
    },
    {
        type: 'ADDER',
        description: 'Realiza la suma aritmética de las entradas.',
        implementation: ''
    },
    {
        type: 'MULT',
        description: 'Realiza la multiplicación aritmética de las entradas.',
        implementation: ''
    },
    {
        type: 'DIV',
        description: 'Realiza la división aritmética de las entradas.',
        implementation: ''
    },
    {
        type: 'MAX',
        description: 'Este bloque almacena siempre el máximo valor registrado en su entrada y lo entrega por su salida. El valor puede resetearse poniendo un VERDADERO en la entrada de reset. Si no es necesaria, puede dejarse desconectada.',
        implementation: ''
    },
    {
        type: 'MIN',
        description: 'Este bloque almacena siempre el mínimo valor registrado en su entrada y lo entrega por su salida. El valor puede resetearse poniendo un VERDADERO en la entrada de reset. Si no es necesaria, puede dejarse desconectada.',
        implementation: ''
    },
    {
        type: 'SHOLD',
        description: 'Este bloque toma el valor de la entrada (i) y lo coloca en la salida únicamente cuando se detecta un VERDADERO en el enable (e). En otras palabras, cuando hay un uno lógico en el enable (e), el bloque captura el valor de la entrada en ese momento y lo coloca en la salida. Si se quisiera que este bloque funcione por flanco, es necesario agregar un bloque FEDGE o REDGE en la entrada enable.',
        implementation: ''
    },
    {
        type: 'RAND',
        description: 'Este bloque genera valores aleatorios a su salida. El tipo de dato de salida es seleccionable (0: bool, 1: int, 2: uint o 3: float) y el rango del número aleatorio se puede definir con los atributos MIN y MAX.',
        implementation: ''
    },
    {
        type: '',
        description: '',
        implementation: ''
    },
]

export const returnTextsBlock = (type) => {
    let findElement = components.find(e => e.type === type);
    if (findElement) {

        return findElement
    }
    else {
        return {
            description: '',
            implementation: ''
        }
    }
}